import { useCallback } from "react"
import { useBackendWithAuth } from "./backend"

export const useRcmfSubmissionService = () => {
  // We're not interested in data
  const [{ data, ...rest }, submitRcmf, submitRcmfs] = useBackendWithAuth()

  return [
    rest,
    useCallback(
      async body =>
        submitRcmf("/update", {
          method: "POST",
          body: JSON.stringify(body),
          skipParse: true,
        }),
      [submitRcmf]
    ),
    useCallback(
      async bodies =>
        submitRcmfs(
          "/update",
          bodies.map(body => ({
            method: "POST",
            body: JSON.stringify(body),
            skipParse: true,
          }))
        ),
      [submitRcmfs]
    ),
  ]
}
