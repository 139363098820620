import React from "react"

type Props = {
  bottom?: string
  right?: string
}

const FloatingButtonsWrapper: React.FC<Props> = ({
  children,
  bottom,
  right,
}) => (
  <div
    style={{
      position: "fixed",
      bottom: bottom ?? "1rem",
      right: right ?? "1rem",
      zIndex: "50",
      display: "flex",
    }}
  >
    {children}
  </div>
)

export default FloatingButtonsWrapper
