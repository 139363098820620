import { Grid } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import * as yup from "yup"
import Error from "../../../components/error"
import { useRcmfSubmissionService } from "../../../hooks/rcmf"
// Recoil
import { formDialogState, rcmfSubmissionState } from "../../../recoil/atoms"
import FormRcmfPayload from "./components/form_rcmf_payload"
import StateSelector from "./components/state_selector"

const operators = [
  "SJ",
  "MTRN",
  "ATRAIN",
  "TÅGAB",
  "Green Cargo",
  "HR",
  "Annan",
]

// For pre fill
const timeTypes = [
  { type: "Planned", name: "Planerad" },
  { type: "Estimated", name: "Beräknad" },
  { type: "Actual", name: "Faktisk" },
]

const missingMsg = "Måste vara ifylld."

const validationSchema = yup.object({
  trainNumber: yup.string().required(missingMsg),
  //location: yup.object().required(missingMsg).nullable(),
  state: yup.object({
    stateType: yup.string().nullable(),
  }),
  timeType: yup.object().when("state.stateType", {
    is: "AdministrativeState",
    then: schema => schema.nullable(),
    otherwise: schema => schema.required(missingMsg).nullable(),
  }),
})

const getLocationAttributes = (location, timeSequence) => {
  switch (timeSequence) {
    case "arrivalTo":
      return { to: location }
    case "departureFrom":
      return { from: location }
    default:
      return { at: location }
  }
}

const getLocationString = ({ locationType, location, trainNumber, track }) => {
  const prefix = `rcmf:location:${locationType}`

  if (locationType === "train") {
    return `${prefix}:${trainNumber}`
  }

  const suffix = track ? `:${track}` : ""

  return `${prefix}:${location}${suffix}`
}

const getReferenceObject = ({ referenceObjectType, trainNumber, wagonId }) => {
  const prefix = `rcmf:reference_object:${referenceObjectType}`

  if (referenceObjectType === "train" && trainNumber) {
    return `${prefix}:${trainNumber}`
  }

  if (referenceObjectType === "wagon" && wagonId) {
    return `${prefix}:${wagonId}`
  }

  return prefix // TODO: Idenfify other reference objects, such as FAECAL_EMPTYING?
}

const createRcmPayload = ({
  state,
  comment,
  time,
  locationString,
  trainNumber,
  wagonId,
  timeType,
}) => {
  const commonProps = {
    comment,
    time: time.getTime(),
    timeSequence: state.timeSequence,
  }

  switch (state.stateType) {
    case "LocationState":
      return {
        ...commonProps,
        ...getLocationAttributes(locationString, state.timeSequence),
        referenceObject: getReferenceObject({
          referenceObjectType: state.referenceObjectType,
          trainNumber,
          wagonId,
          timeType,
        }),
        timeType,
      }

    case "ServiceState":
      return {
        ...commonProps,
        timeType,
        serviceObject:
          "rcmf:service_object:" +
          state.serviceObjectType +
          (state.id.includes("train_becomes") ? `:${trainNumber}` : ""),
      }
    case "AdministrativeState":
      return {
        ...commonProps,
        timeType,
        serviceObject: "rcmf:service_object:" + state.serviceObjectType,
      }
    default:
      return {
        ...commonProps,
        serviceObject: state.serviceObjectType,
      }
  }
}

export default function SubmitRCMFAuto() {
  const rcmfSubmissionStateprefill = useRecoilValue(rcmfSubmissionState)
  const [{ isLoading, status, error }, submitMessage] =
    useRcmfSubmissionService()
  const [formDialog, setFormDialog] = useRecoilState(formDialogState)
  const resetrcmfSubmissionState = useResetRecoilState(rcmfSubmissionState)

  const formik = useFormik({
    initialValues: {
      state: rcmfSubmissionStateprefill.activeState,
      timeType:
        timeTypes.find(
          x => x.type === rcmfSubmissionStateprefill.activeTimeType
        ) || null,
      location: rcmfSubmissionStateprefill.activeStationObj,
      operator: rcmfSubmissionStateprefill.activeOperator,
      trainNumber: rcmfSubmissionStateprefill.activeTrainNumber || "",
      operationalTrainNumber: "",
      trainNumberTo: "",
      technicalWagonId: rcmfSubmissionStateprefill.activeTrainWagonID,
      track: rcmfSubmissionStateprefill.activeTrainTrack || "",
      timeStamp: new Date(),
      comment: "",
      units: [],
      maxSpeed: "",
      totalLength: "",
      totalWeight: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setFormDialog(prev => ({
        ...prev,
        isSending: true,
      }))

      const {
        state,
        timeType,
        location,
        operator,
        trainNumber: trainNumberRaw,
        trainNumberTo: trainNumberToRaw,
        operationalTrainNumber,
        technicalWagonId,
        track,
        timeStamp,
        comment,
        maxSpeed,
        totalWeight,
        totalLength,
        units,
      } = values

      const trainNumber = trainNumberRaw.toLowerCase()
      const trainNumberTo = trainNumberToRaw?.toLowerCase()

      const locationString = getLocationString({
        locationType: state.locationType,
        location: location?.id,
        trainNumber,
        track,
      })

      const payload = createRcmPayload({
        state,
        locationString,
        comment,
        time: timeStamp,
        trainNumber: trainNumberTo || trainNumber,
        wagonId: technicalWagonId,
        timeType: timeType?.type,
      })

      const train = {
        technicalTrainNumber: trainNumber,
        operationalTrainNumber,
        vehicle:
          maxSpeed || totalWeight || totalLength || units.length > 0
            ? {
                maxSpeed: maxSpeed || undefined,
                totalWeight: totalWeight || undefined,
                totalLength: totalLength || undefined,
                units: units.length > 0 ? units : undefined,
              }
            : undefined,
      }

      if (operator && operator !== "Annan") {
        train["operator"] = operator
      }

      const body = {
        train,
        payload,
      }

      submitMessage(body)
    },
  })

  useEffect(() => {
    setFormDialog({
      ...formDialog,
      error: null,
    })
  }, [])

  useEffect(() => {
    if (status === 200) {
      setFormDialog(prev => ({
        ...prev,
        success: true,
        isLoading: false,
        isSending: false,
      }))
      resetrcmfSubmissionState()
    } else {
      setFormDialog(prev => ({
        ...prev,
        isLoading,
        error,
      }))
    }
  }, [status, setFormDialog, resetrcmfSubmissionState, isLoading, error])

  useEffect(() => {
    if (formDialog.success) {
      formik.resetForm()
      setFormDialog(prev => ({ ...prev, success: false }))
    }
  }, [formik, formDialog, setFormDialog])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
        {!formDialog.error && (
          <>
            <Grid item xs={12}>
              <StateSelector
                value={formik.values.state}
                onChange={state => formik.setFieldValue("state", state)}
              />
            </Grid>

            {formik.values.state && (
              <>
                <FormRcmfPayload
                  formik={formik}
                  timeTypes={timeTypes}
                  operators={operators}
                  state={formik.values.state}
                />
              </>
            )}
          </>
        )}

        {formDialog.error && <Error error={formDialog} />}
      </Grid>
    </form>
  )
}
