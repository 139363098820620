import Map from "./components/Map"
import Details from "./components/Details"
import styled from "styled-components"
import { useEffect } from "react"
import { onMessageReceived } from "./actions/messages"
import { createSocket } from "./utils/apiService"
import { fetchReaders } from "./actions/readers"
import { fetchUsers } from "./actions/users"
import { BP_M } from "./utils/breakpoints"
import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./reducers"
import { useAuthDispatch } from "../../hooks/rfidBackend"
import { useHistory, useRouteMatch } from "react-router-dom"
import routes from "./routes.json"

const store = configureStore({ reducer: rootReducer })

const Container = styled.div`
  display: flex;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (max-width: ${BP_M}) {
    flex-direction: column;
  }
`

export default function Rfid() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

function App() {
  const dispatch = useAuthDispatch()
  const { page, selectedItemType } =
    useRouteMatch("/:page/:selectedItemType?")?.params || {}
  const history = useHistory()

  useEffect(() => {
    /*createSocket().on("message", message => {
      console.log("Message received.")
      return dispatch(onMessageReceived(message))
    })*/
  }, [dispatch])

  useEffect(() => {
    if (
      !selectedItemType ||
      !Object.values(routes).includes(selectedItemType)
    ) {
      history.replace(`/${page}/${routes.TRAINS}`)
    }
  }, [page, selectedItemType, history])

  useEffect(() => {
    dispatch(fetchReaders())
    dispatch(fetchUsers())
  }, [dispatch])

  return (
    <Provider store={store}>
      <Container>
        <Map />
        <Details />
      </Container>
    </Provider>
  )
}
