import React from "react"
import { styled } from "@mui/material"
import Text from "./text"
import { useTheme } from "@mui/material/styles"
import { formatEpochDateTime } from "../utils/dataFormatting.js"
import { Tooltip } from "@mui/material"
import { MessageReportedByTooltipTitle } from "./message_reported_by"

const ActualText = styled(Text)(() => {
  const theme = useTheme()

  return `
    color: ${theme.palette.actual.main};
    `
})

const StrikeThroughText = styled(Text)(() => {
  const theme = useTheme()

  return `
        color: ${theme.palette.error.main}
    `
})

interface Props {
  planned?: number
  estimated?: number
  actual?: number
  noWrap?: boolean
  reportedAt?: number
  reportedBy?: string
  lineThrough?: boolean
}

export default function Time({
  planned: plannedRaw,
  estimated: estimatedRaw,
  actual: actualRaw,
  noWrap,
  reportedAt,
  reportedBy,
  ...props
}: Props) {
  const planned = formatEpochDateTime(plannedRaw)
  const estimated = formatEpochDateTime(estimatedRaw)
  const actual = formatEpochDateTime(actualRaw)

  const textProps = { noWrap, ...props }

  const tooltipProps = {
    title:
      reportedAt && reportedBy ? (
        <MessageReportedByTooltipTitle
          reportedAt={reportedAt}
          reportedBy={reportedBy}
        />
      ) : (
        ""
      ),
  }

  if (actual) {
    return (
      <Tooltip {...tooltipProps}>
        <div>
          <ActualText {...textProps} size="small">
            {actual}
          </ActualText>
        </div>
      </Tooltip>
    )
  }

  if (estimated) {
    return (
      <Tooltip {...tooltipProps}>
        <div style={noWrap ? { display: "flex" } : undefined}>
          {planned && (
            <StrikeThroughText
              style={noWrap ? { marginRight: "0.2em" } : undefined}
              {...textProps}
              size="small"
            >
              <del>{planned}</del>
            </StrikeThroughText>
          )}

          <Text size="small" {...textProps}>
            {estimated}
          </Text>
        </div>
      </Tooltip>
    )
  }

  return (
    <Tooltip {...tooltipProps}>
      <div>
        <Text size="small" {...textProps}>
          {planned || "-"}
        </Text>
      </div>
    </Tooltip>
  )
}
