import DataWrapper from "../DataWrapper"
import styled from "styled-components"
import MaterialList from "@mui/material/List"

const Container = styled.div`
  overflow-y: scroll;
  height: 100%;
`

const List: React.FunctionComponent<any> = ({
  items,
  isLoading = false,
  error = false,
  ItemComponent,
  keyProp,
}) => {
  return (
    <Container>
      <DataWrapper isLoading={isLoading} fixedHeight error={error}>
        <MaterialList>
          {items.slice(0, 100).map((item, index) => (
            <ItemComponent
              key={keyProp(item)}
              item={item}
              isNewlyUpdated={
                // Less than 10 seconds since reported
                item.latestMessage &&
                new Date().getTime() - item.latestMessage.reportedAt < 10000
              }
            />
          ))}
        </MaterialList>
      </DataWrapper>
    </Container>
  )
}

export default List
