import { Grid, Paper, styled } from "@mui/material"
import Text from "../../components/text"
import Time from "../../components/time"
import { useTranslation } from "react-i18next"

/*
track: train.departureEvent?.track,
          time: train.departureEvent?.latestTime,
          timeType: train.departureEvent?.latestTimeType,
          trainNumber: train.departureEvent?.technicalTrainNumber,
          trainId: train.trainId,
*/

const Container = styled(Paper)`
  padding: 0.5em;
`

export default function TrainTooltip({ selectedTrainPayload: payload }) {
  const { t } = useTranslation()

  if (!payload) return null
  return (
    <Container elevation={4}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Text size="small" noWrap bold>
            {t("generalTrain.trainId")}
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Text size="small" right>
            {payload.trainNumber}
          </Text>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Text size="small" bold>
            {t("generalTrain.track")}
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Text size="small" right>
            {payload.track}
          </Text>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Text size="small" bold>
            {t("time.time")}
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Time
            right
            planned={payload.planned}
            estimated={payload.estimated}
            actual={payload.actual}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
