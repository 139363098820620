import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import DataWrapper from "../../../components/data_wrapper"
import Text from "../../../components/text"
import { useLogin } from "../../../hooks/user"
import ROUTES from "../../../ROUTES.json"
import { getTitle } from "../../../utils/domain"

export default function LoginForm() {
  const [{ isLoading, error, ...user }, login] = useLogin()
  const history = useHistory()

  useEffect(() => {
    if (user.username) {
      history.replace(
        getTitle() === "YardCDM" ? ROUTES.DEPOT_VIEW : ROUTES.STATION_OVERVIEW
      )
    }
  }, [user, history])

  const handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    login(data.get("email"), data.get("password"), !!data.get("remember"))
  }

  const sendMailRequestAccess = () => {
    window.open(
      "mailto:2020@triplefchallenge.se?subject=StationCDM åtkomst begäran till plattform&body=Vänligen fyll i er information för att kunna deltaga i StatiomCDM Demo. Syftet är att registrera en användare/roll/befattning för att kunna rapportera tidsstämplar.%0D%0ANamn:%0D%0AE-post:%0D%0AFöretag:%0D%0ABefattning: %0D%0A%0D%0AVi återkommer snart, ha en fortsatt trevlig dag!"
    )
  }

  const sendMailRequestNewPassword = () => {
    window.open(
      "mailto:2020@triplefchallenge.se?subject=StationCDM begäran av nytt lösenord&body=Vänligen fyll i er information%0D%0ANamn:%0D%0AE-post:%0D%0AFöretag:%0D%0AVi återkommer snart, ha en fortsatt trevlig dag!"
    )
  }

  if (user.contents?.username) return null

  return (
    <Paper sx={{ maxWidth: "650px", padding: "1rem" }}>
      <DataWrapper isLoading={isLoading || !!user.username}>
        <Box>
          <Typography component="h1" variant="h5" align="center">
            Logga in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Användarnamn"
              name="email"
              autoComplete="email"
              autoCapitalize="none"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Lösenord"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={
                <Checkbox value="true" name="remember" color="primary" />
              }
              label="Kom ihåg mig på denna enhet"
            />
            <Text hidden={!error} isError>
              {error?.message}
            </Text>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Logga in
            </Button>
            <Grid container>
              <Grid item xs>
                <Link onClick={sendMailRequestNewPassword} variant="body2">
                  Glömt lösenordet?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={sendMailRequestAccess} variant="body2">
                  {"Begär åtkomst till plattformen"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DataWrapper>
    </Paper>
  )
}
