import { styled } from "@mui/material"
import Text from "./text"
import AutoGrid from "./auto_grid"

const ItemContainer = styled("div")`
  margin: 0.5em 1em 0.5em 0;
`

export default function KeyValueDisplay({
  items,
  labels,
  hideMissing = false,
}) {
  return (
    <AutoGrid>
      {Object.keys(labels)
        .filter(key => !hideMissing || items[key])
        .map(key => {
          const keyLabel = labels[key] || key
          const valueLabel = items[key] || "-"

          return (
            <ItemContainer key={key}>
              <Text bold size="small">
                {keyLabel}
              </Text>
              <Text>{valueLabel}</Text>
            </ItemContainer>
          )
        })}
    </AutoGrid>
  )
}
