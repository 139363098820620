import { useEffect } from "react"
import { useRecoilState } from "recoil"
import {
  kpiArrivalsState,
  kpiDeparturesState,
  kpiRouteState,
  kpiState,
  kpiSummaryState,
} from "../recoil/atoms"
import { useBackendWithAuth } from "./backend"
import * as qs from "query-string"

export const useStationKpiSummary = (
  locationId,
  epochFrom,
  epochTo,
  filters,
  disableQuery
) => {
  const [{ data, isLoading, error }, fetchKpis] = useBackendWithAuth()
  const [KPIs, setKPIs] = useRecoilState(kpiSummaryState)

  useEffect(() => {
    if (disableQuery) return
    fetchKpis(
      `/station_kpi/${locationId}/summary?${qs.stringify({
        start_time: epochFrom,
        end_time: epochTo,
        hide_cancelled: filters?.hideCancelled,
        hide_deviating: filters?.hideDeviating,
        hide_planned: filters?.hidePlanned,
        hide_missing: filters?.hideMissing,
        hide_even: filters?.hideEven,
        hide_odd: filters?.hideOdd,
        hide_compositionless: filters?.hideCompositionless,
        train_number: filters?.trainNumber,
        include_event_messages: filters?.includeEventMessages,
        include_events: filters?.includeEvents,
        sort_time_mode: filters?.sortTimeMode,
      })}`
    )
  }, [
    fetchKpis,
    locationId,
    epochFrom,
    epochTo,
    setKPIs,
    filters,
    disableQuery,
  ])

  useEffect(() => {
    if (data) {
      setKPIs({
        isLoading,
        error,
        KPIs: data,
      })
    } else {
      setKPIs(prev => ({
        ...prev,
        isLoading,
        error,
      }))
    }
  }, [data, isLoading, error, setKPIs])

  return KPIs
}

export const useStationKpiArrivals = (
  locationId,
  epochFrom,
  epochTo,
  filters,
  disableQuery
) => {
  const [{ data, isLoading, error }, fetchKpis] = useBackendWithAuth()
  const [KPIs, setKPIs] = useRecoilState(kpiArrivalsState)

  useEffect(() => {
    if (disableQuery) return
    fetchKpis(
      `/station_kpi/${locationId}/arrivals?${qs.stringify({
        start_time: epochFrom,
        end_time: epochTo,
        hide_cancelled: filters?.hideCancelled,
        hide_deviating: filters?.hideDeviating,
        hide_planned: filters?.hidePlanned,
        hide_missing: filters?.hideMissing,
        hide_even: filters?.hideEven,
        hide_odd: filters?.hideOdd,
        hide_compositionless: filters?.hideCompositionless,
        train_number: filters?.trainNumber,
        include_event_messages: filters?.includeEventMessages,
        include_events: filters?.includeEvents,
        sort_time_mode: filters?.sortTimeMode,
      })}`
    )
  }, [
    fetchKpis,
    locationId,
    epochFrom,
    epochTo,
    setKPIs,
    filters,
    disableQuery,
  ])

  useEffect(() => {
    if (data) {
      setKPIs({
        isLoading,
        error,
        KPIs: data,
      })
    } else {
      setKPIs(prev => ({
        ...prev,
        isLoading,
        error,
      }))
    }
  }, [data, isLoading, error, setKPIs])

  return KPIs
}

export const useStationKpiDepartures = (
  locationId,
  epochFrom,
  epochTo,
  filters,
  disableQuery
) => {
  const [{ data, isLoading, error }, fetchKpis] = useBackendWithAuth()
  const [KPIs, setKPIs] = useRecoilState(kpiDeparturesState)

  useEffect(() => {
    if (disableQuery) return
    fetchKpis(
      `/station_kpi/${locationId}/departures?${qs.stringify({
        start_time: epochFrom,
        end_time: epochTo,
        hide_cancelled: filters?.hideCancelled,
        hide_deviating: filters?.hideDeviating,
        hide_planned: filters?.hidePlanned,
        hide_missing: filters?.hideMissing,
        hide_even: filters?.hideEven,
        hide_odd: filters?.hideOdd,
        hide_compositionless: filters?.hideCompositionless,
        train_number: filters?.trainNumber,
        include_event_messages: filters?.includeEventMessages,
        include_events: filters?.includeEvents,
        sort_time_mode: filters?.sortTimeMode,
      })}`
    )
  }, [
    fetchKpis,
    locationId,
    epochFrom,
    epochTo,
    setKPIs,
    filters,
    disableQuery,
  ])

  useEffect(() => {
    if (data) {
      setKPIs({
        isLoading,
        error,
        KPIs: data,
      })
    } else {
      setKPIs(prev => ({
        ...prev,
        isLoading,
        error,
      }))
    }
  }, [data, isLoading, error, setKPIs])

  return KPIs
}

export const useRouteKpi = trainId => {
  const [{ data, isLoading, error }, fetchKpis] = useBackendWithAuth()
  const [KPIs, setKPIs] = useRecoilState(kpiRouteState)

  useEffect(() => {
    fetchKpis(`/route_kpi/${trainId}`)
  }, [fetchKpis, trainId])

  useEffect(() => {
    if (data) {
      setKPIs({
        isLoading,
        error,
        KPIs: data,
      })
    } else {
      setKPIs(prev => ({
        ...prev,
        isLoading,
        error,
      }))
    }
  }, [data, isLoading, error, setKPIs])

  return KPIs
}
