import { Paper } from "@mui/material"
import { minutesToHours } from "date-fns/esm"
import { useRecoilValue } from "recoil"
import styled from "styled-components"
import { trainNetworkStatisticsState } from "../recoil/atoms"
import Text from "./text"
import { useTranslation } from "react-i18next"

const Container = styled(Paper)`
  padding: 0.3em;
`

export default function TotalDelays({ className }) {
  const { totDelay } = useRecoilValue(trainNetworkStatisticsState)
  const { t } = useTranslation()
  return (
    <Container className={className} elevation={5}>
      <Text>{t("kpis.totalDelays")}</Text>
      <Text>
        {totDelay
          ? `${minutesToHours(totDelay)} timmar`
          : t("railroadOverview.dataNotAvailable")}
      </Text>
    </Container>
  )
}
