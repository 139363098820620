import {
  FETCH_MESSAGES_ERROR,
  FETCH_MESSAGES_SUCCESS,
  MESSAGE_EVENT_RECEIVED,
  FETCH_MESSAGES_BEGIN,
} from "../actions/types"

const initialState = {
  byTrainId: {},
  byRefObjectId: {},
  byReaderId: {},
  error: null,
  isLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case FETCH_MESSAGES_BEGIN:
      return { ...state, isLoading: true, error: null }
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        byRefObjectId: {
          ...state.byRefObjectId,
          [action.refObjectId]: action.payload,
        },
        byReaderId: {
          ...state.byReaderId,
          [action.readerId]: action.payload,
        },
        byTrainId: {
          ...state.byTrainId,
          [action.trainId]: action.payload,
        },
      }
    case MESSAGE_EVENT_RECEIVED: // Append to message lists
      const refObjectId = action.payload.referenceObject.refObjectId
      const readerId = action.payload.reader.SGLN
      const trainId = action.payload.train.trainId
      const prevRefObjMessages = state.byRefObjectId[refObjectId] || []
      const prevReaderMessages = state.byReaderId[readerId] || []
      const prevTrainMessages = state.byTrainId[trainId] || []

      return {
        ...state,
        byRefObjectId: {
          ...state.byRefObjectId,
          [refObjectId]: [action.payload.message, ...prevRefObjMessages], // Append to beginning of array
        },
        byReaderId: {
          ...state.byReaderId,
          [readerId]: [action.payload.message, ...prevReaderMessages],
        },
        byTrainId: {
          ...state.byTrainId,
          [trainId]: [action.payload.message, ...prevTrainMessages],
        },
      }
    default:
      return state
  }
}

export const selectTrainMessages = (state, train) =>
  state.messages.byTrainId[train?.trainId]?.[
    train?.latestMessage.payload.referenceObject
  ]

export default reducer
