import { TablePagination } from "@mui/material"
import { useEffect } from "react"

export default function Pagination({
  onRequestItems,
  pageSize,
  count,
  page,
  dataOffset,
  onPageChange,
  isLoading,
  ...props
}) {
  const maxPage = Math.max(Math.ceil(count / pageSize) - 1, 0)
  const dynamicPagination = typeof dataOffset === "number" && !!onRequestItems

  useEffect(() => {
    // Adjust page after fetch
    const targetPage = Math.ceil(dataOffset / pageSize)
    if (targetPage !== page && dynamicPagination) {
      onPageChange(null, maxPage)
    }
  }, [])

  return (
    <TablePagination
      {...props}
      nextIconButtonProps={{
        disabled: isLoading || (!dynamicPagination && page === maxPage),
      }}
      backIconButtonProps={{
        disabled: isLoading || page === 0,
      }}
      ActionsComponent={undefined}
      page={page}
      count={count}
      rowsPerPage={pageSize}
      onPageChange={(event, nextPage) => {
        if (nextPage > maxPage) {
          onRequestItems(nextPage * pageSize, pageSize)
        } else {
          onPageChange(event, nextPage)
        }
      }}
    />
  )
}
