/*
    Single Track component for updating number of wagons on the track  
*/

import React, { useEffect, useState } from "react"
import {
  Grid,
  Button,
  TextField,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material"
import axios from "axios"
// Icons
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

export default function SingelTrack(props) {
  const vagons_max = 25
  const [station, setStation] = useState("")
  const [train, setTrain] = useState("")
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [vagons, setVagons] = useState(0)

  useEffect(() => {
    // Getting track data
  }, [props.db_documnet])

  const addVagon = () => {
    if (Number(vagons) < vagons_max) {
      setVagons(Number(vagons) + 1)
    }
  }

  const removeVagon = () => {
    if (Number(vagons) > 0) {
      setVagons(Number(vagons) - 1)
    }
  }

  // Update track infromation
  const updateDatabase = () => {
    axios
      .post("http://127.0.0.1:8000/lakan_event", {
        event: props.db_documnet,
        time: "2021-06-01",
      })
      .then(function (response) {
        // handle success
        console.log(response)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={1}
      sx={{
        maxWidth: "17rem",
        margin: "0.5rem",
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
      }}
    >
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h5">
          <b> {props.track_number}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          sx={{ width: "90%", marginTop: "0.5rem" }}
          label="STN"
          value={station}
          onChange={e => setStation(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          sx={{ width: "90%", marginTop: "0.5rem" }}
          label="Tåg nummer"
          value={train}
          onChange={e => setTrain(e.target.value)}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", height: "4rem" }}
      >
        <TextField
          sx={{ width: "33%", margin: "0.2rem" }}
          label="Vagnar"
          type="number"
          value={vagons}
          onChange={e => setVagons(e.target.value)}
          InputProps={{
            inputProps: {
              max: vagons_max,
              min: 0,
            },
          }}
        />
        <Button
          onClick={removeVagon}
          sx={{
            backgroundColor: "#d45f5f",
            height: "100%",
            margin: "0.2rem",
            "&:hover": {
              backgroundColor: "#944040",
            },
          }}
        >
          <RemoveIcon />
        </Button>

        <Button
          onClick={addVagon}
          sx={{
            height: "100%",
            margin: "0.2rem",
            backgroundColor: "#89c781",
            color: "#000",
            "&:hover": {
              backgroundColor: "#539e49",
            },
          }}
        >
          <AddIcon />
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ height: "2rem", padding: "5%" }}
        >
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={(vagons / vagons_max) * 100}
            />
          </Box>
          <Box minWidth={35}>
            <h4 variant="body2" color="textSecondary">{`${Math.round(
              (vagons / vagons_max) * 100
            )}%`}</h4>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={updateDatabase}
          sx={{
            width: "90%",
            backgroundColor: "#499B5F",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#2B6C3C",
            },
          }}
        >
          Skicka updatering
        </Button>
      </Grid>
      <Grid item xs={12}>
        <p style={{ textAlign: "center" }}>
          Updaterad {selectedDate.toLocaleString("sv-SV")} <br />
          Max antal vagnar: {vagons_max}
        </p>
      </Grid>
    </Grid>
  )
}
