import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import randomstring from "randomstring";
import axios from "axios";
import DateTimePicker from "@mui/lab/DateTimePicker";
import {
  Grid,
  DialogContent,
  Button,
  Dialog,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Fab,
} from "@mui/material";
// Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Components
import EventReportSentMes from "./successMessage";

const GridCenter = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function ReportEventDialog(props) {
  const [radomID, setRadomID] = useState("");
  const [reasonCodes, setReasonCodes] = useState([]);

  const [groupType, setGroupType] = useState("");
  const [groupTypeLevel1, setGroupTypeLevel1] = useState("");
  const [groupTypeLevel1List, setGroupTypeLevel1List] = useState([]);
  const [groupTypeLevel2, setGroupTypeLevel2] = useState("");
  const [groupTypeLevel2List, setGroupTypeLevel2List] = useState([]);
  const [groupTypeLevel3, setGroupTypeLevel3] = useState("");
  const [groupTypeLevel3List, setGroupTypeLevel3List] = useState([]);
  const [eventPriority, setEventPriority] = useState("");
  const [selectedDate, handleDateChange] = useState(new Date().setHours(new Date().getHours() + 1));
  const [selectedDateUpdate, handleDateUpdateChange] = useState(null);
  const [multilineText, setMultilineText] = useState("");

  const [textStation, setTextStation] = useState("");
  const [textTainNum, setTextTainNum] = useState("");

  const [eventSentMsg, setEventSentMsg] = useState("");

  // Component render get TRV reason codes
  useEffect(() => {
    // Generating random string for task id
    const randomIdString = randomstring.generate(7);
    setRadomID(randomIdString);

    let config = {
      headers: { "Content-Type": "text/xml" },
    };
    let xml =
      "<REQUEST>" +
      '<LOGIN authenticationkey="4c19837571f04ce191140305657bcf4d"/>' +
      '<QUERY objecttype="ReasonCode" schemaversion="1">' +
      "</QUERY> </REQUEST>";
    axios
      .post("https://api.trafikinfo.trafikverket.se/v2/data.json", xml, config)
      .then(function (response) {
        setReasonCodes(response.data.RESPONSE.RESULT[0].ReasonCode);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // Push new task to event board
  const pushNewTaskUpdate = async () => {
    // let nowTime = new Date()

  };

  const groupTypeChanged = (value) => {
    setGroupType(value);
    let newArray = reasonCodes.filter(function (obj) {
      return obj.GroupDescription === value;
    });
    let unique = [...new Set(newArray.map((item) => item.Level1Description))];
    setGroupTypeLevel1List(unique);
  };

  const groupTypeLevel1Changed = (value) => {
    setGroupTypeLevel1(value);
    let newArray = reasonCodes.filter(function (obj) {
      return obj.GroupDescription === groupType && obj.Level1Description === value;
    });
    let unique = [...new Set(newArray.map((item) => item.Level2Description))];
    setGroupTypeLevel2List(unique);
  };

  const groupTypeLevel2Changed = (value) => {
    setGroupTypeLevel2(value);
    let newArray = reasonCodes.filter(function (obj) {
      return (
        obj.GroupDescription === groupType &&
        obj.Level1Description === groupTypeLevel1 &&
        obj.Level2Description === value
      );
    });
    let unique = [...new Set(newArray.map((item) => item.Level3Description))];
    setGroupTypeLevel3List(unique);
  };

  const creatNewRapport = () => {
    setEventSentMsg("");
  };

  return (
    <Dialog onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Fab sx={{ color: "#fff" }} color={"secondary"} onClick={props.handleClose}>
              <CloseRoundedIcon fontSize="large" />
            </Fab>
          </Grid>

          {eventSentMsg !== "" ? (
            <>
              <EventReportSentMes eventSentMsg={eventSentMsg} creatNewRapport={creatNewRapport} />
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <h1 style={{ textAlign: "center", margin: "0px" }}>Händelse rapport</h1>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Tåg nummer"
                  value={textTainNum}
                  onChange={(event) => setTextTainNum(event.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Station"
                  value={textStation}
                  onChange={(event) => setTextStation(event.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">(1) Grupp</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={groupType}
                    onChange={(event) => groupTypeChanged(event.target.value)}
                    label="(1) Grupp"
                  >
                    <MenuItem value="Annonseringstexter">Annonseringstexter</MenuItem>
                    <MenuItem value="Orsaker nya principer">Orsaker nya principer</MenuItem>
                    <MenuItem value="Annat">Annat</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {groupType === "Annat" ? (
                <></>
              ) : (
                <>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-outlined-label">(2) Orsak nivå 1</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={groupTypeLevel1}
                        onChange={(event) => groupTypeLevel1Changed(event.target.value)}
                        label="(2) Orsak nivå 1"
                      >
                        {groupTypeLevel1List.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-outlined-label">(3) Orsak nivå 2</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={groupTypeLevel2}
                        onChange={(event) => groupTypeLevel2Changed(event.target.value)}
                        label="(3) Orsak nivå 2"
                      >
                        {groupTypeLevel2List.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-outlined-label">(4) Orsak nivå 3</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={groupTypeLevel3}
                        onChange={(event) => setGroupTypeLevel3(event.target.value)}
                        label="(4) Orsak nivå 3"
                      >
                        {groupTypeLevel3List.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">Prioritet</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={eventPriority}
                    onChange={(event) => setEventPriority(event.target.value)}
                    label="Prioritet"
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: "rgba(255, 0, 0, 0.3)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                        },
                      }}
                      value="high"
                    >
                      Hög
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: "rgba(245, 215, 66, 0.3)",
                        "&:hover": {
                          backgroundColor: "rgba(245, 215, 66, 0.7)",
                        },
                      }}
                      value="medium"
                    >
                      Medel
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: "rgba(33, 101, 143, 0.3)",
                        "&:hover": {
                          backgroundColor: "rgba(33, 101, 143, 0.7)",
                        },
                      }}
                      value="low"
                    >
                      Låg
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <GridCenter item xs={6}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} sx={{ width: "100%" }} />}
                  label="Tidsesimat åtgärdat"
                  minDate={new Date()}
                  value={selectedDate}
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                />
              </GridCenter>

              <Grid item xs={6}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} sx={{ width: "100%" }} />}
                  label="Status uppdatering"
                  minDate={new Date()}
                  value={selectedDateUpdate}
                  onChange={(newValue) => {
                    handleDateUpdateChange(newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Fri text"
                  rows={4}
                  value={multilineText}
                  onChange={(event) => setMultilineText(event.target.value)}
                  fullWidth
                />
              </Grid>

              <GridCenter item xs={12}>
                <Button onClick={pushNewTaskUpdate} variant="outlined" sx={{ fontSize: "1.3rem" }} color="secondary">
                  Rapportera
                </Button>
              </GridCenter>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
