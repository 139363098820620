/*
  Event board 
  --------------------------
*/

import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import TaskColumn from "./task_column"
import { DragDropContext } from "react-beautiful-dnd"

const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "Take out the garbage" },
    "task-2": { id: "task-2", content: "Watch my favorite show" },
    "task-3": { id: "task-3", content: "Charge my phone" },
    "task-4": { id: "task-4", content: "Cook dinner" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "",
      taskIds: ["task-1", "task-2"],
    },
    "column-2": {
      id: "column-2",
      title: "",
      taskIds: [],
    },
    "column-3": {
      id: "column-3",
      title: "",
      taskIds: [],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1", "column-2", "column-3"],
}

export default function EventBoard() {
  const [boardData, setBoardData] = useState(initialData)

  const pushBoardUpdate = async newBoardData => {}

  const onDragStart = () => {}

  const onDragUpdate = update => {
    const { destination } = update

  }

  const onDragEnd = result => {
    const { destination, source, draggableId } = result

    // Draggable item dropped outside
    if (!destination) {
      return
    }
    // Draggable item ended up in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // Reordering draggable items
    const start = boardData.columns[source.droppableId]
    const finish = boardData.columns[destination.droppableId]

    // Moving in the same column
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      }

      let newBoardObj = {
        ...boardData,
        columns: {
          ...boardData.columns,
          [newColumn.id]: newColumn,
        },
      }

      setBoardData(newBoardObj)
      pushBoardUpdate(newBoardObj)
      return
    }

    // Moving to an other column
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    }

    let newBoardObj = {
      ...boardData,
      columns: {
        ...boardData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }

    setBoardData(newBoardObj)
    pushBoardUpdate(newBoardObj)
    console.log("Result", result)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <DragDropContext
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
          >
            {boardData.columnOrder.map(columID => {
              let column = boardData.columns[columID]
              let tasks = column.taskIds.map(taskID => boardData.tasks[taskID])
              return (
                <TaskColumn
                  key={column.id}
                  column={column}
                  tasks={tasks}
                  boardDataFull={boardData}
                />
              )
            })}
          </DragDropContext>
        </Grid>
      </Grid>
    </Grid>
  )
}
