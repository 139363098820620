import React, { useState } from "react"
import Text from "../../components/text"
import RouteIcon from "@mui/icons-material/Route"
import BungalowIcon from "@mui/icons-material/Bungalow"
import { Badge, Stack } from "@mui/material"
import styled from "styled-components"

const Container = styled("div")`
  margin: 2rem 0;
`

export default function TrafficImpact({ routeImpact }) {
  const [showStationList, setShowStationList] = useState(false)

  const toggleStationList = () => {
    setShowStationList(!showStationList)
  }

  return (
    <Container>
      <Text bold>Trafik påverkan</Text>
      <Text>Antal sträckor påverkade: {routeImpact?.length}</Text>
      {routeImpact?.map(obj => {
        return (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{
                paddingTop: "0.9rem",
                borderBottomStyle: "dashed",
                borderWidth: "2px",
                width: "50%",
              }}
            >
              <RouteIcon size="small" />
              <Text sx={{ width: "15rem" }}>
                {obj.FromLocation ? obj.FromLocation[0] : "Ej känd"} {" - "}
                {obj.ToLocation ? obj.ToLocation[0] : "Ej känd"}
              </Text>

              <Badge
                badgeContent={
                  obj.AffectedLocation ? obj.AffectedLocation.length : 0
                }
                color="secondary"
                onClick={toggleStationList}
              >
                <BungalowIcon color="action" />
              </Badge>
            </Stack>
            {showStationList &&
              obj.AffectedLocation.map(obj => {
                return <p>{obj.LocationSignature}</p>
              })}
          </>
        )
      })}
    </Container>
  )
}
