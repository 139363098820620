import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import english from "./utils/translations/en.json"
import swedish from "./utils/translations/sv.json"

export const resources = {
  en: {
    translation: english,
  },
  sv: {
    translation: swedish,
  },
} as const

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  fallbackLng: "sv",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
