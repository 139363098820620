import styled from "styled-components"
import { COLOR_ACCENT_SECONDARY, COLOR_PRIMARY } from "../../utils/colors"
import Text from "../Text"

const Wrapper = styled.div`
  background-color: white;
  border: 0.3px solid ${COLOR_PRIMARY};
  border-radius: ${({ round }) => (round ? "50%" : "3px")};
  padding: 0.3em 0.2em 0 0.2em;
  display: inline-block;
  ${({ glow }) => glow && `box-shadow: 0 0 5px 5px ${COLOR_ACCENT_SECONDARY};`}

  ${({ $isNewlyUpdated }) =>
    $isNewlyUpdated &&
    `
      animation: pulse 6s ease;
    `}
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 5px 5px ${COLOR_ACCENT_SECONDARY};
    }
    100% {
      box-shadow: none;
    }
  }
`

const IconWrapper = ({
  children,
  Icon,
  isNewlyUpdated,
  round = false,
  glow,
  count,
  style,
  Ω,
}) => (
  <Wrapper
    glow={glow}
    style={style}
    round={round}
    $isNewlyUpdated={isNewlyUpdated}
  >
    {Icon && <Icon style={{ color: COLOR_PRIMARY }} fill={COLOR_PRIMARY} />}
    {children}
    {count && (
      <Text noMargin small>
        {count}
      </Text>
    )}
  </Wrapper>
)

export default IconWrapper
