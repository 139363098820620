import React from "react";
import { Stack } from "@mui/material";
import ProcessTask from "./ProcessTask";

export default function ActionProcessTracking() {
  return (
    <Stack direction="row">
      <ProcessTask complected={50} task_name={"Task"} />
      <ProcessTask complected={100} task_name={"Task"} />
      <ProcessTask complected={0} task_name={"Task"} />
    </Stack>
  );
}
