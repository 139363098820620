import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { DatePicker } from "@mui/lab"
import { Grid, IconButton, Paper, styled, TextField } from "@mui/material"
import { useState } from "react"
import { useRouteMatch } from "react-router-dom"
import TrainInsight from "../../components/train_insight"
import TrainRoutesMap from "../../components/train_routes_map"
import { useUserHistory } from "../../hooks/history"
import { useTrainRegister } from "../../hooks/trains"
import TrainList from "./TrainList"

const Container = styled(Grid)`
  padding: 1em;
  flex-grow: 1;
`

const SubContainer = styled(Paper)`
  padding: 1em 0;
  min-height: 100vh;
`

const HeaderContainer = styled(Grid)`
  padding: 0 1em;
  margin-bottom: 1em;
`

export default function TrainInfo() {
  const [query, setQuery] = useState("")
  const [date, setDate] = useState(new Date())

  const { trains, isLoading } = useTrainRegister({
    date: date.getTime(),
    trainNumber: query,
  })
  const { page, trainId } = useRouteMatch("/:page/:trainId?")?.params || {}
  const history = useUserHistory()

  return (
    <Container container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} md={8}>
        <SubContainer>
          <HeaderContainer container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    fullWidth
                    size="small"
                    label="Välj datum"
                  />
                )}
                onChange={setDate}
                value={date}
              />
            </Grid>
            <Grid item xs={query ? 5 : 6}>
              <TextField
                fullWidth
                value={query}
                label="Ange tåg-ID"
                size="small"
                onChange={e => setQuery(e.target.value)}
              />
            </Grid>
            {query && (
              <Grid item xs={1}>
                <IconButton onClick={() => setQuery("")}>
                  <CloseRoundedIcon />
                </IconButton>
              </Grid>
            )}
          </HeaderContainer>
          <TrainList trains={trains} isLoading={isLoading} />
        </SubContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        {trainId && (
          <SubContainer>
            <TrainInsight
              trainId={trainId}
              onDeselectTrain={() => history.push(`/${page}`)}
            />
          </SubContainer>
        )}
        {!trainId && (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <TrainRoutesMap trains={trains} />
          </div>
        )}
      </Grid>
    </Container>
  )
}
