import { format } from "date-fns"
import { useSelector } from "react-redux"
import itemRoutes from "../../routes.json"
import { formatRcmfId, formatTrainId } from "../../utils/dataUtils"
import Text from "../Text"
import WagonIcon from "../icons/WagonIcon"
import Link from "../Link"
import ListItemContainer from "./ListItemContainer"
import ROUTES from "../../../../ROUTES.json"

const RefObjectListItem = ({ item, isNewlyUpdated }) => {
  const reader = useSelector(
    state => state.readers.byId[item.latestMessage.payload.at]
  )

  const { trainDirection, time } = item.latestMessage.payload
  return (
    <ListItemContainer
      itemId={item.refObjectId}
      title={formatRcmfId(item.refObjectId)}
      Icon={WagonIcon}
      latestMessageId={item.latestMessage.messageId}
      isNewlyUpdated={isNewlyUpdated}
    >
      <Text small>
        <b>Latest reading: </b>
        {format(new Date(time), "yyyy-MM-dd HH:mm:ss")}
      </Text>
      {trainDirection && (
        <Text small>
          <b>Direction: </b>
          {trainDirection}
        </Text>
      )}
      {item.technicalTrainId && (
        <Text small>
          <b>Train number: </b>
          <Link
            to={`${ROUTES.RFID}/${itemRoutes.TRAINS}/${item.technicalTrainId}`}
            onClick={e => e.stopPropagation()}
          >
            {formatTrainId(item.technicalTrainId)}
          </Link>
        </Text>
      )}
      {item.vehiclePosition && (
        <Text small>
          <b>Vehicle position: </b>
          {item.vehiclePosition}
        </Text>
      )}
      <Text small>
        <b>Reader: </b>
        {reader?.SGLN ? (
          <Link to={`${ROUTES.RFID}/${itemRoutes.READERS}/${reader.SGLN}`}>
            {reader?.Name}
          </Link>
        ) : (
          "Unknown"
        )}
      </Text>
    </ListItemContainer>
  )
}

export default RefObjectListItem
