import React from "react"
import { getTitle } from "../../utils/domain"

export default function Logo() {
  const title = getTitle()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250.042"
      height="48"
      viewBox="0 0 250.042 48"
      style={{
        width: "80%",
        height: "100%",
        paddingBottom: "1rem",
        maxWidth: "550px",
      }}
    >
      <g
        id="LogoWithText"
        transform="translate(-1576.958 -1329)"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <text
          id="Station_CDM"
          data-name={title}
          transform="translate(1623 1367)"
          fill="#46535c"
          fontSize="36"
          fontFamily="Roboto-Bold, Roboto"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            {title}
          </tspan>
        </text>
        <g
          id="Group_190"
          data-name="Group 190"
          transform="translate(-7 -62.246)"
        >
          <path
            id="Path_386"
            data-name="Path 386"
            d="M-2803.042-3160.334v22.238s-.149,6.979,7.988,7.2c.017,0-3.852,4.115-3.852,4.115h4.472l4.769-4.108,8.968,0,4.147,4.113h4.854l-3.712-4.115s8.06.3,8.06-7.629v-21.806s-2.519-8.5-12.738-8.42-1.02,0-11.425,0S-2803.042-3160.334-2803.042-3160.334Z"
            transform="translate(4387 4563)"
            fill="#46535c"
          />
          <circle
            id="Ellipse_70"
            data-name="Ellipse 70"
            cx="3.5"
            cy="3.5"
            r="3.5"
            transform="translate(1588 1421.279)"
            fill="#b2c8cb"
          />
          <circle
            id="Ellipse_71"
            data-name="Ellipse 71"
            cx="3.5"
            cy="3.5"
            r="3.5"
            transform="translate(1608 1421.279)"
            fill="#b2c8cb"
          />
          <rect
            id="Rectangle_231"
            data-name="Rectangle 231"
            width="13"
            height="10"
            rx="2"
            transform="translate(1587 1403.279)"
            fill="#b2c8cb"
          />
          <rect
            id="Rectangle_232"
            data-name="Rectangle 232"
            width="13"
            height="10"
            rx="2"
            transform="translate(1603 1403.279)"
            fill="#b2c8cb"
          />
        </g>
      </g>
    </svg>
  )
}
