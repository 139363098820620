import { DateTimePicker } from "@mui/lab"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { IconButton, TextField } from "@mui/material"
import FlexDiv from "./flex_div"

type Props = {
  startTime: number
  onChange: (startTime: number | undefined) => void
  open: boolean
  setOpen: (open: boolean) => void
}

export default function DateTimeSelector({
  startTime,
  onChange,
  open,
  setOpen,
}: Props) {
  const localOnChange = (newDate: Date | null | undefined) =>
    onChange(newDate?.getTime() ?? undefined)

  return (
    <DateTimePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={startTime ? new Date(startTime) : null}
      label="Från"
      renderInput={props => (
        <FlexDiv>
          <TextField onClick={() => setOpen(true)} disabled {...props} />
          {startTime && (
            <IconButton onClick={() => localOnChange(undefined)}>
              <CloseRoundedIcon />
            </IconButton>
          )}
        </FlexDiv>
      )}
      onChange={localOnChange}
    />
  )
}
