import { Checkbox, FormControlLabel } from "@mui/material"
import { ViewColumn } from "@mui/icons-material"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import PopoverButton from "../../../components/PopoverButton"
import { useColumns } from "../utils/columns"

export type ColumnFilters = {
  setShowColumn: (name: string, show: boolean) => void
  shownColumns: string[]
  shownColumnIndices: number[]
}

export const usePlankColumnsFilter = (): ColumnFilters => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    JSON.parse(localStorage.getItem("hiddenColumns") ?? "[]") as string[]
  )

  const columns = useColumns()

  useEffect(() => {
    localStorage.setItem("hiddenColumns", JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return {
    setShowColumn: (name: string, show: boolean) => {
      if (columns.includes(name)) {
        setHiddenColumns(
          show
            ? hiddenColumns.filter(c => c !== name)
            : [...hiddenColumns, name]
        )
      }
    },
    shownColumns: columns.filter(c => !hiddenColumns.includes(c)),
    shownColumnIndices: columns
      .map((_, index) => index)
      .filter(i => !hiddenColumns.includes(columns[i])),
  }
}

type Props = { columnFilters: ColumnFilters }

const PlankColumns: React.FC<Props> = props => {
  const { t } = useTranslation()
  const columns = useColumns()
  const { shownColumns, setShowColumn } = props.columnFilters
  return (
    <PopoverButton icon={<ViewColumn />} title={t("plank.columnsFilter")}>
      <>
        {columns.map(column => (
          <FormControlLabel
            key={column}
            label={column}
            control={
              <Checkbox
                checked={shownColumns.includes(column)}
                onChange={(_, checked) => setShowColumn(column, checked)}
              />
            }
          />
        ))}
      </>
    </PopoverButton>
  )
}

export default PlankColumns
