import React, { useState } from "react"
import {
  Grid,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
} from "@mui/material"
// Recoil
import { atom } from "recoil"
import { useRecoilState } from "recoil"

// -----------------------------------
// Atoms
export const layersSelected = atom({
  key: "layersSelected",
  default: {
    railwayMap: true,
    heatmapActual: false,
    heatmapDelay: true,
    stations: true,
    trainMessages: true,
    trains: false,
    rfidReaders: true,
  },
})

export default function MapToggleLayers() {
  const [layersOnMap, setLayersOnMap] = useRecoilState(layersSelected)
  const [SelectedHeatmap, setSelectedHeatmap] = useState("delay")

  const toggleStations = () => {
    setLayersOnMap({
      ...layersOnMap,
      stations: !layersOnMap.stations,
    })
  }

  const toggleRfidReaders = () => {
    setLayersOnMap({
      ...layersOnMap,
      rfidReaders: !layersOnMap.rfidReaders,
    })
  }

  const toggleTrainMessages = () => {
    setLayersOnMap({
      ...layersOnMap,
      trainMessages: !layersOnMap.trainMessages,
    })
  }

  const toggleRailwayMap = () => {
    setLayersOnMap({
      ...layersOnMap,
      railwayMap: !layersOnMap.railwayMap,
    })
  }

  const toggleTrains = () => {
    setLayersOnMap({
      ...layersOnMap,
      trains: !layersOnMap.trains,
    })
  }

  const handleHeatmapSelection = (event, newAlignment) => {
    if (newAlignment === "delay") {
      setLayersOnMap({
        ...layersOnMap,
        heatmapDelay: true,
        heatmapActual: false,
      })
    } else if (newAlignment === "actual") {
      setLayersOnMap({
        ...layersOnMap,
        heatmapDelay: false,
        heatmapActual: true,
      })
    } else {
      setLayersOnMap({
        ...layersOnMap,
        heatmapDelay: false,
        heatmapActual: false,
      })
    }
    setSelectedHeatmap(newAlignment)
  }

  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <ToggleButtonGroup
            value={SelectedHeatmap}
            exclusive
            onChange={handleHeatmapSelection}
            color="secondary"
          >
            <ToggleButton value="delay" size="small">
              Inraporterade förseningar
            </ToggleButton>
            <ToggleButton value="actual" size="small">
              Tidshålling på ankomster och avgångar
            </ToggleButton>
          </ToggleButtonGroup>

          <FormControlLabel
            control={
              <Switch
                checked={layersOnMap.stations}
                onChange={toggleStations}
                color="secondary"
              />
            }
            label="Stations"
            labelPlacement="start"
          />

          <FormControlLabel
            control={
              <Switch
                checked={layersOnMap.rfidReaders}
                onChange={toggleRfidReaders}
                color="secondary"
              />
            }
            label="RFID readers"
            labelPlacement="start"
          />

          <FormControlLabel
            control={
              <Switch
                checked={layersOnMap.trainMessages}
                onChange={toggleTrainMessages}
                color="secondary"
              />
            }
            label="Train messages"
            labelPlacement="start"
          />

          <FormControlLabel
            control={
              <Switch
                checked={layersOnMap.railwayMap}
                onChange={toggleRailwayMap}
                color="secondary"
              />
            }
            label="Railway Map"
            labelPlacement="start"
          />

          <FormControlLabel
            control={
              <Switch
                checked={layersOnMap.trains}
                onChange={toggleTrains}
                color="secondary"
              />
            }
            label="Trains"
            labelPlacement="start"
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
