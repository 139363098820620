const getTrackFromLocation = (
  locationField: string | undefined
): string | null => {
  const split = locationField?.split(":")
  if (split?.length !== 5) return null
  const track = split.splice(-1)[0]
  if (track === "so 20") {
    return null
  }

  return track
}

export default getTrackFromLocation
