import MaterialTable, { MaterialTableProps } from "@material-table/core"

export default function SwedishMaterialTable<RowData extends object>(
  props: MaterialTableProps<RowData>
) {
  return (
    <MaterialTable
      {...props}
      localization={{
        body: { emptyDataSourceMessage: "" },
        pagination: {
          labelDisplayedRows: "{from} - {to} av {count}",
          labelRows: "rader",
          labelRowsPerPage: "Rader per sida",
          firstAriaLabel: "Första sidan",
          firstTooltip: "Första sidan",
          previousAriaLabel: "Föregående sida",
          previousTooltip: "Föregående sida",
          nextAriaLabel: "Nästa sida",
          nextTooltip: "Nästa sida",
          lastAriaLabel: "Sista sidan",
          lastTooltip: "Sista sidan",
        },
        toolbar: {
          searchTooltip: "Sök tåg-ID",
          searchPlaceholder: "Sök tåg-ID",
        },
      }}
    />
  )
}
