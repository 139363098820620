export const getTitle = () => {
  switch (window.location.hostname) {
    case "yardcdm.deplide.org":
      return "YardCDM"
    case "stationcdm.deplide.org":
      return "StationCDM"
    case "localhost":
      return "DevCDM"
    default:
      return "RailwayCDM"
  }
}

export const getWsBaseUrl = () => {
  const protocol =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "ws"
      : "wss"

  return `${protocol}://${window.location.host}/websocket`
}
