import Marker from "react-leaflet-enhanced-marker"
import WagonIcon from "../icons/WagonIcon"
import IconWrapper from "./IconWrapper"
import ItemsLayer from "./ItemsLayer"

const RefObjectsLayer = ({ items, Icon = WagonIcon, readers, onClick }) => (
  <ItemsLayer Icon={Icon}>
    {Object.values(items).map((referenceObject, index) => {
      const reader = readers[referenceObject.latestMessage.payload.at]

      if (!reader?.Latitude || !reader?.Longitude) return null

      const { Latitude, Longitude } = reader

      const isNewlyUpdated =
        new Date().getTime() - referenceObject.latestMessage.reportedAt < 10000

      return (
        <Marker
          key={index}
          position={[Latitude, Longitude]}
          icon={<IconWrapper Icon={Icon} isNewlyUpdated={isNewlyUpdated} />}
          eventHandlers={{
            click: () => onClick(referenceObject),
          }}
          zIndex={10}
          data={{
            isNewlyUpdated,
          }}
        />
      )
    })}
  </ItemsLayer>
)

export default RefObjectsLayer
