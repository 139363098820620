import { Grid } from "@mui/material"
import { styled } from "@mui/material"

const GridCenter = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default GridCenter
