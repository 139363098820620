import { styled } from "@mui/material"
import { useState } from "react"
import FlexDiv from "../../components/flex_div"
import Unit from "./unit"
import DataWrapper from "../../components/data_wrapper"
import Text from "../../components/text"
import { useRouteMatch } from "react-router-dom"
import { useTrain } from "../../hooks/trains"

const MainContainer = styled("div", { shouldForwardProp: false })`
  padding: 0.2em;
  margin: 0 5px;
  //border: 2px ${({ $actual }) => ($actual ? "solid" : "dashed")} white;
  border: 3px dashed ${({ actual }) => (actual ? "transparent" : "white")};
  cursor: pointer;
  position: relative;
  //width: ${({ width }) => width - 10}px;
`

const LengthLabel = styled(Text)`
  position: absolute;
  right: -8px;
  bottom: -6px;
  background-color: #fff;
  z-index: 10;
  padding: 0 2px;
`

export default function Vehicle({ trainId, widthScale, timeSequence }) {
  const { page, stationId } = useRouteMatch("/:page/:stationId?")?.params || {}

  const [showInfo, setShowInfo] = useState(false)

  const { train, isLoading } = useTrain({
    trainId,
    locationId: stationId,
    websocket: showInfo,
  })

  const showLoading = isLoading || (trainId && !train)
  const event =
    {
      arrivals: train?.arrivalEvent,
      departures: train?.departureEvent,
    }[timeSequence] || {}
  const { plannedVehicle, estimatedVehicle, actualVehicle } = event

  const latestVehicle =
    actualVehicle ||
    estimatedVehicle ||
    plannedVehicle ||
    train?.latestActualVehicle ||
    train?.latestEstimatedVehicle ||
    train?.latestPlannedVehicle

  const units = latestVehicle?.units || []

  return (
    <DataWrapper isLoading={showLoading}>
      {train && (
        <MainContainer
          onClick={() => setShowInfo(!showInfo)}
          actual={train?.arrivalEvent?.latestTimeType === "Actual"}
          width={widthScale * latestVehicle?.totalLength || 200}
        >
          <LengthLabel bold size="tiny">
            {latestVehicle?.totalLength} m
          </LengthLabel>
          <FlexDiv>
            {units.length === 0 && (
              <Unit
                showInfo={showInfo}
                showLabels
                train={train}
                event={event}
                timeSequence={timeSequence}
              />
            )}
            {units.map((unit, index) => (
              <Unit
                key={unit.id || index}
                unit={unit}
                showInfo={showInfo}
                showLabels={index === 0}
                train={train}
                event={event}
                timeSequence={timeSequence}
              />
            ))}
          </FlexDiv>
        </MainContainer>
      )}
    </DataWrapper>
  )
}
