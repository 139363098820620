import { Link as RouterLink } from "react-router-dom"
import styled from "styled-components"
import { COLOR_PRIMARY } from "../../utils/colors"

const StyledLink = styled(RouterLink)`
  color: ${COLOR_PRIMARY};
`

const Link = props => (
  <StyledLink {...props} onClick={e => e.stopPropagation()} />
)

export default Link
