import { fetchMessagesForReader } from "../../actions/messages"
import { fetchReader } from "../../actions/readers"
import DataWrapper from "../DataWrapper"
import Text from "../Text"
import Timeline from "../Timeline"
import { useEffect } from "react"
import ReaderIcon from "../icons/ReaderIcon"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import itemRoutes from "../../routes.json"
import BaseContainer from "./BaseContainer"
import { useAuthDispatch } from "../../../../hooks/rfidBackend"

const Reader = ({ id }) => {
  const dispatch = useAuthDispatch()
  const history = useHistory()
  const messages = useSelector(state => state.messages.byReaderId[id]) || []
  const reader = useSelector(state => state.readers.byId[id])
  const isLoadingReader =
    useSelector(state => state.readers.isLoading) || !reader
  const readerError = useSelector(state => state.readers.error)
  const { error: messageError, isLoading: isLoadingMessages } = useSelector(
    state => state.messages
  )

  useEffect(() => {
    if (!reader) {
      dispatch(fetchReader(id))
    }
  }, [dispatch, id, reader])

  useEffect(() => {
    dispatch(fetchMessagesForReader(id))
  }, [dispatch, id])

  return (
    <BaseContainer
      TitleIcon={ReaderIcon}
      titleText={"RFID reader timeline"}
      headerContent={
        <DataWrapper
          fixedHeight
          isLoading={isLoadingReader}
          error={readerError}
        >
          <Text light>
            <b>Name: </b>
            {reader?.Name}
          </Text>
          <Text light>
            <b>Number of readings: </b>
            {messages.length}
          </Text>
          {reader?.["Current status"] && (
            <Text light>
              <b>Status: </b>
              {reader?.["Current status"]}
            </Text>
          )}
        </DataWrapper>
      }
      bodyContent={
        <DataWrapper error={messageError} isLoading={isLoadingMessages}>
          <Timeline
            messages={messages}
            onClick={message =>
              message.payload.referenceObject &&
              history.push(
                `/${itemRoutes.REF_OBJECTS}/${message.payload.referenceObject}`
              )
            }
          />
        </DataWrapper>
      }
    />
  )
}

export default Reader
