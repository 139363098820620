import { styled } from "@mui/material"

const AutoGrid = styled("div")`
  display: grid;
  grid-template-columns: auto auto auto;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    grid-template-columns: auto auto auto auto;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    grid-template-columns: auto auto auto auto;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: auto auto;
  }
`

export default AutoGrid
