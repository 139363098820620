import React from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";

export default function ProcessTask(props) {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress variant="determinate" value={props.complected} {...props} size={33} />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            fontSize: "0.5rem",
          }}
        >
          {`${Math.round(props.complected)}%`}
        </Typography>
      </Grid>

      <Grid item 
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" color="text.secondary" sx={{}}>
          {props.task_name}
        </Typography>
      </Grid>
    </Grid>
  );
}
