import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { IconButton, Paper, styled } from "@mui/material"
import React, { useState } from "react"
// Components
import { format } from "date-fns"
import { useRouteMatch } from "react-router-dom"
import { useUserHistory } from "../../hooks/history"
import TrainInsight from "../train_insight"
import MapBox from "./mapBox"
import StatusSideBar from "./statusSideBar"

const Container = styled("div")`
  position: relative;
  flex: 1;
`

const MapContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const SideBarContainer = styled(Paper)`
  position: absolute;
  top: 1em;
  right: 1em;
  bottom: 1em;
  max-width: 30%;
  padding-top: 1em;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 50%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    z-index: 100;
  }
`

const CloseButtonWrapper = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
`

export default function Map(props) {
  const initialState = {
    layer: {
      id: "",
    },
  }
  const [selectedObjectOnMap, setSelectedObjectOnMap] = useState(initialState)

  const { page, trainId } =
    useRouteMatch("/:page/train/:trainId?")?.params || {}
  const history = useUserHistory()

  return (
    <Container>
      <MapContainer>
        <MapBox
          stationStatistics={props.stationStatistics}
          setObj={obj => {
            if (obj.layer.id === "train-locations-layer") {
              history.push(
                `/map/train/${format(new Date(), "yyyyMMdd")}.${
                  obj.object.tagNr
                }`
              )
            } else {
              if (trainId) {
                history.push("/map")
              }
              setSelectedObjectOnMap(obj)
            }
          }}
          trainMessage={props.trainMessage}
          trainLocations={props.trainLocations}
          rfidReaders={props.rfidReaders}
        />
      </MapContainer>
      {!trainId && selectedObjectOnMap?.layer?.id && (
        <SideBarContainer elevation={5}>
          <CloseButtonWrapper>
            <CloseRoundedIcon
              fontSize="medium"
              onClick={() => setSelectedObjectOnMap(initialState)}
            />
          </CloseButtonWrapper>
          <StatusSideBar selectedObjectOnMap={selectedObjectOnMap} />
        </SideBarContainer>
      )}
      {trainId && (
        <SideBarContainer>
          <TrainInsight
            onDeselectTrain={() => history.push(`/${page}`)}
            trainId={trainId}
          />
        </SideBarContainer>
      )}
    </Container>
  )
}
