import React from "react";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useTheme } from "@mui/material/styles";
// Components
import ActionProcessTracking from "./ActionProcessTracking";

export default function EventTaskTrack(props) {
  const theme = useTheme();

  const print = () => {
    console.log(props);
  };

  return (
    <Draggable key={props.task.id} draggableId={props.task.id} index={props.index}>
      {(provided, snapshot) => {
        return (
          <Paper sx={{ margin: "0.2rem" }} onClick={print}>
            <Box
              key={props.task.id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={
                snapshot.isDragging
                  ? {
                      backgroundColor: theme.palette.secondary.main,
                      width: "12rm",
                      borderRadius: "0.5rem",
                      padding: "0.5rem",
                    }
                  : {
                      backgroundColor: "#fff",

                      width: "12rm",
                      borderRadius: "0.5rem",
                      padding: "0.5rem",
                    }
              }
            >
              <ActionProcessTracking />
              <hr style={{ margin: "2px", border: "1px solid " + theme.palette.primary.light }} />
              <Grid container>
                <Grid item xs={6}>
                  <Typography varian={"body"} align={'center'}>Ankomst</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography varian={"body"} align={'center'}>Avgång</Typography>
                </Grid>

                <Grid item xs={6}>
                  {props.task.ActivityType === "Ankomst" ? (
                    <Typography varian={"body"} align={'center'}>
                      <b> {props.task.TechnicalDateTime ? props.task.TechnicalTrainIdent : ""}</b>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  {props.task.ActivityType === "Avgang" ? (
                    <Typography varian={"body"} align={'center'}>
                      <b> {props.task.TechnicalDateTime ? props.task.TechnicalTrainIdent : ""}</b>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={6}>
                  {props.task.ActivityType === "Ankomst" ? (
                    <Typography varian={"body"} align={'center'}>
                      {props.task.TechnicalDateTime ? props.task.TechnicalDateTime.slice(11, 16) : ""}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  {props.task.ActivityType === "Avgang" ? (
                    <Typography varian={"body"} align={'center'}>
                      {props.task.TechnicalDateTime ? props.task.TechnicalDateTime.slice(11, 16) : ""}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        );
      }}
    </Draggable>
  );
}
