import { Autocomplete, Grid, styled, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import GridCenter from "../../components/grid_center"
import StationSelector from "../../components/station_selector"
import DateTimeSelector from "../../components/DateTimeSelector"
import { useUserHistory } from "../../hooks/history"
import { useStations } from "../../hooks/staticData"
import { rcmfSubmissionState } from "../../recoil/atoms"
import { getTitle } from "../../utils/domain"
import StationOverViewBoard from "./components/station_station_overview"

const FilterContainer = styled(Grid)`
  padding: 0 1em;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 1em;
  }
`

export default function StationOverView() {
  const [timeSelectOpen, setTimeSelectOpen] = useState(false)
  const { stations } = useStations()
  const primaryStations = (
    getTitle() === "YardCDM"
      ? [stations["Mgb"], stations["Hpbg"], stations["Gsv"], stations["Hgl"]]
      : [stations["Cst"], stations["M"], stations["G"], stations["Hgl"]]
  ).map(station => ({ ...station, isPrimary: true }))
  const { page, stationId } = useRouteMatch("/:page/:stationId?")?.params || {}
  const setRcmSubmissionForm = useSetRecoilState(rcmfSubmissionState)
  const history = useUserHistory()
  const activeStation = stations[stationId] || primaryStations[0]

  useEffect(() => {
    if (!stationId && Object.keys(stations).length > 0) {
      history.replace(`/${page}/${primaryStations[0].id}`)
    }
  }, [primaryStations, history, page, stationId, stations])

  useEffect(() => {
    setRcmSubmissionForm(prev => ({
      ...prev,
      activeStationObj: activeStation,
    }))
  }, [activeStation, setRcmSubmissionForm])

  const [startTime, setTimeInterval] = useState(undefined)

  return (
    <Grid container>
      <FilterContainer container>
        <GridCenter item xs={12} lg={4}>
          <DateTimeSelector
            startTime={startTime}
            onChange={setTimeInterval}
            open={timeSelectOpen}
            setOpen={setTimeSelectOpen}
          />
        </GridCenter>
        <GridCenter item xs={12} lg={4}>
          <StationSelector
            onSelectStationId={id => id && history.push(`/${page}/${id}`)}
            selectedStationId={stationId}
            stations={primaryStations}
          />
        </GridCenter>
        <GridCenter item xs={12} lg={4}>
          <Autocomplete
            disablePortal
            disableClearable
            fullWidth
            value={activeStation}
            onChange={(event, station) =>
              history.push(`/${page}/${station.id}`)
            }
            options={Object.values(stations)}
            getOptionLabel={option => `${option.name} (${option.id})`}
            renderInput={params => (
              <TextField {...params} label="Stationer" size="small" />
            )}
          />
        </GridCenter>
      </FilterContainer>
      <Grid item xs={12}>
        <StationOverViewBoard
          stationName={activeStation.name}
          stationId={stationId}
          position={[activeStation.lat, activeStation.lon]}
          startTime={startTime}
          disableQueries={timeSelectOpen}
        />
      </Grid>
    </Grid>
  )
}
