import { Collapse } from "@mui/material"
import ListItemButton from "@mui/material/ListItemButton"
import { useState } from "react"
import ExpandIcon from "@mui/icons-material/ExpandMore"
import CollapseIcon from "@mui/icons-material/ExpandLess"
import { useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import { COLOR_ACCENT_SECONDARY, COLOR_PRIMARY } from "../../utils/colors"
import Text from "../Text"

const ShadowContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  right: 5px;
  z-index: 10;

  ${({ $isNewlyUpdated }) =>
    $isNewlyUpdated &&
    `
      animation: pulse 5s ease;
      `}
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 5px 5px ${COLOR_ACCENT_SECONDARY};
    }
    100% {
      box-shadow: none;
    }
  }
`

const ExpandButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 20px;
  bottom: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const ListItemContainer = ({
  isNewlyUpdated,
  Icon,
  itemId,
  title,
  children,
  collapsedChildren,
  expandedText,
  collapsedText,
  latestMessageId,
}) => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ListItemButton
        divider
        onClick={() =>
          history.push(
            `${url}/${itemId}/${latestMessageId ? latestMessageId : ""}`
          )
        }
      >
        <div style={{ zIndex: 11 }}>
          <Row>
            <Icon style={{ color: COLOR_PRIMARY }} fill={COLOR_PRIMARY} />
            <Text bold>{title}</Text>
          </Row>
          {children}
        </div>
        <ShadowContainer $isNewlyUpdated={isNewlyUpdated} />
        {collapsedChildren && (
          <ExpandButton
            onClick={e => {
              e.stopPropagation()
              setIsOpen(!isOpen)
            }}
          >
            {expandedText && collapsedText && (
              <Text small>{isOpen ? expandedText : collapsedText}</Text>
            )}
            {isOpen ? <CollapseIcon /> : <ExpandIcon />}
          </ExpandButton>
        )}
      </ListItemButton>
      {collapsedChildren && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {collapsedChildren}
        </Collapse>
      )}
    </>
  )
}

export default ListItemContainer
