import { useCallback, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { userState } from "../recoil/atoms"

export const useUserHistory = () => {
  const history = useHistory()
  const { username } = useRecoilValue(userState)

  const wrapWithUsernameCheck = useCallback(
    (f, args) => {
      if (username) {
        f(args)
      } else {
        history.replace("/")
      }
    },
    [username, history]
  )

  return useMemo(
    () => ({
      replace: args => wrapWithUsernameCheck(history.replace, args),
      push: args => wrapWithUsernameCheck(history.push, args),
    }),
    [history, wrapWithUsernameCheck]
  )
}
