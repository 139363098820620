import {
  FETCH_REFERENCE_OBJECTS_BEGIN,
  FETCH_REFERENCE_OBJECTS_ERROR,
  FETCH_REFERENCE_OBJECTS_SUCCESS,
  FETCH_REFERENCE_OBJECT_BEGIN,
  FETCH_REFERENCE_OBJECT_SUCCESS,
  MESSAGE_EVENT_RECEIVED,
} from "../actions/types"

const initialState = {
  byId: {},
  totalCount: 0,
  error: null,
  isLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REFERENCE_OBJECTS_BEGIN:
    case FETCH_REFERENCE_OBJECT_BEGIN:
      return { ...state, isLoading: true, error: null }
    case FETCH_REFERENCE_OBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        byId: action.payload.items.reduce(
          (objects, refObject) => ({
            ...objects,
            [refObject.refObjectId]: refObject,
          }),
          state.byId
        ),
        totalCount: action.payload.totalCount,
      }
    case FETCH_REFERENCE_OBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.refObjectId]: action.payload,
        },
      }
    case FETCH_REFERENCE_OBJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case MESSAGE_EVENT_RECEIVED: // Update latest message for relevant reference object
      const refObjectId = action.payload.referenceObject.refObjectId

      // Remove old property first to put it on top
      const { [refObjectId]: oldRefObj, ...otherRefObjects } = state.byId

      return {
        ...state,
        byId: {
          [refObjectId]: action.payload.referenceObject,
          ...otherRefObjects,
        },
      }
    default:
      return state
  }
}

export default reducer
