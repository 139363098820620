import { useEffect } from "react"
import Text from "../../components/text"
import { useBackendWithAuth } from "../../hooks/backend"

export default function Analytics() {
  const [{ data: analytics, error }, fetchAnalytics] = useBackendWithAuth()

  useEffect(() => {
    fetchAnalytics("/analytics")
  }, [fetchAnalytics])

  if (!error && !analytics) {
    return (
      <div>
        <p>Laddar...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <p>Du har inte behörighet att visa den här sidan.</p>
      </div>
    )
  }

  return (
    <div>
      {analytics.map(({ _id, users, total }) => (
        <div>
          <Text bold>{_id.replace(/_/g, " ")}:</Text>
          {Object.keys(users).map(user => (
            <div>
              <Text>
                {user}: {users[user]}
              </Text>
            </div>
          ))}
          <Text bold>Total: {total}</Text>
          <br />
        </div>
      ))}
    </div>
  )
}
