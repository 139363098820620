import { TabContext, TabPanel, TimelineDot } from "@mui/lab"
import { Paper, styled, Tab, Tabs } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { Box, darken, lighten } from "@mui/system"
import { secondsToMinutes } from "date-fns"
import moment from "moment"
import React, { useState } from "react"
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import DataWrapper from "./data_wrapper"
import { useTheme } from "@emotion/react"

const dateFormatter = date => {
  return moment(date).format("HH:mm")
}

const CustomTooltip = ({ active, payload, label }) => {
  return (
    <Paper>
      <p>
        Tåg-ID:{" "}
        {` ${
          payload[0]?.payload.technicalTrainNumber ||
          payload[0]?.payload.technical_train_number
        }`}
        <br />
        Operatör: {` ${payload[0]?.payload.operator}`}
        <br />
        Punktlighet:{" "}
        {` ${(payload[0]?.payload?.time_keeping / 60)?.toFixed(2)} min`}
      </p>
    </Paper>
  )
}

const ChartContainer = styled(ResponsiveContainer)`
  margin: 0 0;
  width: 95%;
`

export default function KpiScatter({
  useKpis,
  locationId,
  startTime,
  endTime,
  filters,
  isFullScreen,
  disableQuery,
}) {
  let timeNOW = new Date()
  timeNOW = timeNOW.getTime()

  const theme = useTheme()

  const getTicks = (startTime, timeEndG) => {
    const tickArr = []
    const stepInterval = 1800 * 1000
    for (let i = startTime; i < timeEndG; i += stepInterval) {
      tickArr.push(i)
    }

    return tickArr
  }

  const { KPIs, isLoading, error } = useKpis(
    locationId,
    startTime,
    endTime,
    filters,
    disableQuery
  )

  const ticks = [-0.5, 0, 0.5, 1].map(i => i * 3600)

  const yAxisProps = {
    type: "number",
    tickFormatter: secondsToMinutes,
    dataKey: "time_keeping",
    unit: " m",
    interval: 0,
    ticks,
    domain: [ticks[0], ticks[ticks.length - 1]],
    allowDataOverflow: true,
  }

  return (
    <DataWrapper isLoading={isLoading} error={error}>
      <ChartContainer aspect={isFullScreen ? 3 : 1.5}>
        <ScatterChart>
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="time_epoch"
            scale="time"
            tickFormatter={dateFormatter}
            domain={[startTime, endTime]}
            ticks={getTicks(startTime - 3600 * 1000, endTime)}
          />
          <YAxis {...yAxisProps} />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              strokeDasharray: "5 5",
              stroke: "#2196f3",
              strokeWidth: 2,
            }}
          />
          <Legend />
          <ReferenceLine x={timeNOW} stroke="green" label="" />
          <Scatter
            name="Faktisk. jämna"
            data={KPIs?.even_actual_time_keeping}
            fill={theme.palette.actual.main}
          />
          <Scatter
            name="Faktisk. udda"
            data={KPIs?.odd_actual_time_keeping}
            fill={lighten(theme.palette.actual.main, 0.5)}
          />
          <Scatter
            name="Beräkn. jämna"
            data={KPIs?.even_estimated_time_keeping}
            fill={theme.palette.warning.main}
          />
          <Scatter
            name="Beräkn. udda"
            data={KPIs?.odd_estimated_time_keeping}
            fill={darken(theme.palette.warning.main, 0.2)}
          />
        </ScatterChart>
      </ChartContainer>
    </DataWrapper>
  )
}
