import qs from "query-string"
import { tryFetch } from "../utils/apiService"
import {
  FETCH_TRAINS_BEGIN,
  FETCH_TRAINS_ERROR,
  FETCH_TRAINS_SUCCESS,
  FETCH_TRAIN_ERROR,
  FETCH_TRAIN_SUCCESS,
} from "./types"

export const fetchTrains = query => accessToken => dispatch => {
  dispatch({ type: FETCH_TRAINS_BEGIN })
  const queryString = qs.stringify({ limit: 40, q: query })
  return tryFetch(`/trains?${queryString}`, accessToken)
    .then(trains => dispatch({ type: FETCH_TRAINS_SUCCESS, payload: trains }))
    .catch(error =>
      dispatch({
        type: FETCH_TRAINS_ERROR,
        payload: { message: error.message },
      })
    )
}

export const fetchTrain =
  ({ id }, accessToken) =>
  dispatch => {
    dispatch({ type: FETCH_TRAINS_BEGIN })
    return tryFetch(`/trains/${id}`, accessToken)
      .then(train => dispatch({ type: FETCH_TRAIN_SUCCESS, payload: train }))
      .catch(error =>
        dispatch({
          type: FETCH_TRAIN_ERROR,
          payload: { message: error.message },
        })
      )
  }
