import styled from "styled-components"
import SearchBar from "../SearchBar"
import Text from "../Text"
import List from "../List"

const Container = styled.div`
  overflow: hidden;
  text-align: center;
`

const ListHeader = styled.div`
  margin: 0 0.5em;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const StyledSearchBar = styled(SearchBar)`
  flex: 1;
  margin-right: 1em;
`

const DetailsBase = ({
  searchQuery,
  onQueryChange,
  totalItemCount,
  items,
  path,
  searchPlaceholder,
  ...listProps
}) => (
  <Container>
    <ListHeader>
      <StyledSearchBar
        placeholder={searchPlaceholder}
        onChange={e => onQueryChange(e.target.value)}
        value={searchQuery}
      />
      <Text small>
        Showing {items.length} of {totalItemCount}
      </Text>
    </ListHeader>
    <List items={items} {...listProps} />
  </Container>
)

export default DetailsBase
