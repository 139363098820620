/*
  Main Root Component
  --------------------
  - Routing table 
  - Wrapping app with:
    - Recoil 
    - Base page (includes Navbar, Footer & Event report button)
*/

// React
import React, { useEffect, useMemo } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import seLocale from "date-fns/locale/sv"
import ROUTES from "./ROUTES.json"
// Recoil
import { useRecoilValue } from "recoil"
import { appDataState } from "./recoil/atoms"
// Styling
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import DateAdapter from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
// Pages
import BasePage from "./base-element/base_page"
import PageHome from "./pages/home"
import PageTrackOverview from "./pages/track_overview"
import PageEventBoard from "./pages/eventboard"
import PageStationOverview from "./pages/station_overview/index.jsx"
import PageMap from "./pages/map"
import PageTrainInfo from "./pages/train_info"

import Depot from "./pages/depot"
import Analytics from "./pages/analytics"
import Users from "./pages/users"
import Sap from "./pages/sap"
import Rfid from "./pages/rfid"
import { getTitle } from "./utils/domain"
import Plank from "./pages/plank"
import { svSE } from "@mui/material/locale"

function App() {
  const appState = useRecoilValue(appDataState)

  // Global styling
  let theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: appState.themeMode, // dark or light (default)

            ...(appState.themeMode === "light"
              ? {
                  primary: {
                    main: "#46535c",
                    light: "#66bb77",
                    contrastText: "#ffffff",
                  },
                  secondary: {
                    main: "#ed6432",
                  },
                  tertiary: {
                    main: "#dfecf0",
                  },
                  grey: {
                    main: "#cbd8e5",
                  },
                  greyLight: {
                    main: "#f4f4f4",
                  },
                  greyDark: {
                    main: "#747474",
                  },
                  background: {
                    default: "#b2c8cb",
                  },
                  error: {
                    main: "#cb2e24",
                  },
                  warning: {
                    main: "#eec937",
                  },
                  success: {
                    main: "#99b93d",
                  },
                  info: {
                    main: "#2196f3",
                  },
                  actual: {
                    main: "#2c850f",
                  },
                  greenBackground: {
                    main: "#EBF5F0",
                  },
                  warningBackground: {
                    main: "#FFEEDF",
                  },
                  errorBackground: {
                    main: "#F9E6EA",
                  },
                }
              : {
                  primary: {
                    main: "#46525c",
                    light: "#66bb77",
                    contrastText: "#ffffff",
                  },
                  secondary: {
                    main: "#ed6432",
                    dark: "#b84d27",
                  },
                  background: {
                    default: "#b2c8cb",
                    light: "#dae6e8",
                  },
                  error: {
                    main: "#cb2e24",
                  },
                  warning: {
                    main: "#eec937",
                  },
                  success: {
                    main: "#99b93d",
                  },
                  info: {
                    main: "#2196f3",
                  },
                  text: {
                    primary: "#fff",
                  },
                  actual: {
                    main: "#2c850f",
                  },
                }),
          },
        },
        svSE
      ),
    [appState.themeMode]
  )

  theme = responsiveFontSizes(theme)

  useEffect(() => {
    document.title = getTitle()
  }, [])

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter} locale={seLocale}>
          <Router>
            <BasePage theme={theme}>
              <Switch>
                {/* Public routes  */}
                <Route exact path={ROUTES.HOME} component={PageHome} />
                {/* Routes with protected APIs */}
                <Route
                  path={ROUTES.STATION_OVERVIEW}
                  component={PageStationOverview}
                />
                <Route path={ROUTES.TRAIN_INFO} component={PageTrainInfo} />
                <Route path={ROUTES.DEPOT_VIEW} component={Depot} />
                <Route path={ROUTES.SAP_VIEW} component={Sap} />
                <Route path={ROUTES.PLANK_VIEW} component={Plank} />
                <Route
                  exact
                  path={ROUTES.TRACK_VIEW}
                  component={PageTrackOverview}
                />
                <Route
                  exact
                  path={ROUTES.EVENT_BOARD}
                  component={PageEventBoard}
                />
                <Route path={ROUTES.RFID} component={Rfid} />
                <Route path={ROUTES.MAP} component={PageMap} />
                <Route exact path={ROUTES.ANALYTICS} component={Analytics} />
                <Route exact path={ROUTES.USERS} component={Users} />
              </Switch>
            </BasePage>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

export default App
