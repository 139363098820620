import qs from "query-string"
import { tryFetch } from "../utils/apiService"
import {
  FETCH_REFERENCE_OBJECTS_BEGIN,
  FETCH_REFERENCE_OBJECTS_ERROR,
  FETCH_REFERENCE_OBJECTS_SUCCESS,
  FETCH_REFERENCE_OBJECT_BEGIN,
  FETCH_REFERENCE_OBJECT_ERROR,
  FETCH_REFERENCE_OBJECT_SUCCESS,
} from "./types"

export const fetchReferenceObjects = query => accessToken => dispatch => {
  dispatch({ type: FETCH_REFERENCE_OBJECTS_BEGIN })
  const queryString = qs.stringify({ limit: 200, q: query })
  return tryFetch(`/referenceObjects?${queryString}`, accessToken)
    .then(refObjects =>
      dispatch({ type: FETCH_REFERENCE_OBJECTS_SUCCESS, payload: refObjects })
    )
    .catch(error =>
      dispatch({
        type: FETCH_REFERENCE_OBJECTS_ERROR,
        payload: { message: error.message },
      })
    )
}

export const fetchReferenceObject =
  ({ id }, accessToken) =>
  dispatch => {
    dispatch({ type: FETCH_REFERENCE_OBJECT_BEGIN })
    return tryFetch(`/referenceObjects/${id}`, accessToken)
      .then(refObject =>
        dispatch({ type: FETCH_REFERENCE_OBJECT_SUCCESS, payload: refObject })
      )
      .catch(error =>
        dispatch({
          type: FETCH_REFERENCE_OBJECT_ERROR,
          payload: { message: error.message },
        })
      )
  }
