import { styled } from "@mui/material"
import React, { useEffect, useRef } from "react"
import PaddedPaper from "../../components/padded_paper"
import Track from "./track"

const Container = styled(PaddedPaper)`
  position: absolute;
  top: 0;
  right: 1em;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.down("md")} {
    position: static;
    margin-bottom: 1em;
    overflow-y: auto;
  }
`

const filterTrack = (trackObjects, track) =>
  trackObjects.filter(
    ({ arrivalEvent, departureEvent }) =>
      (departureEvent || arrivalEvent)?.track === String(track)
  )

export default React.memo(function TrackOverview({
  arrivingTrains = [],
  departingTrains = [],
  timeSequence,
}) {
  const allTrains = [...(arrivingTrains ?? []), ...(departingTrains ?? [])]

  const trackOf = train =>
    ({
      arrivals: train.arrivalEvent?.track,
      departures: train.departureEvent?.track,
    }[timeSequence])
  const tracks = [...new Set(allTrains.map(trackOf))]
    .filter(Boolean)
    .sort((a, b) =>
      b.localeCompare(a, undefined, { numeric: true, sensitivity: "base" })
    )

  return (
    <Container>
      <Track
        name="okänt"
        arrivingTrains={arrivingTrains?.filter(train => !trackOf(train))}
        departingTrains={departingTrains?.filter(train => !trackOf(train))}
        timeSequence={timeSequence}
      />
      {tracks.map(track => (
        <Track
          key={track}
          name={track}
          arrivingTrains={filterTrack(arrivingTrains, track)}
          departingTrains={filterTrack(departingTrains, track)}
          timeSequence={timeSequence}
        />
      ))}
    </Container>
  )
})
