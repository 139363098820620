import { DatePicker } from "@mui/lab"
import { Grid, Paper, Slider, styled, TextField } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import GridCenter from "../../components/grid_center"
import StationSelector from "../../components/station_selector"
import TrainsTable from "../../components/TrainsTable"
import TrainInsight from "../../components/train_insight"
import { useUserHistory } from "../../hooks/history"
import { useStations } from "../../hooks/staticData"
import { useArrivingTrains, useDepartingTrains } from "../../hooks/trains"
import { rcmfSubmissionState } from "../../recoil/atoms"
import {
  formatEpochDateTime,
  formatEpochTimeShort,
} from "../../utils/dataFormatting"
import { getTitle } from "../../utils/domain"
import DepotInfo from "./depot_info"
import TimeSequenceSelector from "./time_sequence_selector"
import TrackOverview from "./track_overview"

const Container = styled(Grid)`
  flex-grow: 1;
  padding: 0 1em 1em 1em;
  align-content: flex-start;
`

const TrainInsightContainer = styled(Paper)`
  min-height: 50vh;
`

const LeftGrid = styled(Grid)`
  position: relative;
  margin-top: 1em;
`

const HorizontalTopGrid = styled(Grid)`
  align-items: center;
`

const StyledTrainsTable = styled(TrainsTable)`
  margin-top: 1em;
`

const TimeSliderContainer = styled("div")`
  padding-right: 1em;
`

const an_hour = 1000 * 3600

export default function Depot() {
  const date = new Date()
  date.setMinutes(date.getMinutes() >= 30 ? 30 : 0)
  const now = date.getTime()

  const { page, stationId, timeSequence, trainId } =
    useRouteMatch("/:page/:stationId?/:timeSequence?/:trainId?")?.params || {}

  const history = useUserHistory()
  const { stations } = useStations()
  const setRcmSubmissionForm = useSetRecoilState(rcmfSubmissionState)

  const primaryStations = (
    getTitle() === "YardCDM"
      ? [stations["Mgb"], stations["Hpbg"], stations["Gsv"], stations["Hgl"]]
      : [stations["Hgl"], stations["Mgb"], stations["Sär"], stations["Uågb"]]
  ).map(station => ({
    ...station,
    isPrimary: true,
  }))

  const [selectedDate, setSelectedDate] = useState(now)
  const [selectedTime, setSelectedTime] = useState(selectedDate)
  const [selectedDisplayedTime, setSelectedDisplayedTime] =
    useState(selectedTime)

  const { trains: allArrivingTrains, isLoading: isLoadingArrivingTrains } =
    useArrivingTrains(stationId, {
      startTime: selectedTime - an_hour * 4,
      endTime: selectedTime + an_hour,
      hideMissing: true,
    })
  const { trains: departingTrains, isLoading: isLoadingDepartingTrains } =
    useDepartingTrains(stationId, {
      startTime: selectedTime,
      endTime: selectedTime + an_hour,
      hideMissing: true,
    })

  const arrivingTrains = allArrivingTrains.filter(
    train => train.departureEvent?.latestTime > selectedTime - an_hour
  )

  useEffect(() => {
    if (page && (!stationId || !timeSequence)) {
      history.replace(`/${page}/${primaryStations[0].id}/departures/`)
    }
  }, [page, stationId, timeSequence, history, primaryStations])

  useEffect(() => {
    setRcmSubmissionForm(prev => ({
      ...prev,
      activeStationObj: stations[stationId],
    }))
  }, [stations, setRcmSubmissionForm, stationId])

  const onSelectTrain = trainId =>
    history.push(`/${page}/${stationId}/${timeSequence}/${trainId}`)

  const minTimeValue = useMemo(
    () => selectedDate - an_hour * 24,
    [selectedDate]
  )
  const maxTimeValue = useMemo(
    () => selectedDate + an_hour * 24,
    [selectedDate]
  )

  const marks = useMemo(
    () =>
      [...new Array(15).fill()].map((_, index) => {
        const value = minTimeValue + (index + 1) * an_hour * 3
        return {
          value,
          label: formatEpochTimeShort(value),
        }
      }),
    [minTimeValue]
  )

  return (
    <Container container rowSpacing={2}>
      <GridCenter item xs={12}>
        <StationSelector
          onSelectStationId={id =>
            history.push(`/${page}/${id}/${timeSequence || "departures"}/`)
          }
          selectedStationId={stationId}
          stations={primaryStations}
        />
      </GridCenter>

      <Grid item xs={12}>
        <HorizontalTopGrid container spacing={2}>
          <Grid item xs={12} sm={6} md={8}>
            <TimeSliderContainer>
              <Slider
                aria-label="Tid"
                defaultValue={selectedDate}
                step={1000 * 60 * 30}
                marks={marks}
                min={minTimeValue}
                max={maxTimeValue}
                valueLabelDisplay="auto"
                valueLabelFormat={formatEpochDateTime}
                onChange={(_, value) => setSelectedDisplayedTime(value)}
                onChangeCommitted={() => setSelectedTime(selectedDisplayedTime)}
                value={selectedDisplayedTime}
              />
            </TimeSliderContainer>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <DatePicker
              mask={undefined}
              renderInput={props => (
                <TextField
                  {...props}
                  fullWidth
                  size="small"
                  label="Välj datum"
                />
              )}
              value={new Date(selectedDate)}
              onChange={date => {
                const epoch = date.getTime()
                const diffDays = Math.floor(
                  (selectedTime - epoch) / (1000 * 3600 * 24)
                )
                const targetTime = selectedTime - diffDays * 1000 * 3600 * 24

                setSelectedDate(epoch)
                setSelectedDisplayedTime(targetTime)
                setSelectedTime(targetTime)
              }}
            />
          </Grid>
          <GridCenter item xs={12} sm={3} md={2}>
            <TimeSequenceSelector
              timeSequence={timeSequence}
              setTimeSequence={timeSequence =>
                history.push(
                  `/${page}/${stationId}/${timeSequence}/${trainId || ""}`
                )
              }
            />
          </GridCenter>
        </HorizontalTopGrid>
      </Grid>
      <LeftGrid item md={8} xs={12}>
        <TrackOverview
          arrivingTrains={arrivingTrains}
          departingTrains={departingTrains}
          timeSequence={timeSequence}
        />
      </LeftGrid>
      {!trainId && (
        <>
          <Grid item md={4} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DepotInfo selectedTime={selectedTime} />
              </Grid>
              <Grid item xs={12}>
                <StyledTrainsTable
                  arrivingTrains={arrivingTrains}
                  isLoading={isLoadingArrivingTrains}
                  onSelectTrain={onSelectTrain}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTrainsTable
                  departingTrains={departingTrains}
                  isLoading={isLoadingDepartingTrains}
                  onSelectTrain={onSelectTrain}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {trainId && (
        <Grid item md={4} xs={12}>
          <TrainInsightContainer>
            <TrainInsight
              trainId={trainId}
              stationId={stationId}
              onDeselectTrain={() =>
                history.push(`/${page}/${stationId}/${timeSequence}/`)
              }
            />
          </TrainInsightContainer>
        </Grid>
      )}
    </Container>
  )
}
