import { Theme, styled, useTheme } from "@mui/material"
import FlexDiv from "./flex_div"
import {
  CheckCircleOutline,
  Autorenew,
  ErrorOutline,
} from "@mui/icons-material"
import Text from "./text"
import { useTranslation } from "react-i18next"
import { useSystemStatus } from "../hooks/systemStatus"

type Props = {}

type Status = "stopped" | "loading" | "ok"

const getStatusColor = (theme: Theme) => ({
  stopped: theme.palette.error.main,
  loading: theme.palette.secondary.main,
  ok: theme.palette.actual.main,
})

const getStatusColorBg = (theme: Theme) => ({
  stopped: theme.palette.errorBackground.main,
  loading: theme.palette.warningBackground.main,
  ok: theme.palette.greenBackground.main,
})

const SystemStatus: React.FC<Props> = props => {
  const { systemStatus } = useSystemStatus()

  const status = systemStatus?.status ?? "loading"
  const { t } = useTranslation()

  const etaMinutes = Math.floor((systemStatus?.etaSeconds ?? 0) / 60)
  const etaHours = Math.floor(etaMinutes / 60)

  return (
    <Container status={status}>
      <IconContainer>
        {status === "ok" && <OkIcon />}
        {status === "loading" && <LoadingIcon />}
        {status === "stopped" && <StoppedIcon />}
      </IconContainer>
      <Text size="small" bold>
        Systemet {t(`systemStatus.${status}`, "-")}
      </Text>
      {status === "loading" && etaMinutes > 3 && (
        <EtaText size="small">
          {etaHours > 0
            ? t("systemStatus.etaHours", { hours: etaHours })
            : t("systemStatus.etaMinutes", { minutes: etaMinutes })}
        </EtaText>
      )}
    </Container>
  )
}

const OkIcon = styled(CheckCircleOutline)`
  color: ${({ theme }) => theme.palette.actual.main};
  font-size: 1rem;
`

const LoadingIcon = styled(Autorenew)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 1rem;
`

const StoppedIcon = styled(ErrorOutline)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 1rem;
`

const IconContainer = styled(FlexDiv)`
  margin-right: 0.2em;
`

const EtaText = styled(Text)`
  margin-left: 0.5em;
`

const Container = styled(FlexDiv)<{ status: Status }>(
  ({ theme, status }) => `
  padding: .1em .5em;
  margin: 0 1em;
  border-radius: 2em;
  border: 1px solid ${getStatusColor(theme)[status]};
  align-items: center;
  background: ${getStatusColorBg(theme)[status]};
  color: #000;
`
)

export default SystemStatus
