import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { ReactComponent as IconTrain } from "../resources/icon_train.svg"
import { ReactComponent as IconWagon } from "../resources/icon_wagon.svg"
import { extractVehicleUnitsWithTimeType } from "../utils/dataFormatting"
import { timeTypeTranslations } from "../utils/rcmf"
import Text from "./text"
import DeleteIcon from "@mui/icons-material/Delete"

export default function TrainCompositionList({
  latestPlannedVehicle,
  latestEstimatedVehicle,
  latestActualVehicle,
  units: propsUnits,
  onDelete,
}) {
  const units =
    propsUnits ||
    extractVehicleUnitsWithTimeType({
      latestPlannedVehicle,
      latestEstimatedVehicle,
      latestActualVehicle,
    })

  if (units.length === 0) {
    return <Text size="small">Inga fordonsindivider registrerade.</Text>
  }

  const iconProps = { width: "40", height: "40" }
  const typeText = unit => `(${timeTypeTranslations[unit.timeType]})`

  return (
    <List dense>
      {units.map((unit, index) => (
        <ListItem
          key={unit.id}
          secondaryAction={
            onDelete && (
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon onClick={() => onDelete(unit)} />
              </IconButton>
            )
          }
        >
          <ListItemIcon>
            {unit.traction ? (
              <IconTrain {...iconProps} />
            ) : (
              <IconWagon {...iconProps} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={unit.id || "Okänt ID"}
            secondary={`${unit.type ? unit.type + " " : ""}${
              unit.timeType ? typeText(unit) : ""
            }`}
          />
          <Divider variant="inset" />
        </ListItem>
      ))}
    </List>
  )
}
