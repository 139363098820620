import React, { memo, useState } from "react"
import { formatEpochDateTime } from "../utils/dataFormatting"
import {
  IconButton,
  Typography,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  Paper,
  useTheme,
  styled,
  Popover,
} from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { timeTypeTranslations, timeTypePaletteColors } from "../utils/rcmf"

const PaperContainer = styled(Paper)`
  padding: 1em;
  background-color: ${({ theme }) => theme.palette.grey[200]};
`

const ToggleButton = styled(IconButton)`
  padding: 0;
`

const TrainTimelineReportedBy = ({ messages = [] }) => {
  const [open, setOpen] = useState(false)
  const anchorEl = React.useRef(null)
  const theme = useTheme()

  const toggleExpandable = e => {
    setOpen(prev => !prev)
  }

  return (
    <>
      <ToggleButton ref={anchorEl} size="small" onClick={toggleExpandable}>
        <Typography variant="body2"> {messages.length}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ToggleButton>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorEl={anchorEl.current}
        open={open}
        onClose={toggleExpandable}
        elevation={3}
      >
        <PaperContainer>
          <TableContainer>
            <Table
              sx={{
                width: "100%",
                minWidth: "200px",
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Aktör</TableCell>
                  <TableCell align="center">Tid</TableCell>
                  <TableCell align="center">Tidstyp</TableCell>
                  <TableCell align="center">Rapporterad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages?.map(message => (
                  <TableRow key={message.messageId} size="small">
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      {message.reportedBy}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{
                        color: !!message.payload.timeType
                          ? theme.palette[
                              timeTypePaletteColors[message.payload.timeType]
                            ].main
                          : "black",
                      }}
                    >
                      {formatEpochDateTime(message.payload.time)}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ color: theme.palette.greyDark.main }}
                    >
                      {!!message.payload.timeType
                        ? timeTypeTranslations[message.payload.timeType]
                        : " "}
                    </TableCell>
                    <TableCell align="center" padding="none">
                      {formatEpochDateTime(message.reportedAt)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PaperContainer>
      </Popover>
    </>
  )
}

export default memo(TrainTimelineReportedBy)
