import { io } from "socket.io-client"

// TODO: Possibly make this configurable
const API_URL = "/api/api-rfid"

export const createSocket = () =>
  io(undefined, { path: `${API_URL}/socket.io` })

export const tryFetch = (path, accessToken, options = {}) => {
  return fetch(`${API_URL}${path}`, {
    ...options,
    headers: {
      ...options?.headers,
      accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(result => {
    if (result.ok) {
      return result.json()
    }

    throw new Error(`Unable to fetch (${result.status} - ${result.statusText})`)
  })
}
