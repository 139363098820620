import {
  AdministrativeState,
  LocationState,
  RailwayCollaborationMessage,
  RcmfTrain,
} from "../../../types/rcmf"
import { Train, TrainEvent } from "../../../types/trainModel"
import { Row, State } from "../types"

export const generateRcm = (
  row: Row,
  {
    to,
    from,
    event: prevEvent,
  }: { to?: string; from?: string; event: TrainEvent | undefined }
): RailwayCollaborationMessage<LocationState> => {
  const track = (row.track || prevEvent?.track) ?? ""
  const time = (row.time || prevEvent?.latestTime) ?? new Date().getTime()
  const trainNumber = (row.trainNumber || prevEvent?.technicalTrainNumber) ?? ""

  const rcmTrain: RcmfTrain = {
    technicalTrainNumber: trainNumber,
    operationalTrainNumber: row.operationalTrainNumber,
    operator: row.operator,
  }

  const payload: LocationState = {
    timeSequence: to && !from ? "arrivalTo" : "departureFrom",
    referenceObject: `rcmf:reference_object:train:${trainNumber}`,
    timeType: row.actual ? "Actual" : row.planned ? "Planned" : "Estimated",
    time: time,
  }

  if (row.comment) {
    payload["comment"] = row.comment
  }

  if (to) {
    payload["to"] = `rcmf:location:station:${to}${track ? ":" : ""}${
      track || ""
    }`
  }

  if (from) {
    payload["from"] = `rcmf:location:station:${from}${track ? ":" + track : ""}`
  }

  return { train: rcmTrain, payload }
}

export const generateDoneAtDepotRcm = (
  trainNumber: string,
  arrival: boolean
): RailwayCollaborationMessage<AdministrativeState> => {
  return {
    payload: {
      time: new Date().getTime(),
      timeSequence: "confirmed",
      serviceObject: `rcmf:service_object:${
        arrival
          ? "train_arrival_to_depot"
          : "train_ready_for_departure_from_depot"
      }`,
    },
    train: {
      technicalTrainNumber: trainNumber,
    },
  }
}

export const getAllTimestamps = (
  state: State,
  trainId: string,
  stationId: string | undefined,
  train: Train | undefined,
  arrival: boolean,
  event: TrainEvent | undefined
) => {
  const { isDone, ...rest } = state[trainId]

  const doneTimeStamp = isDone
    ? [generateDoneAtDepotRcm(train?.technicalTrainNumber ?? "", arrival)]
    : []

  const regularTimeStamp = Object.values(rest).some(val => !!val)
    ? [
        generateRcm(rest, {
          to: arrival ? stationId : undefined,
          from: !arrival ? stationId : undefined,
          event: event,
        }),
      ]
    : []

  return [...doneTimeStamp, ...regularTimeStamp]
}

export const formatHour = (hour: number) => {
  const formatted = `${hour % 24}:00`
  return formatted.length === 4 ? "0" + formatted : formatted
}

export const createMarksForTimespan = (small: boolean) =>
  [...new Array(37).fill(undefined)].map((_, index: number) => ({
    value: index,
    label:
      index % 2 === 0 &&
      (index % 4 === 0 || !small ? formatHour(index) : undefined),
  }))
