import { useTheme } from "@mui/material/styles"
import React from "react"
import Text from "./text"

type Props = {
  error?: Error
  errorText?: string
  children?: JSX.Element
}

export default function Error({
  error,
  errorText,
  children,
}: Props): JSX.Element | null {
  const theme = useTheme()

  if (error) {
    return (
      <div
        style={{
          textAlign: "center",
          color: theme.palette.secondary.main,
        }}
      >
        <h3>Något gick fel...</h3>
        <Text center isError>
          {errorText || error.message}
        </Text>
      </div>
    )
  }

  if (children) {
    return children
  }

  return null
}
