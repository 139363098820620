/*
  Base layout for all pages 

*/

import { styled } from "@mui/material"
import React, { useEffect } from "react"
import { useRecoilValue } from "recoil"
import FlexDiv from "../components/flex_div"
import {
  useRfidReaders,
  useStateCatalogue,
  useStations,
} from "../hooks/staticData"
import { useTrv } from "../hooks/trv"
import { userState } from "../recoil/atoms"
import Footer from "./footer"
import NavigationBar from "./navbar"
import DialogSubmitWindow from "./static-elements/dialogs/dialog_window"
import FloatingButtonsWrapper from "./static-elements/floating_buttons_wrapper"
import SubmitButtonAuto from "./static-elements/submit_button auto"
import { useLocation } from "react-router-dom"
import ROUTES from "./../ROUTES.json"
import { useTranslation } from "react-i18next"

const Container = styled(FlexDiv)`
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.background.default};
`

const ChildContainer = styled("div")`
  flex: 1;
  width: 100%;
  position: relative;
`

export default function BaseTemplate(props) {
  const isLoggedIn = useRecoilValue(userState).username
  useTrv()
  useStations(true)
  useRfidReaders(true)
  useStateCatalogue(true)
  const { i18n } = useTranslation()
  const location = useLocation()

  const isPlankan = location.pathname.startsWith(ROUTES.PLANK_VIEW)

  useEffect(() => {
    window.setLocale = locale => i18n.changeLanguage(locale)
  }, [i18n])

  return (
    <>
      <Container>
        <NavigationBar />

        <ChildContainer>{props.children}</ChildContainer>

        {isLoggedIn && !isPlankan && (
          <FloatingButtonsWrapper>
            <SubmitButtonAuto />
          </FloatingButtonsWrapper>
        )}

        <DialogSubmitWindow />

        <Footer />
      </Container>
    </>
  )
}
