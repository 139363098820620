import { Paper, styled } from "@mui/material"
import FlexDiv from "../../components/flex_div"
import Text from "../../components/text"
import TrainsTable from "../../components/TrainsTable"
import WagonWrapper from "./wagon_wrapper"
import Wagon from "./wagon"
import Vehicle from "./vehicle"
import { useRef } from "react"

const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`

const TrackContainer = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.grey.main};
  padding: 0.3em 0;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
`

const UnoccupiedMarker = styled("div")`
  background-image: url("/leaning_line.svg");
  background-repeat: repeat-x;
  height: 30px;
  margin: 0.3em 0;
  flex-grow: 1;
`

const TrackInfoItem = styled(Text)`
  padding-right: 1em;
`

const TrackLengthContainer = styled(FlexDiv)`
  justify-content: space-between;
`

const TrackInfo = ({ trackIndex, plannedOccupied, actualOccupied, free }) => (
  <FlexDiv>
    <TrackInfoItem bold size="small">
      Spår {trackIndex}
    </TrackInfoItem>
    <TrackInfoItem size="small">Ockuperad: {actualOccupied} m</TrackInfoItem>
    <TrackInfoItem size="small">Planerad: {plannedOccupied} m</TrackInfoItem>
    <TrackInfoItem size="small">Ledig: {free || "-"} m</TrackInfoItem>
  </FlexDiv>
)

const TrackLength = ({ length }) => (
  <TrackLengthContainer>
    <Text size="small">0m</Text>
    <Text size="small">{length || "-"}m</Text>
  </TrackLengthContainer>
)

export default function Track({
  name,
  length: providedLength,
  arrivingTrains = [],
  departingTrains = [],
  timeSequence,
  wagons = [],
}) {
  const trains =
    { arrivals: arrivingTrains ?? [], departures: departingTrains ?? [] }[
      timeSequence
    ] || []

  const trainIds = trains.map(({ trainId }) => trainId)

  const extractVehicleLength =
    (eventField = "departureEvent") =>
    train =>
      (
        train[eventField]?.actualVehicle ||
        train[eventField]?.estimatedVehicle ||
        train[eventField]?.plannedVehicle
      )?.totalLength || 0

  const sum = (prev, curr) => curr + prev

  const actualOccupied = arrivingTrains
    .filter(train => train.arrivalEvent.latestTimeType === "Actual")
    .map(extractVehicleLength("arrivalEvent"))
    .reduce(sum, 0)

  const plannedOccupied = arrivingTrains
    .filter(train => train.arrivalEvent.latestTimeType !== "Actual")
    .map(extractVehicleLength("arrivalEvent"))
    .reduce(sum, 0)

  const trackContainerRef = useRef(null)

  const widthScale =
    (trackContainerRef.current?.offsetWidth || 0) / providedLength

  return (
    <MainContainer>
      <TrackInfo
        trackIndex={name}
        plannedOccupied={plannedOccupied}
        actualOccupied={actualOccupied}
        free={providedLength - actualOccupied /* TODO: Maybe revisit */}
      />
      <TrackContainer ref={trackContainerRef}>
        {trainIds.map(trainId => (
          <Vehicle
            key={trainId}
            trainId={trainId}
            widthScale={widthScale}
            timeSequence={timeSequence}
          />
        ))}
        <UnoccupiedMarker />
      </TrackContainer>
      <TrackLength length={providedLength} />
    </MainContainer>
  )
}
