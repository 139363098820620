import DetailsBase from "./DetailsBase"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchTrains } from "../../actions/trains"
import TrainListItem from "../List/TrainListItem"
import { sortByLatestMessageTime } from "../../utils/dataUtils"
import { useAuthDispatch } from "../../../../hooks/rfidBackend"

const TrainsDetails = ({ searchQuery = "", setSearchQuery }) => {
  const dispatch = useAuthDispatch()

  const visibleReaderIds = useSelector(state => state.readers.visibleIds)

  const trains = useSelector(state => Object.values(state.trains.byId))
    .filter(train => {
      const matchesSearch = train.trainId?.includes?.(searchQuery)
      return (
        (visibleReaderIds.includes(train.latestMessage.payload.at) &&
          matchesSearch) ||
        (searchQuery.length > 0 && matchesSearch)
      )
    })
    .sort(sortByLatestMessageTime)
    .slice(0, 40)

  const { totalCount, error, isLoading } = useSelector(state => state.trains)

  useEffect(() => {
    dispatch(fetchTrains(searchQuery))
  }, [searchQuery, dispatch])
  return (
    <DetailsBase
      items={trains}
      ItemComponent={TrainListItem}
      keyProp={train => train.latestMessage?.messageId}
      searchPlaceholder="Search by train number"
      totalItemCount={totalCount}
      onQueryChange={setSearchQuery}
      searchQuery={searchQuery}
      error={error}
      isLoading={isLoading}
    />
  )
}

export default TrainsDetails
