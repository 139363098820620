import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled"
import styled from "styled-components"
import Text from "../text"
import FlexDiv from "../flex_div"
import { useTheme } from "@mui/material/styles"

const StyledText = styled(Text)`
  margin-left: 0.2em;
`

const IndicatorIcon = styled(AccessTimeFilledIcon)(
  ({ $timeDifference, $cancelled }) => {
    const theme = useTheme()

    let color = theme.palette.actual.main
    if (Math.abs($timeDifference) > 15 || $cancelled) {
      color = theme.palette.error.main
    } else if (Math.abs($timeDifference) > 2) {
      color = theme.palette.warning.main
    }

    return `
    color: ${color}
  `
  }
)

const StatusIndicator = ({ timeDifference, cancelled = false }) => (
  <FlexDiv>
    <IndicatorIcon $timeDifference={timeDifference} $cancelled={cancelled} />
    {cancelled && <StyledText size="small">Inställd</StyledText>}
    {!cancelled && timeDifference && (
      <StyledText noWrap size="small">
        {timeDifference} min
      </StyledText>
    )}
  </FlexDiv>
)

export default StatusIndicator
