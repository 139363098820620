import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { useTrain } from "../../hooks/trains"
import { rcmfSubmissionState } from "../../recoil/atoms"
import ErrorView from "../error"
import GridCenter from "../grid_center"
import KpiRoute from "../kpi_route"
import MapTrainTimeline from "../map_train_timeline"
import StyledAccordion from "../styled_accrdion"
import Text from "../text"
import TrainCompositionList from "../train_composition_list"
import TrainTimeline from "../train_timeline.jsx"
import TrainData from "./train_data"

const CloseButtonWrapper = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const ContainerGrid = styled(Grid)`
  padding: 1rem;
  position: relative;
  text-align: center;
  width: 100%;
`

const ExpandablesContainer = styled(Grid)`
  margin-top: 1em;
`

const ExpandableDetails = ({ title, children }) => (
  <StyledAccordion
    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{title}</Typography>
    </AccordionSummary>

    <AccordionDetails>{children}</AccordionDetails>
  </StyledAccordion>
)

export default function TrainInsight({ trainId, stationId, onDeselectTrain }) {
  const { train, isLoading, error } = useTrain({
    trainId,
    locationId: stationId,
  })

  const setRcmSubmissionForm = useSetRecoilState(rcmfSubmissionState)

  useEffect(() => {
    if (train?.technicalTrainNumber) {
      setRcmSubmissionForm(prev => ({
        ...prev,
        activeTrainNumber: train.technicalTrainNumber,
      }))
      return () =>
        setRcmSubmissionForm(prev => ({
          ...prev,
          activeTrainNumber: null,
        }))
    }
  }, [train?.technicalTrainNumber, setRcmSubmissionForm])

  return (
    <ContainerGrid container>
      {onDeselectTrain && (
        <CloseButtonWrapper onClick={onDeselectTrain}>
          <CloseRoundedIcon fontSize="medium" />
        </CloseButtonWrapper>
      )}

      <GridCenter item xs={12}>
        {train && (
          <Text center bold size="large">
            Information för tåg {train.technicalTrainNumber}
          </Text>
        )}
        {error && <Text center>Något gick fel...</Text>}
      </GridCenter>
      {isLoading && (
        <GridCenter item xs={12}>
          <CircularProgress />
        </GridCenter>
      )}
      {!isLoading && !!error && (
        <GridCenter item xs={12}>
          <ErrorView>{error}</ErrorView>
        </GridCenter>
      )}
      {!isLoading && train && (
        <>
          <Grid item xs={12}>
            <TrainTimeline
              events={train?.events}
              messagesData={train?.messages}
              selectedStation={stationId}
              selectedTrainNumber={train?.technicalTrainNumber}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <TrainData train={train} />
            </Grid>
          </Grid>

          <ExpandablesContainer item xs={12}>
            <ExpandableDetails title="Karta">
              <MapTrainTimeline
                events={train?.events}
                messagesData={train?.messages}
                trainID={train.technicalTrainNumber}
              />
            </ExpandableDetails>
            <ExpandableDetails title="Rutt KPI">
              <KpiRoute
                trainId={train.trainId}
                technicalTrainNumber={train.technicalTrainNumber}
              />
            </ExpandableDetails>
            <ExpandableDetails title="Tågsammansättning">
              <TrainCompositionList {...train} />
            </ExpandableDetails>
          </ExpandablesContainer>
        </>
      )}
    </ContainerGrid>
  )
}
