import { useTheme } from "@mui/material/styles"
import { styled } from "@mui/material"

const sizes = {
  tiny: "0.7rem",
  small: "0.8rem",
  mediumSmall: "0.85rem",
  medium: "0.95rem",
  large: "1.4rem",
}

type Props = {
  size?: keyof typeof sizes
  center?: boolean
  right?: boolean
  isError?: boolean
  hidden?: boolean
  bold?: boolean
  noWrap?: boolean
  inlineBlock?: boolean
  lineThrough?: boolean
  onClick?: () => void
}

// Only let children pass, nothing else
const Text = styled("p", {
  shouldForwardProp: prop => ["children", "onClick"].includes(prop as string),
})(
  ({
    size = "medium",
    center = false,
    right = false,
    isError,
    hidden,
    bold = false,
    noWrap = false,
    inlineBlock = false,
    lineThrough = false,
  }: Props) => {
    const theme = useTheme()
    const notCenterAlign = right ? "right" : "left"
    const display = inlineBlock ? "inline-block" : "block"

    return `
        margin: 0;
        text-align: ${center ? "center" : notCenterAlign};
        font-size: ${sizes[size]};
        color: ${isError ? theme.palette.secondary.main : ""};
        display: ${hidden ? "none" : display};
        font-weight: ${bold ? "500" : "400"};
        ${noWrap ? "white-space: nowrap;" : ""};
        text-decoration: ${lineThrough ? "line-through" : "none"};
    `
  }
)

export default Text
