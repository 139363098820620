/*
    Wagon tracker & Track overview
    ---------------------------------------
    
    
 */

import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import SingleTrack from "./components/single_track"
import ReportButton from "../../base-element/static-elements/report_button"
import TrackLayout from "./components/track_layout_board"
import axios from "axios"

export default function TrackOverview() {
  const [timetableData, setTimetableData] = useState([])

  useEffect(() => {
    // Get timetable data +/- 1H

    // Data filter
    let getArrDataFrom = new Date()
    getArrDataFrom.setHours(getArrDataFrom.getHours() - 1)
    let getArrDataTo = new Date()
    getArrDataTo.setHours(getArrDataTo.getHours() + 1)

    // Query
    let config = { headers: { "Content-Type": "text/xml" } }
    let xmlQuery =
      `<REQUEST>
        <LOGIN authenticationkey="4c19837571f04ce191140305657bcf4d"/>
          <QUERY sseurl="true" objecttype="TrainAnnouncement" orderby="AdvertisedTimeAtLocation" schemaversion="1.6">
            <FILTER>
              <EQ name="LocationSignature" value="G"/> 
              <LTE name="AdvertisedTimeAtLocation" value="` +
      getArrDataTo.toISOString() +
      `" />
              <GTE name="AdvertisedTimeAtLocation" value="` +
      getArrDataFrom.toISOString() +
      `" />
                </FILTER>
              </QUERY> 
        </REQUEST>`

    // Request
    axios
      .post(
        "https://api.trafikinfo.trafikverket.se/v2/data.json",
        xmlQuery,
        config
      )
      .then(function (response) {
        // sseURL = response.data.RESPONSE.RESULT[0].INFO.SSEURL;
        let dataArrList = response.data.RESPONSE.RESULT[0].TrainAnnouncement

        console.log(dataArrList)
        setTimetableData(dataArrList)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <div>
      <ReportButton />

      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <h1>Spår uppföljning</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          <SingleTrack track_number={"Spår 1"} db_documnet={"track1"} />
          <SingleTrack track_number={"Spår 2"} db_documnet={"track2"} />
          <SingleTrack track_number={"Spår 3"} db_documnet={"track3"} />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <h1>Spår översikt +/-1H (Göteborg)</h1>
        </Grid>
        <Grid item xs={12}>
          <TrackLayout timetableData={timetableData} />
        </Grid>
      </Grid>
    </div>
  )
}
