import { tryFetch } from "../utils/apiService"
import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
} from "./types"

export const fetchUsers = () => accessToken => dispatch => {
  dispatch({ type: FETCH_USERS_BEGIN })
  return tryFetch("/users", accessToken)
    .then(users => dispatch({ type: FETCH_USERS_SUCCESS, payload: users }))
    .catch(error =>
      dispatch({ type: FETCH_USERS_ERROR, payload: { message: error.message } })
    )
}
