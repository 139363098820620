import qs from "query-string"
import { tryFetch } from "../utils/apiService"
import {
  CLEAR_VISIBLE_READERS,
  FETCH_READERS_BEGIN,
  FETCH_READERS_ERROR,
  FETCH_READERS_SUCCESS,
  FETCH_READER_BEGIN,
  FETCH_READER_ERROR,
  FETCH_READER_SUCCESS,
  SET_VISIBLE_READERS,
} from "./types"

export const fetchReaders = query => accessToken => dispatch => {
  dispatch({ type: FETCH_READERS_BEGIN })
  const queryString = qs.stringify({ limit: 0, q: query })
  return tryFetch(`/readers?${queryString}`, accessToken)
    .then(readers =>
      dispatch({ type: FETCH_READERS_SUCCESS, payload: readers })
    )
    .catch(error =>
      dispatch({
        type: FETCH_READERS_ERROR,
        payload: { message: error.message },
      })
    )
}

export const fetchReader =
  ({ id }, accessToken) =>
  dispatch => {
    dispatch({ type: FETCH_READER_BEGIN })
    return tryFetch(`/readers/${id}`, accessToken)
      .then(reader => dispatch({ type: FETCH_READER_SUCCESS, payload: reader }))
      .catch(error => dispatch({ type: FETCH_READER_ERROR }))
  }

export const setVisibleReaders = readerIds => dispatch =>
  dispatch({ type: SET_VISIBLE_READERS, payload: readerIds })

export const clearVisibleReaders = () => dispatch =>
  dispatch({ type: CLEAR_VISIBLE_READERS })
