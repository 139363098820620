import { styled } from "@mui/material"
import { differenceInMinutes } from "date-fns"
import FlexDiv from "../flex_div"
import StatusIndicator from "./status_indicator"
import TrainWarning from "./train_warning"

const getTimeDifferenceMinutes = (dt1, dt2) => {
  const date1 = new Date(dt1)
  const date2 = new Date(dt2)

  if (date1 && date2) {
    return differenceInMinutes(date1, date2)
  }
  return null
}

const mockIndicators = [
  {
    title: "Kolliderande data",
    severity: 0,
  },
  {
    title: "Orimliga förhållanden",
    severity: 1,
  },
  {
    title: "Saknad data",
    severity: 0,
  },
  {
    title: "Kolliderande infrastruktur",
    severity: 1,
  },
]

export default function TrainStatus({
  arrivalEvent,
  departureEvent,
  cancelled,
}) {
  const { plannedTime, estimatedTime } = arrivalEvent || departureEvent
  const timeDifference =
    !cancelled &&
    plannedTime &&
    estimatedTime &&
    getTimeDifferenceMinutes(estimatedTime, plannedTime)

  return (
    <FlexDiv>
      {Math.random() > 0.8 &&
        false && ( // TODO: enable in future
          <TrainWarning
            indicator={
              mockIndicators[Math.floor(Math.random() * mockIndicators.length)]
            }
          />
        )}
      <StatusIndicator cancelled={cancelled} timeDifference={timeDifference} />
    </FlexDiv>
  )
}
