import { Tooltip } from "@mui/material"
import React from "react"
import { formatEpochDateTime } from "../utils/dataFormatting"
import Text from "./text"

export default function MessageReportedBy({
  reportedAt,
  reportedBy,
  children,
}) {
  if (!reportedAt && !reportedBy) return <div>{children}</div>

  return (
    <Tooltip
      title={
        <MessageReportedByTooltipTitle
          reportedAt={reportedAt}
          reportedBy={reportedBy}
        />
      }
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export const MessageReportedByTooltipTitle = ({ reportedBy, reportedAt }) => {
  return (
    <>
      <div>
        <Text bold size="small">
          {reportedBy}
        </Text>
      </div>
      <div>
        <Text size="small">{formatEpochDateTime(new Date(reportedAt))}</Text>
      </div>
    </>
  )
}
