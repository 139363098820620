import { COLOR_ACCENT_SECONDARY, COLOR_SECONDARY } from "../../utils/colors"

const { default: Error } = require("../../../../components/error")
const { default: styled } = require("styled-components")

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ fixedHeight }) => (fixedHeight ? "100px" : "100%")};
`

const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -38px 0 0 -38px;
  border: 8px solid ${COLOR_SECONDARY};
  border-radius: 50%;
  border-top: 8px solid ${COLOR_ACCENT_SECONDARY};
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const DataWrapper = ({ error, fixedHeight = false, isLoading, children }) => (
  <Error error={error}>
    {isLoading && !error ? (
      <LoaderContainer fixedHeight={fixedHeight}>
        <Loader />
      </LoaderContainer>
    ) : (
      children
    )}
  </Error>
)

export default DataWrapper
