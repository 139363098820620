import styled from "styled-components"
import { COLOR_ACCENT_SECONDARY } from "../../utils/colors"

const VerticalLine = styled.div`
width: 2px;
position: absolute;
top: 0;
bottom: 0;
background-color: ${COLOR_ACCENT_SECONDARY}});
`

export default VerticalLine
