import DetailsBase from "./DetailsBase"
import { useSelector } from "react-redux"
import ReaderListItem from "../List/ReaderListItem"
import { sortByLatestMessageTime } from "../../utils/dataUtils"

const ReadersDetails = ({ searchQuery = "", setSearchQuery }) => {
  const visibleReaderIds = useSelector(state => state.readers.visibleIds)

  const readers = useSelector(state => Object.values(state.readers.byId))
    .filter(reader => {
      const matchesSearch =
        reader.Name &&
        reader.Name.toLowerCase().includes(searchQuery.toLowerCase())
      return (
        (visibleReaderIds.includes(reader.SGLN) && matchesSearch) ||
        (searchQuery.length > 0 && matchesSearch)
      )
    })
    .sort(sortByLatestMessageTime)
    .slice(0, 40)

  const { totalCount, error, isLoading } = useSelector(state => state.readers)

  return (
    <DetailsBase
      items={readers}
      ItemComponent={ReaderListItem}
      keyProp={reader => reader.latestMessage?.messageId}
      searchPlaceholder="Search by reader name"
      totalItemCount={totalCount}
      onQueryChange={setSearchQuery}
      searchQuery={searchQuery}
      error={error}
      isLoading={isLoading}
    />
  )
}

export default ReadersDetails
