/*
    Event report button 
    ----------------------------
     
*/

import React, { useState } from "react";
import { Fab } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import DialogSimple from "./dialogs/report_event.jsx";

export default function ReportButton() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = (value) => {
    setDialogOpen(false);
  };

  return (
    <>
      <DialogSimple
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        open={dialogOpen}
      />
      <Fab
        sx={{
          backgroundColor: "secondary.main",
          color: "#fff",
        }}
        onClick={handleClickOpen}
      >
        <ReportIcon fontSize="large" />
      </Fab>
    </>
  );
}
