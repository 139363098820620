import React from "react"
import MaterialTable from "@material-table/core"

export default function FinTimeTable({ timeTableList }) {
  return (
    <MaterialTable
      title={"Tidtabell"}
      columns={columns}
      data={timeTableList}
    ></MaterialTable>
  )
}

const columns = [
  {
    title: "type",
    field: "type",
  },
  {
    title: "scheduledTime",
    field: "scheduledTime",
  },
  {
    title: "ActualTime",
    field: "actualTime",
  },
  {
    title: "commercialTrack",
    field: "commercialTrack",
  },
  {
    title: "differenceInMinutes",
    field: "differenceInMinutes",
  },
]
