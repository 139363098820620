/*
    Public start page
*/

import React from "react"
import { Grid } from "@mui/material"
import LogoBox from "../../base-element/static-elements/logo"
import LoginForm from "./components/login_form"
import GridCenter from "../../components/grid_center"

export default function Home() {
  return (
    <Grid container>
      <GridCenter item xs={12}>
        <LogoBox />
      </GridCenter>
      <GridCenter item xs={12}>
        <LoginForm />
      </GridCenter>
    </Grid>
  )
}
