import React from "react"
import {
  Switch,
  Stack,
  FormGroup,
  FormControlLabel,
  Typography,
  styled,
} from "@mui/material"

const Container = styled(FormGroup)`
  margin: 0 1em;
  display: flex;
  flex-direction: row;
`

const filters = [
  { id: "hideCancelled", name: "Inställda" },
  { id: "hideDeviating", name: "Tidiga/sena" },
  { id: "hidePlanned", name: "Rättidiga" },
  { id: "hideMissing", name: "Saknas" },
  { id: "hideEven", name: "Jämna" },
  { id: "hideOdd", name: "Udda" },
  { id: "hideCompositionless", name: "Saknar sammansättning" },
]

export default function FilterSwitch({
  trainFilters = {},
  onTrainFiltersChange,
}) {
  const handleFilterChange = e =>
    onTrainFiltersChange({ ...trainFilters, [e.target.id]: !e.target.checked })

  return (
    <Container>
      {filters.map(({ id, name }) => (
        <FormControlLabel
          key={id}
          control={
            <Switch
              id={id}
              checked={!trainFilters[id]}
              onChange={handleFilterChange}
              color="secondary"
              size="small"
            />
          }
          label={<Typography variant="caption">{name}</Typography>}
          labelPlacement="end"
        />
      ))}
    </Container>
  )
}
