import ReaderIcon from "../icons/ReaderIcon"
import Marker from "react-leaflet-enhanced-marker"
import IconWrapper from "./IconWrapper"
import ItemsLayer from "./ItemsLayer"

const ReadersLayer = ({ items, onClick, selectedItem }) => (
  <ItemsLayer
    renderClusterIcon={cluster => (
      <IconWrapper
        glow={cluster
          .getAllChildMarkers()
          .some(marker => marker.options.data.isSelected)}
        isNewlyUpdated={cluster
          .getAllChildMarkers()
          .some(marker => marker.options?.data?.isNewlyUpdated)}
        Icon={ReaderIcon}
        count={cluster.getChildCount()}
      />
    )}
  >
    {Object.values(items)
      .filter(reader => reader.Latitude && reader.Longitude) // There may be cases where a message has created a reader but without coords
      .map((reader, index) => {
        const isSelected = reader.SGLN === selectedItem?.SGLN
        const isNewlyUpdated =
          reader.latestMessage &&
          new Date().getTime() - reader.latestMessage.reportedAt < 10000
        return (
          <Marker
            key={index}
            position={[reader.Latitude, reader.Longitude]}
            icon={
              <IconWrapper
                isNewlyUpdated={isNewlyUpdated}
                Icon={ReaderIcon}
                glow={isSelected}
              />
            }
            eventHandlers={
              onClick && {
                click: () => onClick(reader),
              }
            }
            zIndex={10}
            data={{ isSelected, isNewlyUpdated }}
          />
        )
      })}
  </ItemsLayer>
)

export default ReadersLayer
