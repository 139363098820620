import { Paper, styled, useTheme } from "@mui/material"
import React from "react"
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useRouteKpi } from "../hooks/kpis"
import { useStations } from "../hooks/staticData"
import DataWrapper from "./data_wrapper"
import Text from "./text"

const Container = styled("div")`
  width: 100%;
  height: 400px;
`

const TooltipContainer = styled(Paper)`
  padding: 0.5em;
`

export default function KpiRoute({ trainId, technicalTrainNumber }) {
  const { KPIs = {}, isLoading } = useRouteKpi(trainId)
  const { stations } = useStations()
  const theme = useTheme()

  const selectedTrain = KPIs[trainId]?.departures || {}
  const data = Object.keys(selectedTrain).map(location => ({
    location,
    selected: selectedTrain[location] / 60000,
    average: KPIs.average_departures?.[location] / 60000,
  }))

  const CustomTooltip = ({ payload = [] } = {}) => {
    const average = payload?.[0]?.payload?.average?.toFixed(2)
    return (
      <TooltipContainer>
        <Text size="small">
          Station: {stations[payload?.[0]?.payload?.location]?.name}
        </Text>
        <Text size="small">
          Tåg-ID {technicalTrainNumber}:{" "}
          {`${payload?.[0]?.payload?.selected?.toFixed(2)} min`}
        </Text>
        {average && <Text size="small">Genomsnitt: {`${average} min`}</Text>}
      </TooltipContainer>
    )
  }

  return (
    <DataWrapper isLoading={isLoading}>
      <Container>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={[...data].reverse()}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="location" type="category" scale="auto" />
            <YAxis
              domain={[
                minValue => Math.floor(minValue - 5),
                maxValue => Math.ceil(maxValue + 5),
              ]}
              scale="linear"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Line
              name={`Tidshålling för tåg ${technicalTrainNumber} (min)`}
              type="monotone"
              dataKey="selected"
              stroke="#2196f3"
              activeDot={{ r: 8 }}
            />

            <Line
              name={`Genomsnittlig tidshålling samma rutt (min)`}
              type="monotone"
              dataKey="average"
              stroke={theme.palette.secondary.main}
              activeDot={{ r: 8 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Container>
    </DataWrapper>
  )
}
