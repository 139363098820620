import { useMemo } from "react"
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import Text from "../../../components/text"
import {
  AddRow,
  OnAddSubmitFnType,
  OnSelectTrainFnType,
  OnStateChangeFnType,
  PlankRow,
} from "."
import StyledTableCell from "./TableCell"
import { Train } from "../../../types/trainModel"
import { useTranslation } from "react-i18next"
import { useColumns } from "../utils/columns"
import { ColumnFilters, usePlankColumnsFilter } from "./PlankColumns"

const Title = styled(Text)`
  padding: 0.25em 0;
`

const HeaderCell = styled(StyledTableCell)`
  position: sticky;
  top: 36px;
  background-color: white;
  z-index: 45;
  border-right: 1px solid #e0e0e0;
`

const MainHeaderCell = styled(HeaderCell)`
  top: 0px;
`

type Props = {
  arrivals?: Train[]
  departures?: Train[]
  station: any
  date?: any
  onStateChange: OnStateChangeFnType
  onAddSubmit: OnAddSubmitFnType
  onSelectTrain: OnSelectTrainFnType
  disabledRows: string[]
  hasError?: boolean
  selectedTrainId?: string | undefined
  loading?: boolean
  hiddenPlannedTrackReporters?: RegExp
  columnFilters: ColumnFilters
}

export default function PlankPage({
  arrivals = undefined,
  departures = undefined,
  station,
  date = new Date(),
  onStateChange,
  onSelectTrain,
  disabledRows = [],
  hasError,
  selectedTrainId,
  onAddSubmit,
  loading,
  hiddenPlannedTrackReporters,
  columnFilters,
}: Props) {
  const timeSequence = arrivals ? "arrivals" : "departures"
  const { t } = useTranslation()
  const timeSequenceTranslation = {
    arrivals: t("generalTrain.arrivals"),
    departures: t("generalTrain.departures"),
  }[timeSequence]

  const { shownColumnIndices } = columnFilters

  const trains = { arrivals, departures }[timeSequence]

  const day = format(date, "EEEE, d MMM", { locale: sv })

  const columnNames = useColumns(timeSequence).filter((c, index) =>
    shownColumnIndices.includes(index)
  )

  const rows = useMemo(
    () =>
      trains?.map(train => (
        <PlankRow
          key={train.trainId}
          train={train}
          timeSequence={timeSequence}
          onStateChange={onStateChange}
          onSelectTrain={onSelectTrain}
          selected={selectedTrainId === train?.trainId}
          disabled={disabledRows?.includes(train.trainId)}
          hasError={hasError}
          hiddenPlannedTrackReporters={hiddenPlannedTrackReporters}
          columnFilters={columnFilters}
        />
      )),
    [
      trains,
      onStateChange,
      timeSequence,
      onSelectTrain,
      selectedTrainId,
      disabledRows,
      hasError,
      hiddenPlannedTrackReporters,
      columnFilters,
    ]
  )

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "calc(100vh - 20rem)", overflowY: "auto" }}
    >
      <Table size="small" sx={{ borderCollapse: "separate" }}>
        <TableHead sx={{ backgroundColor: "white" }}>
          <TableRow>
            {!loading && (
              <MainHeaderCell colSpan={columnNames.length}>
                <Title size="large" bold center>
                  {`${timeSequenceTranslation} ${t("plank.trainAt")} ${
                    station?.name
                  } ${day.charAt(0).toUpperCase() + day.substring(1)}`}
                </Title>
              </MainHeaderCell>
            )}
          </TableRow>
          {!loading && (
            <TableRow>
              {columnNames.map(name => (
                <HeaderCell key={name}>
                  <Text center bold size="small">
                    {name}
                  </Text>
                </HeaderCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>{rows}</TableBody>
        <TableFooter>
          <AddRow
            timeSequence={timeSequence}
            stationId={station?.id}
            disabledRows={disabledRows}
            onSubmit={onAddSubmit}
            selectedDate={date}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
