/*
  Arrival table editable 
    
*/

// ICons
import { styled, useTheme } from "@mui/material"
import React, { memo } from "react"
import SubmitMessageButton from "../submit_msg_button"
import SwedishMaterialTable from "../SweMaterialTable"
import { useStateCatalogue, useStations } from "../../hooks/staticData"
import FlexDiv from "../flex_div"
import InfoTooltip from "../info_tooltip"
import Text from "../text"
import Time from "../time"
import Pagination from "./pagination"
import Toolbar from "./toolbar"
import TrainStatus from "./train_status"

const HeaderTitle = ({ children, tooltip }) =>
  tooltip ? (
    <FlexDiv>
      <Text bold noWrap size="mediumSmall">
        {children}
      </Text>
      <InfoTooltip text={tooltip} />
    </FlexDiv>
  ) : (
    <Text noWrap size="mediumSmall" bold>
      {children}
    </Text>
  )

const TrainNumberContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const defaultCellStyling = {
  padding: "0 0.5em",
}

export default React.memo(function TrainsTable({
  stationId,
  arrivingTrains,
  departingTrains,
  onSelectTrain,
  selectedTrain,
  pageSize = 4,
  showDetailedInfo = false,
  isLoading,
  trainFilters,
  onTrainFiltersChange,
  onRequestItems,
  dataOffset,
}) {
  const theme = useTheme()
  const timeSequence = arrivingTrains ? "arrival" : "departure"
  const { states: stateCatalogue } = useStateCatalogue()
  const { stations } = useStations()
  const targetStation = stations[stationId]
  const targetState = stateCatalogue.find(
    state =>
      state.id ===
      (timeSequence === "arrival"
        ? "train_arrival_to_station"
        : "train_departure_from_station")
  )

  const columns = [
    {
      cellStyle: {
        padding: "0.2em 0 0.2em 1em",
      },
      render: train => (
        <SubmitMessageButton
          train={train}
          eventType={timeSequence}
          state={targetState}
          station={targetStation}
        />
      ),
    },
    {
      title: <HeaderTitle>Tåg-ID</HeaderTitle>,
      field: "technicalTrainNumber",
      sorting: false,
      cellStyle: {
        padding: 0,
      },
      render: ({ technicalTrainNumber, connectedTrainNumbers = [] }) => (
        <TrainNumberContainer>
          <Text size="small">{technicalTrainNumber}</Text>
          {connectedTrainNumbers?.length > 0 && (
            <Text size="tiny">
              ({connectedTrainNumbers[0].split(".")[1]}
              {connectedTrainNumbers.length > 1 && ",..."})
            </Text>
          )}
        </TrainNumberContainer>
      ),
    },
    showDetailedInfo && {
      title: (
        <HeaderTitle tooltip="Annonserat tågnummer">Annon. tågnr</HeaderTitle>
      ),
      field: "advertisedTrainNumber",
      render: ({ advertisedTrainNumber }) => (
        <Text size="small">{advertisedTrainNumber}</Text>
      ),
    },
    {
      title: <HeaderTitle>Operatör</HeaderTitle>,
      field: "operator",
      cellStyle: defaultCellStyling,
      render: ({ operator }) => <Text size="small">{operator || "Okänd"}</Text>,
    },
    arrivingTrains && {
      title: <HeaderTitle>Ankomst</HeaderTitle>,
      field: "plannedArrivalTime",
      cellStyle: {
        padding: "0 0.5em",
      },
      render: ({
        arrivalEvent: { plannedTime: pta, estimatedTime: eta, actualTime: ata },
      }) => <Time noWrap planned={pta} estimated={eta} actual={ata} />,
    },
    departingTrains && {
      title: <HeaderTitle>Avgång</HeaderTitle>,
      field: "plannedDepartureTime",
      cellStyle: defaultCellStyling,
      render: ({
        departureEvent: {
          plannedTime: ptd,
          estimatedTime: etd,
          actualTime: atd,
        },
      }) => (
        <Time size="small" noWrap planned={ptd} estimated={etd} actual={atd} />
      ),
    },
    {
      title: <HeaderTitle>Spår</HeaderTitle>,
      field: "track",
      cellStyle: defaultCellStyling,
      render: ({ arrivalEvent, departureEvent }) => {
        const track = arrivalEvent?.track || departureEvent?.track
        return track && <Text size="small">{track}</Text>
      },
    },
    {
      title: <HeaderTitle>Status</HeaderTitle>,
      field: "status",
      cellStyle: defaultCellStyling,
      render: ({ arrivalEvent, departureEvent, cancelled }) => (
        <TrainStatus
          cancelled={cancelled}
          arrivalEvent={arrivingTrains && arrivalEvent}
          departureEvent={departingTrains && departureEvent}
        />
      ),
    },
  ].filter(column => !!column)

  // Probably needed because material-table mutates its data...
  // Also add index to train
  const data = (arrivingTrains || departingTrains || []).map((t, index) => ({
    ...t,
    index,
  }))

  return (
    <SwedishMaterialTable
      title={timeSequence === "arrival" ? "Ankomster" : "Avgångar"}
      columns={columns}
      data={data}
      isLoading={isLoading}
      onSearchChange={trainNumber =>
        onTrainFiltersChange({ ...trainFilters, trainNumber })
      }
      components={{
        Toolbar: Toolbar,
        Pagination: memo(props => (
          <Pagination
            {...{ ...props, pageSize, dataOffset, onRequestItems, isLoading }}
          />
        )),
        // This is a hacky way of passing props to keep state in custom components
        toolbarProps: { trainFilters, onTrainFiltersChange },
      }}
      options={{
        sorting: false, // TODO: Allow in future?
        filtering: false, // TODO: Allow in future?
        padding: "dense",
        pageSize: pageSize,
        pageSizeOptions: [],
        paginationType: "stepped", // normal , stepped
        showFirstLastPageButtons: false,
        showTitle: true,
        draggable: false,
        selection: false,
        debounceInterval: 0,
        loadingType: "overlay",
        headerStyle: { paddingRight: 0 },
        rowStyle: rowData => {
          const currentTime = new Date().getTime()
          const eventKey =
            timeSequence === "arrival" ? "arrivalEvent" : "departureEvent"
          const prevTrain = data[rowData.index - 1]
          const prevTrainTime = prevTrain?.[eventKey]?.latestTime
          const currentTrainTime = rowData[eventKey]?.latestTime
          const showRedLineTop =
            (!prevTrainTime || prevTrainTime < currentTime) &&
            currentTrainTime &&
            currentTrainTime > currentTime

          return {
            backgroundColor:
              rowData.trainId === selectedTrain
                ? theme.palette.tertiary.main
                : "transparent",
            borderTop: showRedLineTop
              ? `2px solid ${theme.palette.secondary.main}`
              : undefined,
          }
        },
      }}
      onRowClick={(evt, selectedTrain, togglePanel) => {
        if (onSelectTrain) onSelectTrain(selectedTrain.trainId)
      }}
    />
  )
})
