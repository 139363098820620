import { memo, useEffect, useMemo, useRef, useState } from "react"
import { TimePicker } from "@mui/lab"
import {
  Button,
  Checkbox,
  CircularProgress,
  darken,
  InputBase,
  lighten,
  styled,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material"
import ReportSharpIcon from "@mui/icons-material/ReportSharp"
import { differenceInMinutes } from "date-fns"
import MessageReportedBy from "../../../components/message_reported_by"
import Text from "../../../components/text"
import Time from "../../../components/time"
import TableCell from "./TableCell"
import {
  EstimatesType,
  MessageValue,
  PlannedType,
  useEstimatedMessages,
  useLatestNoneEstimateMessages,
} from "../../../hooks/trains"
import FlexDiv from "../../../components/flex_div"
import { Row } from "../types"
import { Train } from "../../../types/trainModel"
import { TimeSequence, markedAsDone, markedAsDoneBy } from "../utils"
import { ColumnFilters, usePlankColumnsFilter } from "./PlankColumns"
import React from "react"

export type OnStateChangeFnType = (trainId: string, state: any) => void
export type OnAddSubmitFnType = (args: {
  sentAt: number
  trainId: string
}) => void
export type OnSelectTrainFnType = (trainId: string) => void

const StyledTextField = styled(InputBase)`
  .MuiInputBase-input {
    padding: 0;
  }
`

export const PaddedTableCell = styled(TableCell)`
  padding: 0 0.5em;
`

export const CenterContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EstimatesContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em;
`

const TimePickerWrapper = styled("div")`
  max-width: 90px;
`

const ColoredCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.actual.main};
  }
  padding: 0;
  margin: 0;
`

const NewTrainButton = styled(Button, {
  shouldForwardProp: prop => prop !== "reverse",
})<{ reverse?: boolean }>`
  ${({ reverse }) =>
    reverse
      ? `
  margin-right: -20px;
  padding-right: -20px;
  `
      : `
  margin-left: -20px;
  padding-left: -20px;
  `}

  margin-top: 2px;
  opacity: 0.6;
  align-items: flex-start;
`

const TrainNumberButton = styled(Button)`
  padding: 0;
  justify-content: start;
`

const TrainNumberContainer = styled("div", {
  shouldForwardProp: prop => prop !== "reverse",
})<{ reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`
const CommentText = styled(Text, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>`
  ${({ expanded }) =>
    !expanded
      ? `
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-clamp: 2;
          -webkit-line-clamp: 2;
        `
      : ``}
  cursor: pointer;
  margin: 4px 2px;
`

// Am proud
export const DisableableTableRow = styled(TableRow)<{
  forcedBackground?: boolean
}>`
  background-color: ${({ forcedBackground, selected, theme }) =>
    forcedBackground
      ? theme.palette.greyLight.main
      : selected
      ? theme.palette.tertiary.main
      : "transparent"};

  transition: 0.5s ease;
`

export const RestrainedTableCell = styled(TableCell, {
  shouldForwardProp: (field: string) =>
    !["highlight", "maxWidth", "error"].includes(field),
})<{ maxWidth?: string; highlight?: boolean; error?: boolean }>(
  ({ maxWidth, highlight, error, theme }) => ({
    width: maxWidth,
    border: error
      ? `2px solid ${darken(theme.palette.error.main, 0)}`
      : highlight
      ? `2px solid ${darken(theme.palette.warning.main, 0)}`
      : undefined,
    backgroundColor: error
      ? lighten(theme.palette.error.main, 0.8)
      : highlight
      ? lighten(theme.palette.warning.main, 0.8)
      : undefined,
    color: highlight || error ? theme.palette.text.secondary : undefined,
    padding: `0 0.5em`,
  })
)

const CHECK_MISSED_TIME_TIMER_MS = 1000 * 30

type Props = {
  train: Train
  timeSequence: TimeSequence
  onStateChange: OnStateChangeFnType
  onAddSubmit?: OnAddSubmitFnType
  onSelectTrain: OnSelectTrainFnType
  disabled?: boolean
  hasError?: boolean
  selected?: boolean
  hiddenPlannedTrackReporters?: RegExp
  columnFilters: ColumnFilters
}

function PlankRow({
  train,
  timeSequence,
  onStateChange,
  onSelectTrain,
  disabled = false,
  hasError,
  selected,
  hiddenPlannedTrackReporters,
  columnFilters,
}: Props) {
  const [commentExpanded, setCommentExpanded] = useState(false)
  const event = useMemo(
    () =>
      ({
        arrivals: train.arrivalEvent,
        departures: train.departureEvent,
      }[timeSequence]),
    [train, timeSequence]
  )

  if (!event) return null

  const { shownColumnIndices } = columnFilters

  const showFaecalWarning =
    train.events?.some(event => event.state === "faecal_emptying_cancelled") &&
    timeSequence === "arrivals"

  const isMarkedAsDone = markedAsDone(train, timeSequence)

  const newTrainEvent = train?.events?.find(
    event => event.state === "train_becomes_completed"
  )
  const newTrainNumber = newTrainEvent?.linkedTrainNumber ?? null
  const newTrainId = newTrainEvent?.linkedTrainId ?? null

  const [state, setState] = useState<Row>({})
  const [missedTime, setMissedTime] = useState(
    event?.latestTimeType !== "Actual" &&
      new Date().getTime() > (event.latestTime ?? 0)
  )
  const missedTimeTimer = useRef<NodeJS.Timer | null>(null)
  const disabledRef = useRef(disabled)

  useEffect(() => {
    // Was previously disabled, now is not
    if (!disabled && disabledRef.current && !hasError) {
      setState({})
    }

    disabledRef.current = disabled
  }, [disabled, hasError])

  useEffect(() => {
    if (missedTimeTimer.current) {
      clearInterval(missedTimeTimer.current)
      missedTimeTimer.current = null
    }
    if (event?.latestTimeType !== "Actual") {
      missedTimeTimer.current = setInterval(() => {
        setMissedTime(new Date().getTime() > event.latestTime)
      }, CHECK_MISSED_TIME_TIMER_MS)

      return () => {
        clearInterval(missedTimeTimer.current ?? undefined)
        missedTimeTimer.current = null
      }
    }
  }, [event])

  useEffect(() => {
    onStateChange(train.trainId, state)
  }, [state, onStateChange, train.trainId])

  const isActual = event?.latestTimeType === "Actual"

  const planned = useLatestNoneEstimateMessages(train, timeSequence)
  const estimates = useEstimatedMessages(train, timeSequence, planned)

  const setEstimateState = (
    field: keyof Row,
    value: string | number | boolean | undefined | null
  ) => {
    setState(({ [field]: inputField, ...rest }) =>
      value
        ? {
            [field]: value,
            ...rest,
          }
        : rest
    )
  }

  const latestTime = new Date(event.latestTime)

  const timeDiff = differenceInMinutes(latestTime, new Date())
  const withinTimeDiff = timeDiff <= 60 && timeDiff >= 0

  const withinBounds = !isActual && withinTimeDiff

  const highlightTrack =
    withinBounds && shouldHighlightField(estimates, planned, "track")
  const highlightOperator =
    withinBounds && shouldHighlightField(estimates, planned, "operator")
  const highlightTime =
    withinBounds && shouldHighlightField(estimates, planned, "time")

  const hidePlannedTrack =
    !planned?.track?.reportedBy ||
    hiddenPlannedTrackReporters?.test(planned.track.reportedBy)

  const plannedColumns = [
    <RestrainedTableCell maxWidth="82px">
      {disabled ? (
        <CenterContainer>
          <CircularProgress size={20} />
        </CenterContainer>
      ) : (
        <FlexDiv>
          <TrainNumberContainer reverse={timeSequence !== "arrivals"}>
            <MessageReportedBy
              reportedAt={planned.train?.reportedAt}
              reportedBy={planned.train?.reportedBy}
            >
              <TrainNumberButton onClick={() => onSelectTrain(train.trainId)}>
                <Text bold>{planned.train?.value ?? "-"}</Text>
              </TrainNumberButton>
            </MessageReportedBy>
            {newTrainNumber && (
              <NewTrainButton
                reverse={timeSequence !== "arrivals"}
                onClick={
                  !!newTrainId ? () => onSelectTrain(newTrainId) : undefined
                }
              >
                <Text size="small">{newTrainNumber}</Text>
              </NewTrainButton>
            )}
          </TrainNumberContainer>
          {showFaecalWarning && (
            <Tooltip title="Ska ej fekalietömmas">
              <span>
                <ReportSharpIcon color="warning" />
              </span>
            </Tooltip>
          )}
        </FlexDiv>
      )}
    </RestrainedTableCell>,
    <RestrainedTableCell>
      <MessageReportedBy
        reportedAt={planned.operationalTrainNumber.reportedAt}
        reportedBy={planned.operationalTrainNumber.reportedBy}
      >
        <Text bold>{planned.operationalTrainNumber?.value ?? "-"}</Text>
      </MessageReportedBy>
    </RestrainedTableCell>,
    <RestrainedTableCell maxWidth="4rem">
      <MessageReportedBy
        reportedAt={planned.operator?.reportedAt}
        reportedBy={planned.operator?.reportedBy}
      >
        <Text lineThrough={!isActual && !!estimates?.operator?.length}>
          {planned.operator?.value ?? "-"}
        </Text>
      </MessageReportedBy>
    </RestrainedTableCell>,
    <RestrainedTableCell maxWidth="4rem" error={missedTime && !isActual}>
      <Time
        planned={planned.time.value ?? undefined}
        actual={event.actualTime}
        lineThrough={!isActual && !!estimates?.time?.length}
        reportedAt={planned.time?.reportedAt}
        reportedBy={planned.time?.reportedBy}
      />
    </RestrainedTableCell>,
    <RestrainedTableCell maxWidth="4rem">
      <MessageReportedBy
        reportedAt={planned.track?.reportedAt}
        reportedBy={planned.track?.reportedBy}
      >
        <Text
          bold
          lineThrough={
            !planned.track.actualWithTrack && !!estimates?.track?.length
          }
        >
          {(!hidePlannedTrack && planned.track?.value) || "-"}
        </Text>
      </MessageReportedBy>
    </RestrainedTableCell>,
    <RestrainedTableCell>
      <CommentText
        expanded={commentExpanded}
        onClick={() => setCommentExpanded(prev => !prev)}
        lineThrough={!isActual && !!estimates?.comment?.length}
      >
        {planned.comment.value ?? "-"}
      </CommentText>
    </RestrainedTableCell>,
  ]

  const inputColumns = [
    <RestrainedTableCell highlight={highlightOperator} maxWidth="4rem">
      <Estimates
        currentState={state.operator}
        estimates={estimates.operator ?? []}
      />
      <StyledTextField
        size="small"
        margin="none"
        onChange={(e: any) => setEstimateState("operator", e.target?.value)}
        value={state.operator ?? ""}
        disabled={disabled}
      />
    </RestrainedTableCell>,
    <RestrainedTableCell
      highlight={highlightTime}
      error={missedTime && !isActual}
      maxWidth="8rem"
    >
      <TimePickerWrapper>
        <Estimates currentState={state.time} estimates={estimates.time ?? []} />
        <TimePicker
          onChange={date => {
            const eventDate = new Date(event.latestTime)
            date?.setDate(eventDate?.getDate())
            setEstimateState("time", date?.getTime?.())
          }}
          disabled={disabled}
          InputProps={{ disableUnderline: true }}
          value={state.time ? new Date(state.time) : null}
          renderInput={props => <TextField variant="standard" {...props} />}
        />
      </TimePickerWrapper>
    </RestrainedTableCell>,
    <RestrainedTableCell
      highlight={highlightTrack && !isMarkedAsDone}
      maxWidth="4rem"
    >
      <Estimates estimates={estimates.track ?? []} currentState={state.track} />
      <StyledTextField
        size="small"
        margin="none"
        onChange={(e: any) => setEstimateState("track", e.target?.value)}
        value={state.track ?? ""}
        disabled={disabled}
      />
    </RestrainedTableCell>,
    <TableCell>
      <Estimates
        currentState={state.comment}
        estimates={estimates.comment ?? []}
      />
      <StyledTextField
        size="small"
        margin="none"
        disabled={disabled}
        onChange={(e: any) => setEstimateState("comment", e.target?.value)}
        value={state.comment ?? ""}
      />
    </TableCell>,
    <TableCell>
      <MessageReportedBy
        reportedAt={markedAsDoneBy(train, timeSequence).reportedAt}
        reportedBy={markedAsDoneBy(train, timeSequence).reportedBy}
      >
        <ColoredCheckbox
          checked={isMarkedAsDone || !!state.isDone}
          onChange={(e: any) => setEstimateState("isDone", e.target?.checked)}
          disabled={isMarkedAsDone}
        />
      </MessageReportedBy>
    </TableCell>,
  ]

  return (
    <DisableableTableRow
      forcedBackground={disabled || isMarkedAsDone}
      selected={selected}
    >
      {plannedColumns
        .map((cell, index) => (
          <React.Fragment key={"cell_" + index}>{cell}</React.Fragment>
        ))
        .filter((_, index) => shownColumnIndices.includes(index))}
      {inputColumns
        .map((cell, index) => (
          <React.Fragment key={"cell_" + index}>{cell}</React.Fragment>
        ))
        .filter((_, index) =>
          shownColumnIndices.includes(index + plannedColumns.length)
        )}
    </DisableableTableRow>
  )
}

export default memo(PlankRow)

interface EstimatesProps {
  currentState: string | boolean | number | null | undefined
  estimates: MessageValue<any>[]
}
const Estimates = ({ currentState, estimates }: EstimatesProps) => {
  return (
    <EstimatesContainer>
      {estimates &&
        estimates.map((est, estimatedIndex) => (
          <MessageReportedBy
            key={`${currentState}_${estimatedIndex}`}
            reportedAt={est.reportedAt}
            reportedBy={est.reportedBy}
          >
            <Text
              size="small"
              bold
              lineThrough={
                // isActual ||
                estimatedIndex < estimates?.length - 1
              }
            >
              {est.value}
            </Text>
          </MessageReportedBy>
        ))}
    </EstimatesContainer>
  )
}

function shouldHighlightField(
  estimates: EstimatesType,
  planned: PlannedType,
  field: keyof EstimatesType
) {
  const estimateField = estimates?.[field]?.slice(-1)?.[0]?.value

  const plannedField = planned?.[field]?.value

  return !!estimateField && plannedField !== estimateField
}
