import { atom } from "recoil"
import { Train } from "../types/trainModel"

const defaultProps = { error: null, isLoading: false }

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet(({ remember, ...newValue }: any, _: any, isReset: any) => {
      if (isReset) {
        localStorage.removeItem(key)
      } else {
        if (remember === true || remember === undefined) {
          localStorage.setItem(key, JSON.stringify(newValue))
        }
      }
    })
  }

export const appDataState = atom({
  key: "app_data_state",
  default: {
    demoMode: false,
    themeMode: "light",
  },
})

export const userState = atom({
  key: "user",
  default: {
    accessToken: "",
    username: "",
    role: "",
    userGroup: "Test account",
    defaultStation: "Cst",
    remember: false,
    ...defaultProps,
  },
  effects_UNSTABLE: [localStorageEffect("current_user")],
})

export const formDialogState = atom({
  key: "form_dialog_is_open",
  default: {
    isOpen: false,
    formType: "base",
    isSending: false,
    success: false,
    ...defaultProps,
  },
})

export const rcmfSubmissionState = atom({
  key: "form_rcmf",
  default: {
    activeState: null,
    activeTimeType: null,
    activeStationObj: null,
    activeOperator: null,
    activeTrainNumber: null,
    activeTrainWagonID: null,
    activeTrainTrack: null,
  },
})

export const arrivingTrainsState = atom({
  key: "arriving_trains",
  default: { trains: [] as Train[], ...defaultProps },
})

export const departingTrainsState = atom({
  key: "departing_trains",
  default: { trains: [] as Train[], ...defaultProps },
})

export const allTrainsState = atom({
  key: "allTrainsState",
  default: { trains: [] as Train[], ...defaultProps },
})

export const wagonsState = atom({
  key: "wagons",
  default: { wagons: [], ...defaultProps },
})

export const kpiSummaryState = atom({
  key: "kpiSummary",
  default: { KPIs: {}, ...defaultProps },
})

export const kpiArrivalsState = atom({
  key: "kpiArrivals",
  default: { KPIs: {}, ...defaultProps },
})

export const kpiDeparturesState = atom({
  key: "kpiDepartures",
  default: { KPIs: {}, ...defaultProps },
})

export const kpiRouteState = atom({
  key: "kpiRoute",
  default: { KPIs: {}, ...defaultProps },
})

export const trainLocationsState = atom({
  key: "trainLocations",
  default: [],
})

export const trainNetworkStatisticsState = atom({
  key: "rain_network_statistics",
  default: [],
})

export const stationsState = atom({
  key: "stations",
  default: {
    stations: {},
    ...defaultProps,
  },
  effects_UNSTABLE: [localStorageEffect("stations")],
})

export const rfidReadersState = atom({
  key: "rfid_readers",
  default: {
    readers: {},
    ...defaultProps,
  },
  effects_UNSTABLE: [localStorageEffect("rfid_readers")],
})

export const stateCatalogueState = atom({
  key: "state_catalogue",
  default: {
    states: [],
    ...defaultProps,
  },
  effects_UNSTABLE: [localStorageEffect("state_catalogue")],
})

export const trvTrainMessageState = atom({
  key: "trv_train_message_state",
  default: {
    events: [],
    ...defaultProps,
  },
})

export const digitrafficTrainPosState = atom({
  key: "digitraffic_train_position_state",
  default: {
    events: [],
    ...defaultProps,
  },
})
