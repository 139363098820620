import { styled } from "@mui/material"
import FlexDiv from "../../components/flex_div"
import PaddedPaper from "../../components/padded_paper"
import Text from "../../components/text"
import { formatEpochDateTime } from "../../utils/dataFormatting"

const Row = styled(FlexDiv)`
  justify-content: space-between;
`

export default function DepotInfo({ selectedTime }) {
  return (
    <PaddedPaper>
      <Row>
        <Text bold>Spårinfo</Text>
        <Text>{formatEpochDateTime(selectedTime)}</Text>
      </Row>
      <Row>
        <Text>Ledig kapacitet: </Text>
        <Text>- m</Text>
      </Row>
      <Row>
        <Text>Planerad kapacitet för uppställning: </Text>
        <Text>- m</Text>
      </Row>
      <Row>
        <Text>Ockuperad kapacitet ankommande: </Text>
        <Text>- m</Text>
      </Row>
      <Row>
        <Text>Ockuperad kapacitet avgående: </Text>
        <Text>- m</Text>
      </Row>
      <Row>
        <Text>Total kapacitet: </Text>
        <Text>- m</Text>
      </Row>
      <Row>
        <Text>Fyllnadsgrad: </Text>
        <Text>0%</Text>
      </Row>
    </PaddedPaper>
  )
}
