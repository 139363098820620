import { DateTimePicker } from "@mui/lab"
import { Autocomplete, Button, Grid, TextField } from "@mui/material"
import React from "react"
import { useStations } from "../../../../hooks/staticData"
import FormRcmfTrain from "./form_rcmf_train"

export default function FormRcmfPayload({
  formik,
  timeTypes,
  state,
  operators,
}) {
  const stations = Object.values(useStations()?.stations)
  const isTrainBecomes = state.id.includes("train_becomes")

  return (
    <>
      <Grid item xs={12}>
        {state.stateType !== "AdministrativeState" && (
          <Autocomplete
            disablePortal
            value={formik.values.timeType}
            onChange={(e, value) => formik.setFieldValue("timeType", value)}
            getOptionLabel={option => `${option.name}`}
            options={timeTypes}
            renderInput={params => (
              <TextField
                error={formik.touched.timeType && formik.errors.timeType}
                helperText={formik.touched.timeType && formik.errors.timeType}
                {...params}
                label="Tidstyp"
              />
            )}
            onBlur={formik.onBlur}
          />
        )}
      </Grid>
      {(state.locationType === "station" || state.locationType === "depot") && (
        <>
          <Grid item xs={8}>
            <Autocomplete
              value={formik.values.location}
              onChange={(e, value) => formik.setFieldValue("location", value)}
              options={stations}
              getOptionLabel={option => `${option.name} (${option.id})`}
              renderInput={params => (
                <TextField {...params} label="Station/Depå" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="track"
              name="track"
              label="Spår"
              fullWidth
              value={formik.values.track}
              onChange={formik.handleChange}
            />
          </Grid>
        </>
      )}
      {(state.referenceObjectType === "train" ||
        state.referenceObjectType === "wagon") && (
        <>
          <Grid
            item
            xs={12}
            md={state.referenceObjectType === "wagon" ? 6 : 12}
          >
            <Autocomplete
              disablePortal
              id="operator"
              name="operator"
              value={formik.values.operator}
              onChange={(e, value) => formik.setFieldValue("operator", value)}
              options={operators}
              renderInput={params => <TextField {...params} label="Operatör" />}
            />
          </Grid>
          {state.referenceObjectType === "wagon" && (
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                id="technicalWagonId"
                name="technicalWagonId"
                label="Vagn-ID"
                fullWidth
                value={formik.values.technicalWagonId}
                onChange={formik.handleChange}
              />
            </Grid>
          )}
        </>
      )}

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          id="trainNumber"
          name="trainNumber"
          label={`Tåg-ID ${isTrainBecomes ? "från" : ""}`}
          autoCapitalize="none"
          value={formik.values.trainNumber}
          onChange={formik.handleChange}
          error={formik.touched.trainNumber && formik.errors.trainNumber}
          helperText={formik.touched.trainNumber && formik.errors.trainNumber}
          onBlur={formik.handleBlur}
        />
      </Grid>
      {isTrainBecomes && (
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="trainNumberTo"
            name="trainNumberTo"
            label="Tåg-ID till"
            autoCapitalize="none"
            value={formik.values.trainNumberTo}
            onChange={formik.handleChange}
            error={formik.touched.trainNumberTo && formik.errors.trainNumberTo}
            helperText={
              formik.touched.trainNumberTo && formik.errors.trainNumberTo
            }
            onBlur={formik.handleBlur}
          />
        </Grid>
      )}
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          id="operationalTrainNumber"
          name="operationalTrainNumber"
          label={`OTN`}
          autoCapitalize="none"
          value={formik.values.operationalTrainNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.operationalTrainNumber &&
            formik.errors.operationalTrainNumber
          }
          helperText={
            formik.touched.operationalTrainNumber &&
            formik.errors.operationalTrainNumber
          }
          onBlur={formik.handleBlur}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimePicker
          id="timeStamp"
          name="timeStamp"
          fullWidth
          renderInput={props => <TextField {...props} sx={{ width: "100%" }} />}
          label="Tidsstämpel"
          value={formik.values.timeStamp}
          onChange={value => {
            formik.setFieldValue("timeStamp", value)
          }}
          disableMaskedInput
        />
      </Grid>
      <Grid item xs={12}>
        {state.referenceObjectType === "train" && (
          <FormRcmfTrain formik={formik} />
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          id="comment"
          name="comment"
          variant="outlined"
          fullWidth
          label="Kommentar"
          rows={2}
          value={formik.values.comment}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={Object.keys(formik.errors).length > 0}
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
        >
          Uppdatera
        </Button>
      </Grid>
    </>
  )
}
