import {
  styled,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  padding: 1em;
`

export default function StationSelector({
  onSelectStationId,
  selectedStationId,
  stations = [],
}) {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <StyledToggleButtonGroup
      value={selectedStationId}
      color="secondary"
      orientation={small ? "vertical" : "horizontal"}
      exclusive
      onChange={(e, stationId) => onSelectStationId(stationId)}
      size="small"
    >
      {stations
        .filter(({ isPrimary, id }) => isPrimary && id)
        .map((station, index) => (
          <ToggleButton value={station.id} key={index + "tog"}>
            {station.name}
          </ToggleButton>
        ))}
    </StyledToggleButtonGroup>
  )
}
