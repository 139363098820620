import { setVisibleReaders } from "../../actions/readers"
import { useEffect, useState } from "react"
import { LayersControl, MapContainer, Popup, TileLayer } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import itemRoutes from "../../routes.json"
import styled from "styled-components"
import { BP_M } from "../../utils/breakpoints"
import locations from "../../utils/locations"
import BaseLayers from "./BaseLayers"
import TrainIcon from "../icons/TrainIcon"
import ReadersLayer from "./ReadersLayer"
import RefObjectLayer from "./RefObjectLayer"
import RefObjectsLayer from "./RefObjectsLayer"
import DataWrapper from "../../../../components/data_wrapper"
import ROUTES from "../../../../ROUTES.json"

const Container = styled.div`
  flex: 2;

  @media screen and (max-width: ${BP_M}) {
    flex: 1;
  }
`

const center = locations.munster
const defaultZoom = 5
const selectedZoom = 8

const Map = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [map, setMap] = useState(null)
  const { itemType, itemId, messageId } =
    useRouteMatch("/:page/:itemType/:itemId?/:messageId?")?.params || {}

  const trains = useSelector(state => state.trains.byId)
  const readers = useSelector(state => state.readers.byId)
  const refObjects = useSelector(state => state.referenceObjects.byId)

  const selectedItem =
    itemId &&
    {
      [itemRoutes.TRAINS]: trains,
      [itemRoutes.REF_OBJECTS]: refObjects,
      [itemRoutes.READERS]: readers,
    }[itemType][itemId]

  const messages = useSelector(state => {
    if (selectedItem && itemType === itemRoutes.TRAINS) {
      return state.messages.byTrainId[itemId]
    }

    return state.messages.byRefObjectId[itemId]
  })

  const isLoading = useSelector(state => state.readers.isLoading)
  const isLoadingMessages = useSelector(state => state.messages.isLoading)
  const error = useSelector(
    state => state.messages.error || state.users.error || state.readers.error
  )

  useEffect(() => {
    if (!selectedItem && map) {
      //map.setZoom(defaultZoom)
    } else if (selectedItem && map) {
      if (itemType === itemRoutes.READERS) {
        map.flyTo(
          [selectedItem.Latitude, selectedItem.Longitude],
          Math.max(selectedZoom, map.getZoom())
        )
        return
      }

      if (
        itemType === itemRoutes.TRAINS ||
        itemType === itemRoutes.REF_OBJECTS
      ) {
        if (messages?.length > 0) {
          // Create an array of coords to use as bounds for flying
          const coords = messages
            .map(message => readers[message.payload.at])
            .filter(reader => !!reader)
            .map(reader => [reader.Latitude, reader.Longitude])

          map.flyToBounds(coords, { maxZoom: selectedZoom })
        }
      }
    }
  }, [selectedItem, itemType, map, readers, messages])

  useEffect(() => {
    if (map) {
      map.off("moveend")
      map.on("moveend", e => {
        const visibleReaderIds = Object.values(readers)
          .filter(
            ({ Longitude, Latitude }) =>
              Latitude &&
              Longitude &&
              map
                .getBounds()
                .contains([parseFloat(Latitude), parseFloat(Longitude)])
          )
          .map(reader => reader.SGLN)

        dispatch(setVisibleReaders(visibleReaderIds))
      })
    }
  }, [map, dispatch, readers])

  useEffect(() => () => map && map.remove(), [map])

  const allObjectsLayers = {
    [itemRoutes.TRAINS]: (
      <RefObjectsLayer
        items={trains}
        Icon={TrainIcon}
        readers={readers}
        onClick={item =>
          history.push(
            `${ROUTES.RFID}/${itemType}/${item.trainId}/${item.latestMessage.messageId}`
          )
        }
      />
    ),
    [itemRoutes.REF_OBJECTS]: (
      <RefObjectsLayer
        items={refObjects}
        readers={readers}
        onClick={item =>
          history.push(
            `${ROUTES.RFID}/${itemType}/${item.refObjectId}/${item.latestMessage.messageId}`
          )
        }
      />
    ),
    [itemRoutes.READERS]: (
      <ReadersLayer
        items={readers}
        onClick={item =>
          history.push(`${ROUTES.RFID}/${itemType}/${item.SGLN}`)
        }
      />
    ),
  }

  const goToReader = reader =>
    history.push(`${ROUTES.RFID}/${itemRoutes.READERS}/${reader.SGLN}`)

  const selectedObjectLayers = {
    [itemRoutes.TRAINS]: (
      <RefObjectLayer
        messages={messages}
        readers={readers}
        selectedMessageId={messageId}
        onClick={message =>
          history.push(
            `${ROUTES.RFID}/${itemType}/${itemId}/${message.messageId}`
          )
        }
      />
    ),
    [itemRoutes.REF_OBJECTS]: (
      <RefObjectLayer
        messages={messages}
        readers={readers}
        selectedMessageId={messageId}
        onClick={message =>
          history.push(
            `${ROUTES.RFID}/${itemType}/${itemId}/${message.messageId}`
          )
        }
      />
    ),
    [itemRoutes.READERS]: (
      <ReadersLayer
        items={readers}
        selectedItem={selectedItem}
        onClick={goToReader}
      />
    ),
  }

  return (
    <Container>
      <DataWrapper
        error={error}
        isLoading={
          isLoading ||
          Object.keys(readers).length === 0 ||
          Object.keys(refObjects) === 0
        }
      >
        <MapContainer
          style={{ width: "100%", height: "100%" }}
          center={center}
          zoom={6}
          scrollWheelZoom
          whenCreated={setMap}
        >
          <LayersControl position="topright">
            <BaseLayers />
          </LayersControl>
          {selectedItem && !isLoadingMessages && selectedObjectLayers[itemType]}
          {!selectedItem && allObjectsLayers[itemType]}
          <TileLayer
            attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy; <a href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            url="https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"
            zIndex={10}
          />
        </MapContainer>
      </DataWrapper>
    </Container>
  )
}

export default Map
