import {
  CLEAR_VISIBLE_READERS,
  FETCH_READERS_BEGIN,
  FETCH_READERS_ERROR,
  FETCH_READERS_SUCCESS,
  FETCH_READER_BEGIN,
  FETCH_READER_ERROR,
  FETCH_READER_SUCCESS,
  MESSAGE_EVENT_RECEIVED,
  SET_VISIBLE_READERS,
} from "../actions/types"

const initialState = {
  byId: {},
  totalCount: 0,
  error: null,
  isLoading: false,
  visibleIds: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_READERS_BEGIN:
    case FETCH_READER_BEGIN:
      return { ...state, isLoading: true, error: null }
    case FETCH_READERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        byId: action.payload.items.reduce(
          (objects, reader) => ({ ...objects, [reader.SGLN]: reader }),
          state.byId
        ),
        totalCount: action.payload.totalCount,
      }
    case FETCH_READER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.SGLN]: action.payload,
        },
      }
    case FETCH_READERS_ERROR:
    case FETCH_READER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case MESSAGE_EVENT_RECEIVED: // Update latest message for relevant reference object
      const readerId = action.payload.reader.SGLN

      // Remove old property first to put it on top
      const { [readerId]: oldReader, ...otherReaders } = state.byId

      return {
        ...state,
        byId: {
          [readerId]: action.payload.reader,
          ...otherReaders,
        },
      }
    case SET_VISIBLE_READERS:
      return { ...state, visibleIds: action.payload }
    case CLEAR_VISIBLE_READERS:
      return { ...state, visibleIds: [] }
    default:
      return state
  }
}

export default reducer
