/*
  Map overview the SWE railway
  -----------------------------

*/

// Components
import { Grid, styled } from "@mui/material"
import React from "react"
// Recoil
import { atom, useRecoilValue } from "recoil"
import Map from "../../components/map"
import MapToggleLayers from "../../components/mapToggleLayers"
import TotalDelays from "../../components/total_delays"
import { useRfidReaders } from "../../hooks/staticData"
import { trvTrainMessageState } from "../../recoil/atoms"
// Atoms
export const objectSelectedOnMap = atom({
  key: "objectSelectedOnMapKey",
  default: {},
})

export const trainNetworkStats = atom({
  key: "trainNetworkStatsKey",
  default: {
    totDelay: 0,
  },
})

const MapContainer = styled("div")`
  position: relative;
  flex: 1;
  display: flex;
`

const HeaderContainer = styled("div")`
  padding: 1em;
`

const Container = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const AbsoluteTotalDelays = styled(TotalDelays)`
  position: absolute;
  top: 1em;
  left: 1em;
`

export default function MapIndex() {
  const trainMessage = useRecoilValue(trvTrainMessageState)
  const rfidReaders = Object.values(useRfidReaders().readers)

  return (
    <Container>
      <HeaderContainer>
        <MapToggleLayers />
      </HeaderContainer>
      <MapContainer item xs={12}>
        <Map trainMessage={trainMessage.events} rfidReaders={rfidReaders} />
        <AbsoluteTotalDelays />
      </MapContainer>
    </Container>
  )
}
