/*
    Event tracker board (Trello) 

*/

import React from "react"
import { Grid } from "@mui/material"
import EventBoard from "./components/event_board"
// Icons
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate"

export default function EventBoardIndex() {
  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sx={{ margin: "1rem" }}>
          <h1
            style={{
              textAlign: "center",
              marginTop: "0rem",
              marginBottom: "0rem",
            }}
          >
            Händelser & Åtgärdsplan
          </h1>
          <p style={{ display: "flex", placeItems: "center", margin: "0rem" }}>
            <b style={{ marginRight: "0.5rem" }}> Prioritet:</b>
            Låg{" "}
            <AssignmentLateIcon
              sx={{ color: "#174f7245", marginRight: "0.5rem" }}
            />
            Medel{" "}
            <AssignmentLateIcon
              sx={{ color: "#d2911945", marginRight: "0.5rem" }}
            />
            Hög{" "}
            <AssignmentLateIcon
              sx={{ color: "#d2191945", marginRight: "0.5rem" }}
            />
          </p>
        </Grid>

        <Grid item xs={12}>
          <EventBoard />
        </Grid>
      </Grid>
    </>
  )
}
