import { styled } from "@mui/system"
import { useState } from "react"
import FlexDiv from "../../components/flex_div"
import Wagon from "./wagon"

const MainContainer = styled("div")`
  padding: 0.2em;
  //border: 2px ${({ $actual }) => ($actual ? "solid" : "dashed")} white;
  border: 3px dashed ${({ actual }) => (actual ? "transparent" : "white")};
  margin: 0 0.5em;
  cursor: pointer;
`

export default function WagonWrapper({ train, wagons = [], wagon }) {
  const [showInfo, setShowInfo] = useState(false)

  return (
    <MainContainer
      onClick={() => setShowInfo(!showInfo)}
      actual={
        train?.arrivalEvent?.latestTimeType === "Actual" ||
        wagon?.arrivalEvent?.latestTimeType === "Actual"
      }
    >
      <FlexDiv>
        {wagons?.map((w, index) => {
          // TODO: Revisit, use specific data for this wagon
          /*const wagon = {
            id: wagonId,
            arrivalTrainNumber: train.technicalTrainNumber,
            plannedArrivalTime: train.arrivalEvent?.plannedTime,
            estimatedArrivalTime: train.arrivalEvent?.estimatedTime,
            actualArrivalTime: train.arrivalEvent?.actualTime,
            plannedDepartureTime: train.departureEvent?.plannedTime,
            estimatedDepartureTime: train.departureEvent?.estimatedTime,
          }*/
          return (
            <Wagon
              key={w.wagonId}
              wagon={w}
              showInfo={showInfo}
              showLabels={index === 0}
              train={train}
            />
          )
        })}
        {wagon && <Wagon wagon={wagon} showLabels showInfo={showInfo} />}
      </FlexDiv>
    </MainContainer>
  )
}
