/*
    Event table editable 
    
*/

import React, { useState, useMemo } from "react"
import MaterialTable from "@material-table/core"
import { useRecoilValue } from "recoil"
import { trvTrainMessageState } from "../../../recoil/atoms"
import TrafficImpact from "../../../base-element/static-elements/traffic_impact"
import Text from "../../../components/text"
import { formatEpochDateTime } from "../../../utils/dataFormatting"
import styled from "styled-components"
import FilterSelector from "./filter-selector"
import { Paper } from "@mui/material"

import PicRailwayTrackGreen from "../../../components/map/svg/RailwayTrackGreen.png"
import PicRailwayTrackOrange from "../../../components/map/svg/RailwayTrackOrange.png"
import PicRailwayTrackRed from "../../../components/map/svg/RailwayTrackRed.png"
import PicRailwayAnimalGreen from "../../../components/map/svg/RailwayAnimalOnTrackGreen.png"
import PicRailwayAnimalOrange from "../../../components/map/svg/RailwayAnimalOnTrackOrange.png"
import PicRailwayAnimalRed from "../../../components/map/svg/RailwayAnimalOnTrackRed.png"
import PicRailwayNoTrainRed from "../../../components/map/svg/RailwayNoTrainRed.png"
import PicRailwayNoTrainOrange from "../../../components/map/svg/RailwayNoTrainOrange.png"
import PicRailwayNoTrainGreen from "../../../components/map/svg/RailwayNoTrainGreen.png"
import PicRailwayOtherRed from "../../../components/map/svg/RailwayOtherRed.png"
import PicRailwayOtherOrange from "../../../components/map/svg/RailwayOtherOrange.png"
import PicRailwayOtherGreen from "../../../components/map/svg/RailwayOtherGreen.png"

const EventIcon = styled("img")`
  width: 1.5rem;
`

export default function EventTableComp({ stationId }) {
  const { events } = useRecoilValue(trvTrainMessageState)
  const trvTrainMsgEvents =
    useMemo(() => events.map(e => ({ ...e })), [events]) || []

  const [showAllStationsAffected, setShowAllStationsAffected] = useState(false)

  const filterStationEvents = event =>
    event?.TrafficImpact?.some(route =>
      route?.AffectedLocation?.some(
        location => location?.LocationSignature === stationId
      )
    )

  const initColumnType = [
    {
      title: "",
      field: "ReasonCode",
      width: "10%",
      render: row => {
        if (row.ReasonCode[0].Description === "banarbete") {
          if (row.TrafficImpact.length < 2) {
            return <EventIcon src={PicRailwayTrackGreen} />
          } else if (row.TrafficImpact.length < 6) {
            return <EventIcon src={PicRailwayTrackOrange} />
          } else {
            return <EventIcon src={PicRailwayTrackRed} />
          }
        } else if (row.ReasonCode[0].Description === "djur i spåret") {
          if (row.TrafficImpact.length < 2) {
            return <EventIcon src={PicRailwayAnimalGreen} />
          } else if (row.TrafficImpact.length < 6) {
            return <EventIcon src={PicRailwayAnimalOrange} />
          } else {
            return <EventIcon src={PicRailwayAnimalRed} />
          }
        } else if (
          row.ReasonCode[0].Description === "sen avgång från bangården"
        ) {
          if (row.TrafficImpact.length < 2) {
            return <EventIcon src={PicRailwayNoTrainGreen} />
          } else if (row.TrafficImpact.length < 6) {
            return <EventIcon src={PicRailwayNoTrainOrange} />
          } else {
            return <EventIcon src={PicRailwayNoTrainRed} />
          }
        } else {
          if (row.TrafficImpact.length < 2) {
            return <EventIcon src={PicRailwayOtherGreen} />
          } else if (row.TrafficImpact.length < 6) {
            return <EventIcon src={PicRailwayOtherOrange} />
          } else {
            return <EventIcon src={PicRailwayOtherRed} />
          }
        }
      },
    },
    {
      title: "Händelse",
      field: "Header",
      render: ({ Header }) => <Text size="small">{Header}</Text>,
    },

    {
      title: "Updaterad",
      field: "LastUpdateDateTime",
      type: "datetime",
      defaultSort: "desc",
      dateSetting: { locale: "sv-SE" },
      render: ({ LastUpdateDateTime }) => (
        <Text size="small">{formatEpochDateTime(LastUpdateDateTime)}</Text>
      ),
    },

    {
      title: "Sluttidsprognos",
      field: "PrognosticatedEndDateTimeTrafficImpact",
      type: "datetime",
      dateSetting: { locale: "sv-SE" },
      render: ({ PrognosticatedEndDateTimeTrafficImpact }) => (
        <Text size="small">
          {formatEpochDateTime(PrognosticatedEndDateTimeTrafficImpact)}
        </Text>
      ),
    },
  ]

  return (
    <Paper>
      <FilterSelector setShowAllStationsAffected={setShowAllStationsAffected} />

      <MaterialTable
        title="TRV Händelser"
        columns={initColumnType}
        data={
          showAllStationsAffected
            ? trvTrainMsgEvents
            : trvTrainMsgEvents.filter(filterStationEvents)
        }
        options={{
          sorting: true,
          paging: true,
          pageSize: 5,
          padding: "dense",
          paginationType: "normal",
          showTitle: true,
          rowStyle: {},
        }}
        detailPanel={row => {
          return (
            <div style={{ padding: "0.5rem" }}>
              <Text bold={true}>Detaljerad information</Text>
              <p> {row.rowData.ExternalDescription}</p>

              <TrafficImpact
                routeImpact={row.rowData.TrafficImpact}
                key={"a" + row.rowData.ExternalDescription}
              />

              <Text bold={true}>Medelande detaljer </Text>
              <p>
                Händelsens starttid:{" "}
                {formatEpochDateTime(row.rowData.StartDateTime)}
                <br />
                Updaterad: {formatEpochDateTime(row.rowData.LastUpdateDateTime)}
                <br />
                Modiferiad: {formatEpochDateTime(row.rowData.ModifiedTime)}
              </p>
            </div>
          )
        }}
      />
    </Paper>
  )
}
