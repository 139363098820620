import { useEffect } from "react"
import { useRecoilState } from "recoil"
import {
  trainLocationsState,
  trainNetworkStatisticsState,
} from "../recoil/atoms"
import { useBackendWithAuth } from "./backend"

export const useTrainLocations = () => {
  const [{ data }, fetchTrainLocations] = useBackendWithAuth()
  const [trainLocations, setTrainLocations] =
    useRecoilState(trainLocationsState)

  useEffect(() => {
    fetchTrainLocations("/train_locations")
  }, [fetchTrainLocations])

  useEffect(() => {
    if (data) {
      setTrainLocations(data)
    }
  }, [data, setTrainLocations])

  return trainLocations
}

export const useTrainNetworkStatistics = () => {
  const [{ data }, fetchTrainNetworkStatistics] = useBackendWithAuth()
  const [trainNetworkStatistics, setTrainNetworkStatistics] = useRecoilState(
    trainNetworkStatisticsState
  )

  useEffect(() => {
    fetchTrainNetworkStatistics("/station_statistics")
  }, [fetchTrainNetworkStatistics])

  useEffect(() => {
    if (data) {
      setTrainNetworkStatistics({
        ...data,
        stationAgg: Object.values(JSON.parse(data.stationAgg)),
      })
    }
  }, [data, setTrainNetworkStatistics])

  return trainNetworkStatistics
}
