import React from "react"
import { CircularProgress, Fab, Tooltip } from "@mui/material"
import { Send } from "@mui/icons-material"
import Close from "@mui/icons-material/Close"
import FloatingButtonsWrapper from "../../../base-element/static-elements/floating_buttons_wrapper"

type SendProps = {
  onClick: () => void
  disabled?: boolean
  loading?: boolean
}
export const SendButton: React.FC<SendProps> = ({
  onClick,
  disabled,
  loading = false,
}) => {
  return (
    <FloatingButtonsWrapper bottom="5rem">
      <Tooltip placement="left" title="Skicka in">
        <span>
          <Fab
            disabled={disabled}
            sx={{
              backgroundColor: "secondary.main",
              color: "#fff",
              margin: "0 0.5em",
            }}
            onClick={onClick}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Send fontSize="large" />
            )}
          </Fab>
        </span>
      </Tooltip>
    </FloatingButtonsWrapper>
  )
}

type CancelProps = {
  onClick: () => void
  disabled?: boolean
}
export const CancelButton: React.FC<CancelProps> = ({ onClick, disabled }) => {
  return (
    <FloatingButtonsWrapper bottom="1rem">
      <Tooltip placement="left" title="Återställ">
        <span>
          <Fab
            disabled={disabled}
            sx={{
              backgroundColor: "greyDark.main",
              color: "#fff",
              margin: "0 0.5em",
            }}
            onClick={onClick}
          >
            <Close fontSize="large" />
          </Fab>
        </span>
      </Tooltip>
    </FloatingButtonsWrapper>
  )
}
