import { format, isToday, isTomorrow, isYesterday } from "date-fns"

export const formatEpochDateTime = epoch => {
  const date = epoch && new Date(epoch)

  if (date && date instanceof Date && !isNaN(date)) {
    if (isTomorrow(date)) return `Imorgon ${format(date, "HH:mm")}`
    if (isYesterday(date)) return `Igår ${format(date, "HH:mm")}`
    if (isToday(date)) return format(date, "HH:mm")

    return format(date, "yyyy-MM-dd HH:mm")
  }
}

export const formatEpochTimeShort = epoch => {
  const date = epoch && new Date(epoch)

  if (date && date instanceof Date && !isNaN(date)) {
    return format(date, "HH:mm")
  }
}

export const formatUrn = urn => {
  if (!urn) return "-"
  const split = urn.split(":")
  return split[split.length - 1]
}

export const formatReferenceObject = referenceObject => {
  if (!referenceObject) return "-"

  return referenceObject.split("reference_object:") || "-"
}

export const formatStationId = id =>
  `${id.charAt(0).toUpperCase() + id.substring(1).toLowerCase()}`

export const extractStationFromLocation = location => {
  if (!location) return

  if (location.includes("track")) {
    return location.split("track:")[1].split(":")[0]
  }

  return formatUrn(location)
}

export const extractVehicleUnitsWithTimeType = ({
  latest_planned_vehicle,
  latest_estimated_vehicle,
  latest_actual_vehicle,
}) => {
  return [
    ...(latest_actual_vehicle?.units || []).map(unit => ({
      ...unit,
      timeType: "Actual",
    })),
    ...(latest_estimated_vehicle?.units || []).map(unit => ({
      ...unit,
      timeType: "Estimated",
    })),
    ...(latest_planned_vehicle?.units || []).map(unit => ({
      ...unit,
      timeType: "Planned",
    })),
  ]
}
