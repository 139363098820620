import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { TabContext, TabPanel } from "@mui/lab"
import {
  IconButton,
  Paper,
  Slider,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useMemo, useState } from "react"
import Text from "../components/text"
import { useStationKpiArrivals, useStationKpiDepartures } from "../hooks/kpis"
import KpiScatter from "./kpi_scatter"
import KpiText from "./kpi_text"

function valuetext(value) {
  return `${value}H`
}

const getScatterTime = (timeInterval, startDate) => {
  const timeNow = startDate ?? new Date()

  const timeNowMil = timeNow.getTime()
  const addSeconds = timeInterval * 60 * 60 * 1000
  const timeNew = new Date(timeNowMil + addSeconds)
  timeNew.setSeconds(0)
  return timeNew.getTime()
}

const TabsBox = styled(Box)`
  border-bottom: 1px;
  margin-bottom: 1em;
`

export default function KpiView({
  locationId = "Cst",
  arrivalFilters,
  departureFilters,
  filterTime,
  disableQuery,
}) {
  const filterDate = useMemo(
    () => (filterTime ? new Date(filterTime) : null),
    [filterTime]
  )

  const minTime = -24
  const maxTime = 24
  const [[intervalStart, intervalEnd], setDataTimeInterval] = useState([-6, 3])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"))

  const startTime = useMemo(
    () => getScatterTime(intervalStart, filterDate),
    [intervalStart, filterDate]
  )
  const endTime = useMemo(
    () => getScatterTime(intervalEnd, filterDate),
    [intervalEnd, filterDate]
  )

  const commonKpiProps = {
    locationId,
    startTime,
    endTime,
    disableQuery,
  }

  const onToggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const sliderMarks = [
    {
      value: 24,
      label: "+24H",
    },
    {
      value: 18,
      label: "+18H",
    },
    {
      value: 12,
      label: "+12H",
    },
    {
      value: 6,
      label: "+6H",
    },
    {
      value: 0,
      label: !filterDate
        ? "Nu"
        : new Date(filterDate).toLocaleTimeString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
            second: undefined,
          }),
    },
    {
      value: -6,
      label: "-6H",
    },
    {
      value: -12,
      label: "-12H",
    },
    {
      value: -18,
      label: "-18H",
    },
    {
      value: -24,
      label: "-24H",
    },
  ]

  return (
    <Paper
      sx={
        !isFullScreen
          ? { position: "relative" }
          : {
              position: "absolute",
              zIndex: 100,
              width: "98%",
              left: "1%",
              top: "2%",
              height: "95%",
            }
      }
    >
      {!smallScreen &&
        (!isFullScreen ? (
          <IconButton
            aria-label="delete"
            sx={{ position: "absolute", right: 0 }}
            onClick={onToggleFullScreen}
          >
            <OpenInFullIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label="delete"
            sx={{ position: "absolute", right: 0 }}
            onClick={onToggleFullScreen}
          >
            <CloseFullscreenIcon />
          </IconButton>
        ))}

      <Stack direction="column" justifyContent="center" alignItems="center">
        <Text bold sx={{ marginTop: "0.5rem" }}>
          Välj tidsinterval
        </Text>
        <Slider
          sx={{ margin: "0.5rem", width: "90%" }}
          getAriaLabel={() => "Minimum distance shift"}
          defaultValue={[-6, 3]}
          onChangeCommitted={(e, value) => setDataTimeInterval(value)}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          min={minTime}
          max={maxTime}
          step={2}
          marks={sliderMarks}
        />
      </Stack>

      <TabContext value={String(tabIndex)}>
        <TabsBox>
          <Tabs
            value={tabIndex}
            onChange={(e, index) => setTabIndex(index)}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="Punktlighet ankomster" />
            <Tab label="Punktlighet avgångar" />
          </Tabs>
        </TabsBox>
        <TabPanel value="0">
          <KpiScatter
            isFullScreen={isFullScreen}
            useKpis={useStationKpiArrivals}
            filters={arrivalFilters}
            {...commonKpiProps}
          />
        </TabPanel>
        <TabPanel value="1">
          <KpiScatter
            isFullScreen={isFullScreen}
            useKpis={useStationKpiDepartures}
            filters={departureFilters}
            {...commonKpiProps}
          />
        </TabPanel>
      </TabContext>
      <KpiText
        filters={tabIndex === 0 ? arrivalFilters : departureFilters}
        {...commonKpiProps}
      />
    </Paper>
  )
}
