import { Autocomplete, FormControl, TextField } from "@mui/material"
import { useStateCatalogue } from "../../../../hooks/staticData"

const stateTypeData = {
  LocationState: { sortValue: 0, name: "Förflyttning" },
  ServiceState: { sortValue: 1, name: "Service" },
  AdministrativeState: { sortValue: 2, name: "Administrativt" },
}

export default function StateSelector({ value, onChange }) {
  const { states: stateCatalogue } = useStateCatalogue()
  return (
    <FormControl fullWidth>
      <Autocomplete
        value={value}
        onChange={(e, state) => onChange(state)}
        getOptionLabel={state => `${state.name}`}
        groupBy={state => stateTypeData[state.stateType].name}
        options={[...stateCatalogue].sort((a, b) =>
          stateTypeData[a.stateType].sortValue <
          stateTypeData[b.stateType].sortValue
            ? -1
            : 1
        )}
        renderInput={params => <TextField {...params} label="Händelsetyp" />}
      />
    </FormControl>
  )
}
