import { format } from "date-fns"
import { useSelector } from "react-redux"
import itemRoutes from "../../routes.json"
import { formatRcmfId, formatTrainId } from "../../utils/dataUtils"
import Text from "../Text"
import CollapsibleTable from "../CollapsibleTable"
import TrainIcon from "../icons/TrainIcon"
import Link from "../Link"
import ListItemContainer from "./ListItemContainer"
import ROUTES from "../../../../ROUTES.json"

const TrainListItem = ({ item, isNewlyUpdated }) => {
  const reader = useSelector(
    state => state.readers.byId[item.latestMessage.payload.at]
  )

  return (
    <ListItemContainer
      Icon={TrainIcon}
      isNewlyUpdated={isNewlyUpdated}
      itemId={item.trainId}
      title={formatTrainId(item.trainId)}
      latestMessageId={item.latestMessage.messageId}
      collapsedChildren={
        <CollapsibleTable
          headerItems={["Wagons"]}
          rows={item.latestReferenceObjects.map(refObjId => [
            <Link to={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}/${refObjId}`}>
              {formatRcmfId(refObjId)}
            </Link>,
          ])}
        />
      }
      collapsedText="Show wagons"
      expandedText="Hide wagons"
    >
      <Text small>
        <b>Last read: </b>
        {format(
          new Date(item.latestMessage.payload.time),
          "yyyy-MM-dd HH:mm:ss"
        )}
      </Text>
      <Text small>
        <b>Vehicle length: </b>
        {item.latestReferenceObjects?.length || "Unknown"}
      </Text>
      <Text small>
        <b>Reader: </b>
        <Link to={`${ROUTES.RFID}/${itemRoutes.READERS}/${reader?.SGLN}`}>
          {reader?.Name}
        </Link>
      </Text>
    </ListItemContainer>
  )
}

export default TrainListItem
