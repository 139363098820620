import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TaskColumn from "./track_layout_column";
import { DragDropContext } from "react-beautiful-dnd";

const initialData = {
  tasks: {
    "train-demo": { id: "train-demo", TechnicalTrainIdent: "DEMO (TRAIN)" },
  },
  columns: {
    "track-1": {
      id: "track-1",
      title: "Spår 1",
      capacity: 200,
      taskIds: ["train-demo"],
    },
    "track-2": {
      id: "track-2",
      title: "Spår 2",
      capacity: 200,
      taskIds: [],
    },
    "track-3": {
      id: "track-3",
      title: "Spår 3",
      capacity: 200,
      taskIds: [],
    },
    "track-4": {
      id: "track-4",
      title: "Spår 4",
      capacity: 200,
      taskIds: [],
    },
    "track-5": {
      id: "track-5",
      title: "Spår 5",
      capacity: 200,
      taskIds: [],
    },
    "track-6": {
      id: "track-6",
      title: "Spår 6",
      capacity: 200,
      taskIds: [],
    },
    "track-7": {
      id: "track-7",
      title: "Spår 7",
      capacity: 200,
      taskIds: [],
    },
    "track-8": {
      id: "track-8",
      title: "Spår 8",
      capacity: 200,
      taskIds: [],
    },
    "track-9": {
      id: "track-9",
      title: "Spår 9",
      capacity: 200,
      taskIds: [],
    },
    "track-10": {
      id: "track-10",
      title: "Spår 10",
      capacity: 200,
      taskIds: [],
    },
    "track-11": {
      id: "track-11",
      title: "Spår 11",
      capacity: 200,
      taskIds: [],
    },
    "track-12": {
      id: "track-12",
      title: "Spår 12",
      capacity: 200,
      taskIds: [],
    },
    "track-13": {
      id: "track-13",
      title: "Spår 13",
      capacity: 200,
      taskIds: [],
    },
    "track-14": {
      id: "track-14",
      title: "Spår 14",
      capacity: 200,
      taskIds: [],
    },
    "track-15": {
      id: "track-15",
      title: "Spår 15",
      capacity: 200,
      taskIds: [],
    },
    "track-16": {
      id: "track-16",
      title: "Spår 16",
      capacity: 200,
      taskIds: [],
    },
    "track-x": {
      id: "track-x",
      title: "Spår X",
      capacity: 200,
      taskIds: [],
    },
    "track--": {
      id: "track--",
      title: "Spår -",
      capacity: 200,
      taskIds: [],
    },
  },

  // Facilitate reordering of the columns
  columnOrder: [
    "track-1",
    "track-2",
    "track-3",
    "track-4",
    "track-5",
    "track-6",
    "track-7",
    "track-8",
    "track-9",
    "track-10",
    "track-11",
    "track-12",
    "track-13",
    "track-14",
    "track-15",
    "track-16",
    "track-x",
    "track--",
  ],
};

export default function EventBoard(props) {
  const [boardData, setBoardData] = useState(initialData);

  useEffect(() => {
    Func();

    async function Func() {
      // Parse train to tracks
      let inData = props.timetableData;

      await inData.map((obj) => {
        // Creat Task
        initialData.tasks[obj.TechnicalTrainIdent] = {
          ...obj,
          id: obj.TechnicalTrainIdent,
          content: obj.TechnicalTrainIdent,
        };

        // Place on track (TrackAtLocation)
        if (obj.TrackAtLocation) {
          initialData.columns["track-" + obj.TrackAtLocation].taskIds.push(obj.TechnicalTrainIdent);
        }

        return initialData
      });

      await setBoardData(initialData);
    }
  }, [props]);

  const onDragStart = () => {
    console.log("Drag started");
  };

  const onDragUpdate = (update) => {
    const { destination } = update;
    console.log(destination);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // Draggable item dropped outside
    if (!destination) {
      return;
    }
    // Draggable item ended up in the same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Reordering draggable items
    const start = boardData.columns[source.droppableId];
    const finish = boardData.columns[destination.droppableId];

    // Moving in the same column
    if (start === finish) {
      console.log("here");
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      setBoardData({
        ...boardData,
        columns: {
          ...boardData.columns,
          [newColumn.id]: newColumn,
        },
      });
      return;
    }

    // Moving to an other column
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    setBoardData({
      ...boardData,
      columns: {
        ...boardData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    });

    console.log(result);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
            {boardData.columnOrder.map((columID) => {
              let column = boardData.columns[columID];
              let tasks = column.taskIds.map((taskID) => boardData.tasks[taskID]);
              return <TaskColumn key={column.id} column={column} tasks={tasks} />;
            })}
          </DragDropContext>
        </Grid>
      </Grid>
    </Grid>
  );
}
