import { isSameDay } from "date-fns"

export const hasDifferentDays = (message, prevMessage) => {
  const isFirstMessage = !prevMessage
  return (
    isFirstMessage ||
    !isSameDay(
      new Date(message.payload.time),
      new Date(prevMessage.payload.time)
    )
  )
}
