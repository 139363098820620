export const FETCH_REFERENCE_OBJECTS_BEGIN = "FETCH_REFERENCE_OBJECTS_BEGIN"
export const FETCH_REFERENCE_OBJECTS_SUCCESS = "FETCH_REFERENCE_OBJECTS_SUCCESS"
export const FETCH_REFERENCE_OBJECTS_ERROR = "FETCH_REFERENCE_OBJECTS_ERROR"

export const FETCH_TRAINS_BEGIN = "FETCH_TRAINS_BEGIN"
export const FETCH_TRAINS_SUCCESS = "FETCH_TRAINS_SUCCESS"
export const FETCH_TRAINS_ERROR = "FETCH_TRAINS_ERROR"

export const FETCH_READERS_BEGIN = "FETCH_READERS_BEGIN"
export const FETCH_READERS_SUCCESS = "FETCH_READERS_SUCCESS"
export const FETCH_READERS_ERROR = "FETCH_READERS_ERROR"

export const SET_VISIBLE_READERS = "SET_VISIBLE_READERS"
export const CLEAR_VISIBLE_READERS = "CLEAR_VISIBLE_READERS"

export const FETCH_MESSAGES_BEGIN = "FETCH_MESSAGES_BEGIN"
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS"
export const FETCH_MESSAGES_ERROR = "FETCH_MESSAGES_FOR_REF_OBJECT_ERROR"

export const FETCH_REFERENCE_OBJECT_BEGIN = "FETCH_REFERENCE_OBJECT_BEGIN"
export const FETCH_REFERENCE_OBJECT_SUCCESS = "FETCH_REFERENCE_OBJECT_SUCCESS"
export const FETCH_REFERENCE_OBJECT_ERROR = "FETCH_REFERENCE_OBJECT_ERROR"

export const FETCH_TRAIN_BEGIN = "FETCH_TRAIN_BEGIN"
export const FETCH_TRAIN_SUCCESS = "FETCH_TRAIN_SUCCESS"
export const FETCH_TRAIN_ERROR = "FETCH_TRAIN_ERROR"

export const FETCH_READER_BEGIN = "FETCH_READER_BEGIN"
export const FETCH_READER_SUCCESS = "FETCH_READER_SUCCESS"
export const FETCH_READER_ERROR = "FETCH_READER_ERROR"

export const MESSAGE_EVENT_RECEIVED = "MESSAGE_EVENT_RECEIVED"

export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN"
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS"
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR"

export const SET_USER_TOKEN = "SET_USER_TOKEN"
