import React, { useState } from "react"
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Stack,
  Fab,
  CircularProgress,
  styled,
} from "@mui/material"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import SubmitRCMFAuto from "./submit_rcmf_auto"
import { useRecoilState } from "recoil"
import { formDialogState } from "../../../recoil/atoms"

const LoaderContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
`

const Loader = ({ title }) => (
  <LoaderContainer>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography variant="h4">{title}</Typography>
      <CircularProgress sx={{ marginTop: "1rem" }} />
    </Stack>
  </LoaderContainer>
)

export default function DialogWindow() {
  const [dialogWindow, setDialogWindow] = useRecoilState(formDialogState)

  const handleClose = () => setDialogWindow({ ...dialogWindow, isOpen: false })

  return (
    <Dialog open={dialogWindow.isOpen} maxWidth="sm" onClose={handleClose}>
      <DialogContent>
        <Grid
          container
          sx={{ width: "100%", minHeight: "20vh", position: "relative" }}
          color="primary"
        >
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Inmatning av tidsstämpel</Typography>
              <Fab
                sx={{ color: "#fff", marginLeft: "1rem" }}
                color={"secondary"}
                onClick={handleClose}
                size="small"
              >
                <CloseRoundedIcon fontSize="large" />
              </Fab>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <SubmitRCMFAuto />
          </Grid>
        </Grid>
        {(dialogWindow.isLoading || dialogWindow.isSending) && (
          <Loader title={dialogWindow.isSending ? "Skickar..." : "Laddar..."} />
        )}
      </DialogContent>
    </Dialog>
  )
}
