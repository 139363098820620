export const formatRcmfId = id => {
  const split = id.split(":")
  return split[split.length - 1]
}

export const formatTrainId = (id = "") => {
  if (id.includes("train_number:")) {
    return id.split("train_number:")[1]
  }

  return formatRcmfId(id)
}

export const formatWagonId = message =>
  `Train ${formatTrainId(message.technicalTrainId)} wagon ${
    message.vehicle_position || formatRcmfId(message.payload.referenceObject)
  }`

export const getRcmfRefObjectType = refObjectId =>
  refObjectId.split("reference_object:")[1].split(":")[0]

export const sortByLatestMessageTime = (a, b) =>
  a.latestMessage?.payload.time > b.latestMessage?.payload.time ? -1 : 1
