import { format } from "date-fns"
import itemRoutes from "../../routes.json"
import { formatRcmfId } from "../../utils/dataUtils"
import ReaderIcon from "../icons/ReaderIcon"
import Link from "../Link"
import Text from "../Text"
import ListItemContainer from "./ListItemContainer"
import ROUTES from "../../../../ROUTES.json"

const ReaderListItem = ({ item, isNewlyUpdated }) => {
  const latestMessage = item.latestMessage

  return (
    <ListItemContainer
      itemId={item.SGLN}
      isNewlyUpdated={isNewlyUpdated}
      Icon={ReaderIcon}
      title={item.Name}
    >
      {item["Current status"] && (
        <Text small>
          <b>Status: </b>
          {item["Current status"]}
        </Text>
      )}
      <Text small>
        <b>Latest reading: </b>
        {latestMessage
          ? format(
              new Date(item.latestMessage.payload.time),
              "yyyy-MM-dd HH:mm:ss"
            )
          : "No readings yet."}
      </Text>
      <Text small>
        <b>Wagon: </b>
        {latestMessage ? (
          <Link
            to={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}/${latestMessage.payload.referenceObject}`}
          >
            {formatRcmfId(latestMessage.payload.referenceObject)}
          </Link>
        ) : (
          <Text>"N/A"</Text>
        )}
      </Text>
    </ListItemContainer>
  )
}

export default ReaderListItem
