import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const useColumns = (timeSequence?: "arrivals" | "departures") => {
  const { t } = useTranslation()
  return useMemo(
    () => [
      t("plank.columns.trainId"),
      t("plank.columns.otn"),
      t("plank.columns.operator"),
      {
        arrivals: t("plank.columns.arrivals"),
        departures: t("plank.columns.departures"),
        both: t("plank.columns.arrivalsDepartures"),
      }[timeSequence ?? "both"],
      t("plank.columns.track"),
      t("plank.columns.comment"),
      t("plank.columns.newOperator"),
      t("plank.columns.newTime"),
      t("plank.columns.newTrack"),
      t("plank.columns.newComment"),
      t("plank.columns.done"),
    ],
    [timeSequence, t]
  )
}
