import styled from "styled-components"
import { COLOR_PRIMARY, COLOR_SECONDARY } from "../../utils/colors"

const Container = styled.div`
  color: ${COLOR_PRIMARY};
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  box-shadow: 0 8px 10px -10px black;
  position: relative;
  z-index: 2;
  padding: 0.5em 0;
  background-color: ${COLOR_PRIMARY};
  color: ${COLOR_SECONDARY};
`

const Title = styled.h4`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BaseContainer = ({
  TitleIcon,
  titleText,
  headerContent,
  bodyContent,
}) => (
  <Container>
    <Header>
      <Title>
        {TitleIcon && <TitleIcon fill={COLOR_SECONDARY} />}
        {titleText}
      </Title>
      {headerContent}
    </Header>
    {bodyContent}
  </Container>
)

export default BaseContainer
