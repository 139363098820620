import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useRecoilValue } from "recoil"
import { userState } from "../recoil/atoms"

export const useAuthDispatch = () => {
  const dispatch = useDispatch()
  const { accessToken } = useRecoilValue(userState)
  return useCallback(
    action => dispatch(action(accessToken)),
    [accessToken, dispatch]
  )
}
