/*
  General View of an station
  --------------------
    
*/

import { Grid, Paper, styled, useMediaQuery, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import KpiView from "../../../components/kpi_view"
import TrainsTable from "../../../components/TrainsTable"
import TrainInsight from "../../../components/train_insight"
import { useUserHistory } from "../../../hooks/history"
import { useArrivingTrains, useDepartingTrains } from "../../../hooks/trains"
import EventTable from "./table_event"

const PageColumn = styled(Grid)`
  padding-right: 0.5em;

  &:first-of-type {
    padding-left: 0.5em;
  }
`

const ContentWrapper = styled("div")`
  &:not(:first-of-type) {
    padding-top: 0.5em;
  }
`

const StackContainerItem = styled("div")`
  &:not(:first-of-type) {
    margin-top: 0.5em;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin: 0.5em 0;
  }
`

const Container = styled(Grid)`
  padding: 0 0.2em;
`

export default function StationStationOverview({ startTime, disableQueries }) {
  const pageSize = 20
  const [arrivalsOffset, setArrivalsOffset] = useState(0)
  const [departuresOffset, setDeparturesOffset] = useState(0)

  const [arrivalFilters, setArrivalFilters] = useState({})
  const [departureFilters, setDepartureFilters] = useState({})

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"))

  const { page, stationId, trainId } =
    useRouteMatch("/:page/:stationId?/:trainId?")?.params || {}

  const history = useUserHistory()
  const setSelectedTrainId = useCallback(
    trainId => history.push(`/${page}/${stationId}/${trainId || ""}`),
    [page, stationId, history]
  )

  const selectedTrainId =
    (typeof trainId === "string" &&
      trainId.split(".").length === 2 &&
      trainId) ||
    null

  const { trains: arrivingTrains, isLoading: isLoadingArrivals } =
    useArrivingTrains(stationId, {
      disable: disableQueries,
      limit: pageSize,
      skip: arrivalsOffset,
      startTime,
      ...arrivalFilters,
    })

  const { trains: departingTrains, isLoading: isLoadingDepartures } =
    useDepartingTrains(stationId, {
      disable: disableQueries,
      limit: pageSize,
      skip: departuresOffset,
      startTime,
      ...departureFilters,
    })

  useEffect(() => {
    setArrivalsOffset(0)
    setDeparturesOffset(0)
  }, [stationId])

  const renderTrainInsight = () =>
    selectedTrainId && (
      <Paper>
        <TrainInsight
          trainId={selectedTrainId}
          stationId={stationId}
          onDeselectTrain={() => setSelectedTrainId(null)}
        />
      </Paper>
    )

  return (
    <Container container item md={12}>
      {/* Column 1 */}
      <PageColumn item xs={12} lg={4}>
        {smallScreen && renderTrainInsight()}
        <ContentWrapper>
          <TrainsTable
            stationId={stationId}
            arrivingTrains={arrivingTrains}
            isLoading={isLoadingArrivals}
            onSelectTrain={setSelectedTrainId}
            selectedTrain={selectedTrainId}
            trainFilters={arrivalFilters}
            onTrainFiltersChange={filters => {
              setArrivalsOffset(0)
              setArrivalFilters(filters)
            }}
            pageSize={pageSize}
            onRequestItems={(offset, limit) => setArrivalsOffset(offset)}
            dataOffset={arrivalsOffset}
          />
        </ContentWrapper>
      </PageColumn>

      {/* Column 2 */}
      <PageColumn item xs={12} lg={4}>
        <ContentWrapper>
          <TrainsTable
            stationId={stationId}
            isLoading={isLoadingDepartures}
            departingTrains={departingTrains}
            pageSize={pageSize}
            onSelectTrain={setSelectedTrainId}
            selectedTrain={selectedTrainId}
            trainFilters={departureFilters}
            onTrainFiltersChange={filters => {
              setDeparturesOffset(0)
              setDepartureFilters(filters)
            }}
            onRequestItems={(offset, limit) => setDeparturesOffset(offset)}
            dataOffset={departuresOffset}
          />
        </ContentWrapper>
      </PageColumn>

      {/* Column 3 */}
      <PageColumn item xs={12} lg={4}>
        {(!selectedTrainId || smallScreen) && (
          <>
            <StackContainerItem>
              <KpiView
                filterTime={startTime}
                arrivalFilters={arrivalFilters}
                departureFilters={departureFilters}
                locationId={stationId}
                disableQuery={disableQueries}
              />
            </StackContainerItem>
            <StackContainerItem>
              <EventTable stationId={stationId} />
            </StackContainerItem>
          </>
        )}
        {!smallScreen && renderTrainInsight()}
      </PageColumn>
    </Container>
  )
}
