import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HouseIcon from "@mui/icons-material/House"
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab"
import { styled, TableSortLabel, Tooltip } from "@mui/material"
import { differenceInMinutes } from "date-fns"
import React, { memo, useEffect, useRef, useState } from "react"
import {
  useRfidReaders,
  useStateCatalogue,
  useStations,
} from "../hooks/staticData"
import {
  formatEpochDateTime,
  formatStationId,
  formatUrn,
} from "../utils/dataFormatting"
import FlexDiv from "./flex_div"
import Text from "./text"
import TrainTimelineReportedBy from "./train_timeline_reported_by"
import { timeTypePaletteColors as dotColors } from "../utils/rcmf"
import { Link } from "react-router-dom"
import ROUTES from "../ROUTES.json"

const LegendItem = ({ children, ...props }) => (
  <div style={{ margin: "0 0.5em", display: "flex", alignItems: "center" }}>
    <TimelineDot {...props} style={{ marginRight: "0.2em" }} />
    {children}
  </div>
)

const TextButton = styled("button")`
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const IntermediateEventsWrapper = ({ children = [] }) => {
  const [isIn, setIsIn] = useState(false)
  return (
    <>
      <TimelineItem onClick={() => setIsIn(!isIn)}>
        <TimelineContent>
          <TextButton>
            <Text>{`${isIn ? "Göm" : "Visa"} ${children?.length} händelse${
              children?.length === 1 ? "" : "r"
            }`}</Text>
            {isIn ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </TextButton>
        </TimelineContent>
        <TimelineSeparator>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineOppositeContent />
      </TimelineItem>
      {isIn && children}
    </>
  )
}

const SortLabel = styled(TableSortLabel)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    position: absolute;
    top: 0.2em;
    left: 0;
  }

  z-index: 10;
`

const Container = styled("div")`
  position: relative;
`

const EventTitle = ({ event, rfidReaders, stations, stateCatalogue }) => {
  const { location, state } = event

  const matchedState = stateCatalogue.find(state => state.id === event.state)
  let locationString = ""

  if (matchedState) {
    if (["station", "depot"].includes(matchedState.locationType)) {
      locationString = location.includes("rcmf:")
        ? formatStationId(formatUrn(location))
        : location
    } else if (matchedState.locationType === "rfid_reader") {
      locationString =
        rfidReaders[location.split("rcmf:location:rfid_reader:")[1] || location]
          ?.Name || location
    }

    return (
      <FlexDiv style={{ whiteSpace: "pre-wrap" }}>
        <Text>{matchedState.name} </Text>
        <Tooltip
          title={stations[locationString]?.name || ""}
          placement="top"
          arrow
          disableFocusListener
        >
          <span>
            <Text>{locationString}</Text>
          </span>
        </Tooltip>
      </FlexDiv>
    )
  }

  return (
    <Text>
      {state} {location}
    </Text>
  )
}

const renderDiffMinutes = diffMinutes => {
  if (!diffMinutes) {
    return ""
  }

  if (diffMinutes < 0) {
    return `(${diffMinutes})`
  } else {
    return `(+${diffMinutes})`
  }
}

const StyledTimeline = styled(Timeline)`
  padding: 0;
  margin: 0;
  max-height: 60vh;
  overflow-y: auto;
`

const TrainTimeline = ({
  events: unfilteredEvents,
  messagesData,
  selectedStation,
  selectedTrainNumber,
}) => {
  const { readers: rfidReaders } = useRfidReaders()
  const { stations } = useStations()
  const { states: stateCatalogue } = useStateCatalogue()
  const [sortDirection, setSortDirection] = useState("desc")
  const selectedStationRef = useRef(null)

  useEffect(() => {
    if (selectedStationRef.current) {
      selectedStationRef.current.scrollIntoView({
        block: "nearest",
        behavior: "auto",
      })
    }
  }, [selectedStationRef])

  const TimelineEvent = ({ event, isLast }) => {
    const {
      plannedTime: pt,
      estimatedEime: et,
      actualTime: at,
      latestTime: time,
      messages,
      latestTimeType: timeType,
    } = event
    const diffMinutes = differenceInMinutes(
      new Date(time),
      new Date(pt || et || at)
    )

    return (
      <TimelineItem key={`${event.state}${event.location}`}>
        <TimelineOppositeContent style={{ zIndex: 10 }}>
          <EventTitle
            event={event}
            rfidReaders={rfidReaders}
            stations={stations}
            stateCatalogue={stateCatalogue}
          />
          {event.linkedTrainNumber && (
            <Text size="small">
              <Link to={`${event.linkedTrainId}`}>
                ({event.linkedTrainNumber})
              </Link>
            </Text>
          )}
          {!event.linkedTrainNumber &&
            event.technicalTrainNumber !== selectedTrainNumber && (
              <Text size="small">({event.technicalTrainNumber})</Text>
            )}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            variant={
              (timeType || "Actual") === "Actual" ? "filled" : "outlined"
            }
            color={dotColors[timeType || "Actual"]}
            sx={{
              color: (timeType ?? "Actual") === "Actual" ? "white" : undefined,
            }}
          >
            {selectedStation === event.location ? (
              <HouseIcon ref={selectedStationRef} />
            ) : null}
          </TimelineDot>
          {!isLast && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Text bold right>
            {formatEpochDateTime(time)} {renderDiffMinutes(diffMinutes)}
          </Text>
          <TrainTimelineReportedBy
            messages={messagesData
              .filter(m => messages.includes(m.messageId))
              .sort((a, b) => a.reportedAt - b.reportedAt)}
          />
        </TimelineContent>
      </TimelineItem>
    )
  }

  const events = unfilteredEvents
    .filter(e => !!e.latestTime)
    .sort((a, b) => {
      return a.latestTime < b.latestTime
        ? { asc: -1, desc: 1 }[sortDirection]
        : { asc: 1, desc: -1 }[sortDirection]
    })
  const selectedStationEvents = []
  const firstIntermediateEvents = []
  const lastIntermediateEvents = []
  const lastEvent =
    events[events.length - 1]?.location !== selectedStation &&
    events[events.length - 1]

  for (let i = 1; i < events.length; i++) {
    const event = events[i]
    if (event.location === selectedStation) {
      selectedStationEvents.push(event)
      // Not a nice solution
    } else if (
      selectedStationEvents.length === 0 &&
      events[0].location !== selectedStation
    ) {
      firstIntermediateEvents.push(event)
    } else if (i < events.length - 1) {
      lastIntermediateEvents.push(event)
    }
  }

  const timelineItems = [
    (props, index) => (
      <TimelineEvent key={index} event={events[0]} {...props} />
    ),
    firstIntermediateEvents.length > 0 &&
      ((props, index) => (
        <IntermediateEventsWrapper key={index}>
          {firstIntermediateEvents.map(e => (
            <TimelineEvent event={e} key={e.state + e.location + e.updatedAt} />
          ))}
        </IntermediateEventsWrapper>
      )),
    ...selectedStationEvents.map(e => (props, index) => (
      <TimelineEvent event={e} key={e.state + e.location + index} {...props} />
    )),
    lastIntermediateEvents.length > 0 &&
      ((props, index) => (
        <IntermediateEventsWrapper key={index}>
          {lastIntermediateEvents.map(e => (
            <TimelineEvent event={e} key={e.state + e.location + e.updatedAt} />
          ))}
        </IntermediateEventsWrapper>
      )),
    lastEvent &&
      ((props, index) => (
        <TimelineEvent key={index} event={lastEvent} {...props} />
      )),
  ].filter(e => !!e)

  return (
    <Container>
      <SortLabel
        direction={sortDirection}
        active
        onClick={() =>
          setSortDirection(sortDirection === "asc" ? "desc" : "asc")
        }
      >
        {{ asc: "Stigande", desc: "Fallande" }[sortDirection]}
      </SortLabel>
      <StyledTimeline position="left">
        {timelineItems.map((render, index) =>
          render({ isLast: index === timelineItems.length - 1 }, index)
        )}
      </StyledTimeline>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LegendItem variant="outlined" color={dotColors["Planned"]}>
          Planerad
        </LegendItem>
        <LegendItem variant="outlined" color={dotColors["Estimated"]}>
          Beräknad
        </LegendItem>
        <LegendItem variant="filled" color={dotColors["Actual"]}>
          Faktisk
        </LegendItem>
      </div>
    </Container>
  )
}

export default memo(TrainTimeline)
