import LabelIcon from "@mui/icons-material/Label"
import { Box, Button, Card, Chip, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { formatEpochDateTime } from "../../utils/dataFormatting"

export default function LocationPassingInfo({ train }) {
  const [showWagons, setShowWagons] = useState(false)

  const toggleShowWagons = () => {
    setShowWagons(!showWagons)
  }

  const trainLength = train.passingByEvent.actualTrainComposition?.length || 0

  return (
    <div>
      <Card elevation={2} sx={{ width: "100%", margin: "0.5rem 0rem" }}>
        <Box style={{ margin: "0.8rem" }}>
          <p>
            <b>
              {" "}
              Passagetid: {formatEpochDateTime(train.passingByEvent.actualTime)}
              <br />
              Tåg-ID:{" "}
              <Link to={`/map/train/${train.trainId}`}>
                {train.technicalTrainNumber}
              </Link>{" "}
              <br />
              Rapported av: {train.passingByEvent.updatedBy}
            </b>
            <br />
          </p>
        </Box>
        <Typography
          variant="h6"
          color="text.primary"
          align="center"
          sx={{ marginBottom: "0.5rem" }}
        >
          Tågsammansättning ({trainLength})
          {trainLength ? (
            <Button
              onClick={toggleShowWagons}
              variant="outlined"
              size="small"
              color="secondary"
            >
              {showWagons ? "Dölj" : "Visa"}
            </Button>
          ) : null}
        </Typography>

        {showWagons
          ? train.passingByEvent.actualTrainComposition?.map(obj => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%", marginBottom: "0.5rem" }}
                >
                  <Chip
                    avatar={<LabelIcon />}
                    label={obj}
                    variant="outlined"
                    sx={{ maxWidth: "15rem" }}
                  />
                </Stack>
              )
            })
          : null}
      </Card>
    </div>
  )
}
