import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
} from "@mui/material"
import MaterialTable from "@material-table/core"
import { useEffect, useState } from "react"
import Text from "../../components/text"
import { useBackendWithAuth } from "../../hooks/backend"

const Container = styled(Paper)`
  width: 80%;
`

const InputContainer = styled(Grid)`
  padding: 1em;
`

export default function Users() {
  const [{ data: users, error }, fetchUsers] = useBackendWithAuth()
  const [{}, postUser] = useBackendWithAuth()
  const [username, setUsername] = useState("")
  const [role, setRole] = useState("")
  const [password, setPassword] = useState("")

  const submitUser = () => {
    postUser("/user", {
      method: "POST",
      body: JSON.stringify({ username, password, role }),
    })
    setUsername("")
    setPassword("")
    setRole("")
    fetchUsers("/users")
  }

  useEffect(() => {
    fetchUsers("/users")
  }, [fetchUsers])

  if (!error && !users) {
    return (
      <div>
        <p>Laddar...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <p>Du har inte behörighet att visa den här sidan.</p>
      </div>
    )
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Användare"
            columns={[
              { title: "Användarnamn", field: "username" },
              { title: "Roll", field: "role" },
            ]}
            data={users}
            options={{
              pageSize: 10,
              padding: "dense",
            }}
          />
        </Grid>
        <InputContainer container spacing={2} xs={12}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Användarnamn"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Lösenord"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Roll</InputLabel>
              <Select
                value={role}
                label="Roll"
                onChange={e => setRole(e.target.value)}
                labelId="role-select-label"
                id="role-select"
              >
                <MenuItem value={"admin"}>Administratör</MenuItem>
                <MenuItem value={"user"}>Användare</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={"contained"}
              color="secondary"
              sx={{ margin: "0.5rem" }}
              onClick={submitUser}
              fullWidth
            >
              Skapa
            </Button>
          </Grid>
        </InputContainer>
      </Grid>
    </Container>
  )
}
