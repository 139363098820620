import { FilterList } from "@mui/icons-material"
import { Button, Paper, Popover, styled } from "@mui/material"
import { useRef, useState } from "react"

type Props = {
  children?: JSX.Element
  title: string
  icon?: JSX.Element
}

const PopoverButton: React.FC<Props> = props => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(null)
  return (
    <>
      <Button
        startIcon={props.icon ?? <FilterList />}
        variant="outlined"
        ref={buttonRef}
        onClick={() => setOpen(prev => !prev)}
      >
        {props.title}
      </Button>
      <Popover
        open={open}
        onClose={() => setOpen(prev => !prev)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Container>{props.children}</Container>
      </Popover>
    </>
  )
}

const Container = styled(Paper)`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export default PopoverButton
