import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Button } from "@mui/material";

const GridCenter = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function SuccessMessage(props) {
  return (
    <>
      <GridCenter item xs={12}>
        <h1>{props.eventSentMsg}</h1>
      </GridCenter>
      <GridCenter item xs={12}>
        <Button variant={"outlined"} color={'primary'} onClick={props.creatNewRapport}>
          Skapa en ny event rapport
        </Button>
      </GridCenter>
    </>
  );
}
