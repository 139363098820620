import { MTableToolbar } from "@material-table/core"
import FilterSwitch from "../filter_switch"

export default function Toolbar(props) {
  const { trainFilters, onTrainFiltersChange } =
    props.components.toolbarProps || {}
  return (
    <div>
      <MTableToolbar
        {...props}
        search={!!trainFilters && !!onTrainFiltersChange}
      />

      {onTrainFiltersChange && trainFilters && (
        <FilterSwitch
          onTrainFiltersChange={onTrainFiltersChange}
          trainFilters={trainFilters}
        />
      )}
    </div>
  )
}
