import Marker from "react-leaflet-enhanced-marker"
import ArrowIcon from "@mui/icons-material/ArrowUpward"
import styled from "styled-components"
import {
  COLOR_ACCENT_SECONDARY,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_WHITE,
} from "../../utils/colors"
import ReaderIcon from "../icons/ReaderIcon"
import Text from "../Text"
import ItemsLayer from "./ItemsLayer"

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.1em;
  background-color: ${COLOR_PRIMARY};
  border: 0.3px solid ${COLOR_SECONDARY};
  ${({ glow }) => glow && `box-shadow: 0 0 5px 5px ${COLOR_ACCENT_SECONDARY};`}
`

const Icon = ({ messageCount, glow, reader, nextReader }) => {
  const vector = nextReader && {
    x: nextReader.Longitude - reader.Longitude,
    y: nextReader.Latitude - reader.Latitude,
  }

  const rotation = (vector && Math.atan2(vector.x, vector.y)) || 0

  return (
    <div
      style={
        {
          display: "inline-block",
        } /* Required for Markers to work in leaflet */
      }
    >
      <IconContainer glow={glow}>
        {nextReader ? (
          <ArrowIcon
            width={20}
            height={20}
            style={{ transform: `rotate(${rotation}rad)` }}
            fill={COLOR_WHITE}
          />
        ) : (
          <ReaderIcon width={20} height={20} fill={COLOR_WHITE} />
        )}
      </IconContainer>
      {messageCount && (
        <Text bold small>
          +{messageCount - 1}
        </Text>
      )}
    </div>
  )
}

const RefObjectLayer = ({
  messages = [],
  readers,
  onClick,
  selectedMessageId,
}) => {
  return (
    <ItemsLayer
      selectedMessageId={selectedMessageId}
      maxClusterRadius={10}
      renderClusterIcon={cluster => {
        const allMessageData = cluster
          .getAllChildMarkers()
          .map(marker => marker.options.data)
          .sort((a, b) =>
            a.message.payload.time > b.message.payload.time ? -1 : 1
          )
        const latestMessageData = allMessageData[0]

        return (
          <Icon
            messageCount={cluster.getChildCount()}
            {...latestMessageData}
            glow={
              // TODO: Make sure it rerenders like a react component
              allMessageData.some(
                ({ message }) => message.messageId === selectedMessageId
              )
            }
          />
        )
      }}
    >
      {messages?.map((message, index) => {
        const reader = readers[message.payload.at]
        const nextMessage = messages?.[index - 1]
        const nextReader = nextMessage && readers[nextMessage.payload.at]

        if (!reader?.Latitude || !reader?.Longitude) {
          return null
        }

        return (
          <Marker
            key={index}
            position={[reader.Latitude, reader.Longitude]}
            icon={
              <Icon
                reader={reader}
                nextReader={
                  nextReader?.Latitude && nextReader?.Longitude && nextReader
                }
                index={index}
                glow={selectedMessageId === message.messageId}
              />
            }
            eventHandlers={{
              click: () => onClick(message),
            }}
            zIndex={messages.length - index}
            data={{ reader, nextReader, message }}
          />
        )
      })}
    </ItemsLayer>
  )
}

export default RefObjectLayer
