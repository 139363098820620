import { Train } from "../../../types/trainModel"

export type TimeSequence = "arrivals" | "departures"

const markedAsDone = (train: Train, timeSequence: TimeSequence): boolean =>
  !!train?.events?.find(
    event =>
      event.state ===
      (timeSequence === "arrivals"
        ? "train_arrival_to_depot_confirmed"
        : "train_ready_for_departure_from_depot_confirmed")
  )

export const markedAsDoneBy = (train: Train, timeSequence: TimeSequence) => {
  const doneEvent = train.events?.find(
    event =>
      event.state ===
      (timeSequence === "arrivals"
        ? "train_arrival_to_depot_confirmed"
        : "train_ready_for_departure_from_depot_confirmed")
  )

  return {
    reportedBy: doneEvent?.updatedBy,
    reportedAt: doneEvent?.updatedAt,
  }
}

export default markedAsDone
