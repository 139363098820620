import { styled } from "@mui/material"
import { secondsToMinutes } from "date-fns"
import React from "react"
import { useStationKpiSummary } from "../hooks/kpis"
import DataWrapper from "./data_wrapper"
import KeyValueDisplay from "./key_value_display"
import Text from "./text"

const Container = styled("div")`
  padding: 0 1em;
`

export default function KpiText({
  locationId = "Cst",
  filters,
  startTime,
  endTime,
  disableQuery,
}) {
  const { KPIs, isLoading, error } = useStationKpiSummary(
    locationId,
    startTime,
    endTime,
    filters,
    disableQuery
  )

  const labels = {
    number_of_arrivals: "Tåg ank.",
    number_of_departures: "Tåg avg.",
    number_of_unique_trains: "Tot. Tåg",
    number_of_even: "Jämna tåg",
    number_of_odd: "Udda tåg",
    time_keeping_planned_vs_actual: "Ø Punktlighet",
    time_keeping_actual_vs_estimated: "Ø Beräkn. puntklighet",
    time_keeping_estimated_vs_planned: "Ø Beräkn. försening",
    number_of_cancelled: "Inställda",
    number_of_unique_operators: "Operatörer",
    total_delays: "Tot. försening",
    total_estimated_vs_planned_delays: "Tot. beräkn. försening",
    total_actual_vs_estimated_delays: "Tot. beräkn. puntklighet",
  }

  return (
    <Container>
      <DataWrapper isLoading={isLoading} error={error}>
        <Text size="small">Tid i minuter</Text>
        <KeyValueDisplay
          items={{
            ...KPIs,
            time_keeping_planned_vs_actual: secondsToMinutes(
              KPIs.time_keeping_planned_vs_actual
            ).toFixed(0),
            time_keeping_actual_vs_estimated: secondsToMinutes(
              KPIs.time_keeping_actual_vs_estimated
            ),
            time_keeping_estimated_vs_planned: secondsToMinutes(
              KPIs.time_keeping_estimated_vs_planned
            ),
            total_delays: secondsToMinutes(KPIs.total_delays),
            total_estimated_vs_planned_delays: secondsToMinutes(
              KPIs.total_estimated_vs_planned_delays
            ),
            total_actual_vs_estimated_delays: secondsToMinutes(
              KPIs.total_actual_vs_estimated_delays
            ),
          }}
          labels={labels}
        />
      </DataWrapper>
    </Container>
  )
}
