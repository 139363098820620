import { CircularProgress, styled } from "@mui/material"
import Error from "./error"
import React from "react"
import FlexDiv from "./flex_div"

const Container = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`

const FullSizeWrapper = styled(FlexDiv, { shouldForwardProp: false })`
  position: ${({ overlayChildren }) =>
    overlayChildren ? "absolute" : "relative"};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
  justify-content: center;
`

export default function DataWrapper({
  isLoading,
  error = false,
  children,
  overlayChildren = true,
  renderChildrenWhenLoading = true,
}) {
  return (
    <Container>
      {isLoading && (
        <FullSizeWrapper overlayChildren={overlayChildren}>
          <CircularProgress />
        </FullSizeWrapper>
      )}
      {error && (
        <FullSizeWrapper overlayChildren={overlayChildren}>
          <Error error={error} />
        </FullSizeWrapper>
      )}
      {overlayChildren && (renderChildrenWhenLoading || !isLoading) && children}
    </Container>
  )
}
