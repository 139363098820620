import React, { useEffect, useState } from "react"
import axios from "axios"
import Text from "../text"
import FinTimeTable from "./fin_time_table"

export default function FinTrainInfo({ trainNumber, departureDate }) {
  const [trainObj, setTrainObj] = useState({
    timeTableRows: [],
  })

  useEffect(() => {
    axios
      .get(
        `https://rata.digitraffic.fi/api/v1/trains/${departureDate}/${trainNumber}?version=0`
      )
      .then(function (response) {
        // console.log("DigiTrain", response.data[0])
        setTrainObj(response.data[0])
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [trainNumber, trainObj])

  return (
    <div>
      <Text bold="true">Detaljerad information</Text>
      <Text>Cancelled:{trainObj.cancelled ? " true" : " false"}</Text>
      <Text>CommuterLineID:{trainObj.commuterLineID}</Text>
      <Text>OperatorShortCode:{trainObj.operatorShortCode}</Text>
      <Text>OperatorUICCode:{trainObj.operatorUICCode}</Text>
      <Text>
        RunningCurrently:{trainObj.runningCurrently ? " true" : " false"}
      </Text>
      <Text>TimetableAcceptanceDate:{trainObj.timetableAcceptanceDate}</Text>
      <Text>TimetableType:{trainObj.timetableType}</Text>
      <Text>trainCategory:{trainObj.trainCategory}</Text>
      <Text>trainType:{trainObj.trainType}</Text>
      <Text>operatorUICCode:{trainObj.operatorUICCode}</Text>

      <FinTimeTable timeTableList={trainObj.timeTableRows} />
    </div>
  )
}
