import styled from "styled-components"
import { COLOR_PRIMARY, COLOR_SECONDARY } from "../../utils/colors"

const Wrapper = styled.p`
  font-size: ${({ small }) => (small ? "0.8rem" : "1rem")};
  margin: ${({ noMargin }) => (noMargin ? "0" : "0.2em")};
  color: ${({ light }) => (light ? COLOR_SECONDARY : COLOR_PRIMARY)};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
`

const Text = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export default Text
