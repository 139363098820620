import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)``

export default function TimeSequenceSelector({
  timeSequence,
  setTimeSequence,
}) {
  return (
    <StyledToggleButtonGroup
      value={timeSequence}
      color="secondary"
      exclusive
      onChange={(e, timeSequence) => setTimeSequence(timeSequence)}
      size="small"
    >
      <ToggleButton value="arrivals">Ankomster</ToggleButton>
      <ToggleButton value="departures">Avgångar</ToggleButton>
    </StyledToggleButtonGroup>
  )
}
