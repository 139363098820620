import React from "react"
import { Typography, IconButton, Popover } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"

export default function LocationInfo({ locationName, locationID }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div>
      <Typography variant="h5" color="text.primary" align="center">
        <IconButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        >
          <InfoIcon />
        </IconButton>{" "}
        RFID läsare
        <br /> <b>{locationName}</b>
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="body1" sx={{ padding: "0.5rem" }}>
          Plats ID: {locationID}
        </Typography>
      </Popover>
    </div>
  )
}
