import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";
import Task from "./event_task";
import { Droppable } from "react-beautiful-dnd";

const ColumnBox = styled(Box)(() => ({
  width: "98%",
  borderRadius: "0.5rem",
  padding: "8px",
  border: "0px solid",
  minHeight: "50vh",
  height: "100%",
}));

export default function EventColumn(props) {
  return (
    <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "2rem" }}>
      <h2 style={{margin: '0px'}} >{props.column.title}</h2>
      <Droppable droppableId={props.column.id}>
        {(provided, snapshot) => (
          <ColumnBox
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={snapshot.isDraggingOver ? { backgroundColor: "#c7c7c7cf" } : {}}
          >
            {props.tasks.map((task, index) => {
              return (
                <Task
                  key={task.id}
                  task={task}
                  index={index}
                  columnId={props.column.id}
                  boardDataFull={props.boardDataFull}
                />
              );
            })}
            {provided.placeholder}
          </ColumnBox>
        )}
      </Droppable>
    </Grid>
  );
}
