import DetailsBase from "./DetailsBase"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchReferenceObjects } from "../../actions/referenceObjects"
import RefObjectListItem from "../List/RefObjectListItem"
import { sortByLatestMessageTime } from "../../utils/dataUtils"
import { useAuthDispatch } from "../../../../hooks/rfidBackend"

const RefObjectDetails = ({ searchQuery = "", setSearchQuery }) => {
  const dispatch = useAuthDispatch()

  const visibleReaderIds = useSelector(state => state.readers.visibleIds)

  const refObjects = useSelector(state =>
    Object.values(state.referenceObjects.byId)
  )
    .filter(refObject => {
      const matchesSearch = refObject.refObjectId.includes(searchQuery)

      return (
        (visibleReaderIds.includes(refObject.latestMessage.payload.at) &&
          matchesSearch) ||
        (searchQuery.length > 0 && matchesSearch)
      )
    })
    .sort(sortByLatestMessageTime)
    .slice(0, 40)

  const { totalCount, error, isLoading } = useSelector(
    state => state.referenceObjects
  )

  useEffect(() => {
    dispatch(fetchReferenceObjects(searchQuery))
  }, [searchQuery, dispatch])

  return (
    <DetailsBase
      items={refObjects}
      ItemComponent={RefObjectListItem}
      keyProp={refObj => refObj.latestMessage.messageId}
      searchPlaceholder="Search by wagon ID"
      searchQuery={searchQuery}
      totalItemCount={totalCount}
      onQueryChange={setSearchQuery}
      error={error}
      isLoading={isLoading}
    />
  )
}

export default RefObjectDetails
