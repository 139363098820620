import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { userState } from "../recoil/atoms"
import { useBackend } from "./backend"

export const useLogin = () => {
  const [{ data, isLoading, status }, login] = useBackend()
  const [{ data: userData, status: userStatus }, fetchUser] = useBackend()
  const [user, setUser] = useRecoilState(userState)
  const [{ username = "", password = "", remember = false }, setCredentials] =
    useState({})

  useEffect(() => {
    if (username && password) {
      const body = `username=${encodeURI(username)}&password=${password}`

      const config = {
        body,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }

      setUser(user => ({ ...user, isLoading: true, error: null }))

      login("/token", config)
    }
  }, [username, password, remember, login, setUser])

  useEffect(() => {
    if (data?.access_token) {
      fetchUser("/me", {
        headers: { Authorization: `Bearer ${data?.access_token}` },
      })
    }
  }, [fetchUser, data])

  useEffect(() => {
    if (userData) {
      const {
        username: actualUsername,
        role,
        default_station: defaultStation = "CST",
      } = userData

      setUser({
        username: actualUsername,
        role,
        defaultStation,
        accessToken: data?.access_token,
        isLoading: false,
        error: null,
        remember,
      })
    }
  }, [userData, setUser, data, remember])

  useEffect(() => {
    if (status === 401) {
      setUser(prev => ({
        ...prev,
        error: new Error("Felaktig e-post eller lösenord"),
        isLoading: false,
      }))
    } else if (
      (status && status !== 200) ||
      (userStatus && userStatus !== 200)
    ) {
      setUser(prev => ({
        ...prev,
        error: new Error("Något gick fel - status " + (userStatus || status)),
        isLoading: false,
      }))
    }
  }, [status, setUser, userStatus])

  return [
    user,
    useCallback(
      (username, password, remember) =>
        setCredentials({ username, password, remember }),
      [setCredentials]
    ),
  ]
}

export const useLogout = () => {
  const user = useRecoilValue(userState)
  const history = useHistory()

  useEffect(() => {
    if (!user.username) {
      history.replace("/")
    }
  }, [user, history])

  return useResetRecoilState(userState)
}
