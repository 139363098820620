import { HeatmapLayer } from "@deck.gl/aggregation-layers"
import { TileLayer } from "@deck.gl/geo-layers"
import { BitmapLayer, IconLayer, ScatterplotLayer } from "@deck.gl/layers"
import DeckGL from "@deck.gl/react"
import mapboxgl from "mapbox-gl"
import React, { useState } from "react"
// Deck GL
import { StaticMap } from "react-map-gl"
// Recoil
import { useRecoilValue } from "recoil"
import useDigitraffic from "../../hooks/digitraffic"
import {
  useTrainLocations,
  useTrainNetworkStatistics
} from "../../hooks/overview"
import PicRFID from "../../resources/rfid.png"
// Pic & Icons
import TrainIcon from "../../resources/train.png"
import PicRailwayAnimalGreen from "./svg/RailwayAnimalOnTrackGreen.png"
import PicRailwayAnimalOrange from "./svg/RailwayAnimalOnTrackOrange.png"
import PicRailwayAnimalRed from "./svg/RailwayAnimalOnTrackRed.png"
import PicRailwayNoTrainGreen from "./svg/RailwayNoTrainGreen.png"
import PicRailwayNoTrainOrange from "./svg/RailwayNoTrainOrange.png"
import PicRailwayNoTrainRed from "./svg/RailwayNoTrainRed.png"
import PicRailwayOtherGreen from "./svg/RailwayOtherGreen.png"
import PicRailwayOtherOrange from "./svg/RailwayOtherOrange.png"
import PicRailwayOtherRed from "./svg/RailwayOtherRed.png"
import PicRailwayTrackGreen from "./svg/RailwayTrackGreen.png"
import PicRailwayTrackOrange from "./svg/RailwayTrackOrange.png"
import PicRailwayTrackRed from "./svg/RailwayTrackRed.png"
import PicRailwayTrainPosition from "./svg/RailwayTrainPositionWhite.png"
// Components
import { layersSelected } from "./mapToggleLayers"
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const picEventLayout = { width: 673, height: 673, anchorY: 0 }

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoicG9udHVzLXJpc2UiLCJhIjoiY2xlb2Q4cm5iMDE4dDN6bzUzcHdvZTloeCJ9.ZwLUzI_XAq_yxq6ckovoIQ"

function getTooltip({ object }) {
  return (
    object &&
    `\
    ${
      object.Name ||
      object.Header ||
      object.operator ||
      object.properties.trainNumber
    }
  `
  )
}

export default function MapBox(props) {
  const trainLocations = useTrainLocations()
  const digitrafficTrainPos = useDigitraffic()

  const trainNetworkStatistics = useTrainNetworkStatistics()

  let activeMapLayers = useRecoilValue(layersSelected)

  const getMapObj = info => {
    // const { object, x, y } = info;
    props.setObj(info)
  }

  // State of the map
  const [viewstate, setViewState] = useState({
    latitude: 61,
    longitude: 16,
    zoom: 5,
    pitch: 0,
  })

  // -----------------------------------------------------------------
  // Layers

  // Railway Map
  const layerBitMapOpenRailway = new TileLayer({
    data: "https://tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png",
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    visible: activeMapLayers.railwayMap,
    renderSubLayers: props => {
      const {
        bbox: { west, south, east, north },
      } = props.tile
      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      })
    },
  })

  // Station locations
  const layerStationTimeKeepingScatter = new ScatterplotLayer({
    id: "stationStatistics",
    data: trainNetworkStatistics?.stationAgg,
    pickable: true,
    opacity: 0.7,
    stroked: false,
    filled: true,
    radiusUnits: "meters",
    radiusMinPixels: 3,
    radiusMaxPixels: 50,
    getPosition: d => [d.Longitude, d.Latitude],
    getRadius: d => 150,
    getFillColor: d => [14, 11, 152],
    visible: activeMapLayers.stations,
  })

  // RFID locations
  const layerRFIDReaders = new IconLayer({
    id: "rfid-readers",
    data: props.rfidReaders,
    pickable: true,
    getIcon: d => {
      return {
        url: PicRFID,
        width: 400,
        height: 400,
        anchorY: 0,
      }
    },
    sizeUnits: "common",
    sizeMinPixels: 10,
    sizeMaxPixels: 25,
    getPosition: d => [parseFloat(d.Longitude), parseFloat(d.Latitude)],
    getSize: d => 0.4,
    visible: activeMapLayers.rfidReaders,
  })

  // Station total delay estimated heatmap
  const layerStationTimeKeepingDelay = new HeatmapLayer({
    id: "heatmap-stationStatistics-delay",
    data: trainNetworkStatistics?.stationAgg,
    getPosition: d => [d.Longitude, d.Latitude],
    getWeight: d => d.total_delay_estimation,
    aggregation: "SUM",
    visible: activeMapLayers.heatmapDelay,
  })

  // Station total actual estimated heatmap
  const layerStationTimeKeepingActual = new HeatmapLayer({
    id: "heatmap-stationStatistics-actual",
    data: trainNetworkStatistics?.stationAgg,
    getPosition: d => [d.Longitude, d.Latitude],
    getWeight: d => d.timekeeping_actual,
    aggregation: "SUM",
    // intensity: 0.5,
    visible: activeMapLayers.heatmapActual,
  })

  // Train station messages
  const layerTrainStationMessages = new IconLayer({
    id: "train-message-layer",
    data: props.trainMessage,
    pickable: true,
    getIcon: d => {
      if (d.ReasonCode[0].Description === "banarbete") {
        if (d.TrafficImpact.length < 2) {
          return {
            url: PicRailwayTrackGreen,
            ...picEventLayout,
          }
        } else if (d.TrafficImpact.length < 6) {
          return {
            url: PicRailwayTrackOrange,
            ...picEventLayout,
          }
        } else {
          return {
            url: PicRailwayTrackRed,
            ...picEventLayout,
          }
        }
      } else if (d.ReasonCode[0].Description === "djur i spåret") {
        if (d.TrafficImpact.length < 2) {
          return {
            url: PicRailwayAnimalGreen,
            ...picEventLayout,
          }
        } else if (d.TrafficImpact.length < 6) {
          return {
            url: PicRailwayAnimalOrange,
            ...picEventLayout,
          }
        } else {
          return {
            url: PicRailwayAnimalRed,
            ...picEventLayout,
          }
        }
      } else if (d.ReasonCode[0].Description === "sen avgång från bangården") {
        if (d.TrafficImpact.length < 2) {
          return {
            url: PicRailwayNoTrainGreen,
            ...picEventLayout,
          }
        } else if (d.TrafficImpact.length < 6) {
          return {
            url: PicRailwayNoTrainOrange,
            ...picEventLayout,
          }
        } else {
          return {
            url: PicRailwayNoTrainRed,
            ...picEventLayout,
          }
        }
      } else {
        if (d.TrafficImpact.length < 2) {
          return {
            url: PicRailwayOtherGreen,
            width: 673,
            height: 673,
            anchorY: 0,
          }
        } else if (d.TrafficImpact.length < 6) {
          return {
            url: PicRailwayOtherOrange,
            width: 673,
            height: 673,
            anchorY: 0,
          }
        } else {
          return {
            url: PicRailwayOtherRed,
            width: 673,
            height: 673,
            anchorY: 0,
          }
        }
      }
    },
    sizeUnits: "common",
    sizeMinPixels: 30,
    sizeMaxPixels: 30,

    getPosition: d => {
      return [
        parseFloat(
          d.Geometry.WGS84.replaceAll("(", "").replaceAll(")", "").split(" ")[1]
        ),
        parseFloat(
          d.Geometry.WGS84.replaceAll("(", "").replaceAll(")", "").split(" ")[2]
        ),
      ]
    },
    getSize: d => 0.4,
    visible: activeMapLayers.trainMessages,
  })

  // Train locations 1409
  const layerTrainLocations = new IconLayer({
    id: "train-locations-layer",
    data: trainLocations,
    pickable: true,
    getIcon: d => {
      return {
        url: TrainIcon,
        width: 400,
        height: 100,
        anchorY: 0,
      }
    },
    sizeUnits: "common",
    sizeMinPixels: 4,
    sizeMaxPixels: 10,
    getPosition: d => [d.lng, d.lat],
    getAngle: d => -d.bearing - 90,
    visible: activeMapLayers.trains,
  })

  // Train locations Digitraffic
  const layerTrainLocationsFin = new IconLayer({
    id: "train-locations-fin-layer",
    data: digitrafficTrainPos.data,
    pickable: true,
    getIcon: d => {
      return {
        url: PicRailwayTrainPosition,
        width: 466,
        height: 643,
        anchorY: 643,
      }
    },
    sizeUnits: "common",
    sizeMinPixels: 30,
    sizeMaxPixels: 30,
    getPosition: d => {
      return d.geometry.coordinates
    },

    visible: activeMapLayers.trains,
  })

  return (
    <DeckGL
      layers={[
        layerBitMapOpenRailway,
        layerStationTimeKeepingDelay,
        layerStationTimeKeepingActual,
        layerStationTimeKeepingScatter,
        layerRFIDReaders,
        layerTrainLocationsFin,
        layerTrainLocations,
        layerTrainStationMessages,
      ]}
      viewState={viewstate}
      onViewStateChange={e => setViewState(e.viewState)}
      controller={{ dragPan: true }}
      getTooltip={getTooltip}
      onClick={getMapObj}
    >
      <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
    </DeckGL>
  )
}
