import WarningIcon from "@mui/icons-material/PriorityHigh"
// import { TimelineDot } from "@mui/lab"
import { Collapse } from "@mui/material"
import { styled } from "@mui/system"
import FlexDiv from "../../components/flex_div"
import Text from "../../components/text"
import Time from "../../components/time"
import { formatUrn } from "../../utils/dataFormatting"

const Container = styled("div")`
  // TODO: Possibly make width based on length

  background-color: ${({ theme }) => theme.palette.greyLight.main};
  //border-radius: 3px;
  position: relative;
  border: solid white;
  border-width: 5px 0 5px 5px;
  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
    border-width: 5px 5px 5px 5px;
  }
`

const WarningIndicator = styled(WarningIcon)`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  z-index: 10;
  fill: ${({ theme }) => theme.palette.warning.main};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`

const WagonNumberText = styled(Text)`
  padding: 0 0.8em;
  background-color: ${({ theme }) => theme.palette.background.default};
  flex-grow: 1;
`

const WagonInfoContainer = styled(FlexDiv)`
  padding: 0 0.2em;
  border-top: 5px solid white;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
`
const WagonInfoRowContainer = styled("div")`
  display: flex;
  width: 100%;
  justify-content: ${({ $hasLabel }) =>
    $hasLabel ? "space-between" : "flex-end"};
  margin: ${({ $horizontalSpacing }) => ($horizontalSpacing ? "1em" : "0")} 0;
`

const TrainInfoContainer = styled(FlexDiv)`
  background-color: #fff;
  padding: 0 0.2em;
`

const TrainNumberText = styled(Text)`
  padding-right: 0.5em;
`

const LabelText = styled(Text)`
  padding-right: 0.5em;
`

const WagonInfoRow = ({ label, horizontalSpacing, children }) => (
  <WagonInfoRowContainer
    $hasLabel={!!label}
    $horizontalSpacing={horizontalSpacing}
  >
    {label && (
      <LabelText size="small" bold>
        {label}
      </LabelText>
    )}
    {children}
  </WagonInfoRowContainer>
)

const WagonInfo = ({ wagon, showLabels }) => (
  <WagonInfoContainer>
    <WagonInfoRow label={showLabels && "Ank. TNR"}>
      <Text right size="small">
        {wagon.arrivalEvent?.technicalTrainNumber || "-"}
      </Text>
    </WagonInfoRow>
    <WagonInfoRow label={showLabels && "Ank. tid"}>
      <Time
        right
        bold
        planned={wagon.arrivalEvent?.plannedTime}
        estimated={wagon.arrivalEvent?.estimatedTime}
        actual={wagon.arrivalEvent?.actualTime}
        noWrap
      />
    </WagonInfoRow>
    {/* <WagonInfoRow label={showLabels && "Åtgärder"} horizontalSpacing>
      <WagonTimeline />
    </WagonInfoRow> */}
    <WagonInfoRow label={showLabels && "Avg. tid"}>
      <Time
        planned={wagon.departureEvent?.plannedTime}
        estimated={wagon.departureEvent?.estimatedTime}
        right
        bold
        noWrap
      />
    </WagonInfoRow>
  </WagonInfoContainer>
)

const TrainInfo = ({ train }) => (
  <TrainInfoContainer>
    <TrainNumberText noWrap size="small" bold>
      {train.technicalTrainNumber}
    </TrainNumberText>
    <Time
      noWrap
      planned={train.departureEvent?.plannedTime}
      estimated={train.departureEvent?.estimatedTime}
    />
  </TrainInfoContainer>
)

export default function Wagon({ wagon, showLabels, train, showInfo }) {
  return (
    <Container>
      <FlexDiv>
        {train && showLabels && <TrainInfo train={train} />}
        <WagonNumberText center bold size="small">
          {formatUrn(wagon.wagonId)}
        </WagonNumberText>
      </FlexDiv>
      {wagon.warning && <WarningIndicator />}
      <Collapse in={showInfo}>
        <WagonInfo wagon={wagon} showLabels={showLabels} />
      </Collapse>
    </Container>
  )
}
