import { TimelineDot } from "@mui/lab"
import { styled } from "@mui/material"
import FlexDiv from "../../components/flex_div"
import Text from "../../components/text"

const dotColors = {
  Planned: "info",
  Estimated: "warning",
  Actual: "success",
}

const Row = styled(FlexDiv)`
  justify-content: flex-end;
`

const StyledDot = styled(TimelineDot)`
  margin: 3px 0.5em 0 0.5em;
`

const Container = styled(FlexDiv)`
  flex-direction: column;
`

// 100% hardcoded
export default function WagonTimeline({ events = [] }) {
  return (
    <Container>
      <Row>
        <Text size="small">S</Text>
        <StyledDot variant="filled" color={dotColors["Actual"]} />
        <Text size="small">-</Text>
      </Row>
      <Row>
        <Text size="small">F</Text>
        <StyledDot variant="outlined" color={dotColors["Estimated"]} />
        <Text size="small">-</Text>
      </Row>
      <Row>
        <Text size="small">U</Text>
        <StyledDot variant="outlined" color={dotColors["Planned"]} />
        <Text size="small">-</Text>
      </Row>
    </Container>
  )
}
