import React, { useState } from "react"
import {
  Grid,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
} from "@mui/material"
// Recoil
import { atom } from "recoil"
import { useRecoilState } from "recoil"
import AutoGrid from "./auto_grid"
import { useTranslation } from "react-i18next"

// -----------------------------------
// Atoms
export const layersSelected = atom({
  key: "layersSelected",
  default: {
    railwayMap: true,
    heatmapActual: false,
    heatmapDelay: true,
    stations: true,
    trainMessages: true,
    trains: false,
    rfidReaders: true,
  },
})

export default function MapToggleLayers() {
  const [layersOnMap, setLayersOnMap] = useRecoilState(layersSelected)
  const [SelectedHeatmap, setSelectedHeatmap] = useState("delay")
  const { t } = useTranslation()

  const toggleStations = () => {
    setLayersOnMap({
      ...layersOnMap,
      stations: !layersOnMap.stations,
    })
  }

  const toggleRfidReaders = () => {
    setLayersOnMap({
      ...layersOnMap,
      rfidReaders: !layersOnMap.rfidReaders,
    })
  }

  const toggleTrainMessages = () => {
    setLayersOnMap({
      ...layersOnMap,
      trainMessages: !layersOnMap.trainMessages,
    })
  }

  const toggleRailwayMap = () => {
    setLayersOnMap({
      ...layersOnMap,
      railwayMap: !layersOnMap.railwayMap,
    })
  }

  const toggleTrains = () => {
    setLayersOnMap({
      ...layersOnMap,
      trains: !layersOnMap.trains,
    })
  }

  const handleHeatmapSelection = (event, newAlignment) => {
    if (newAlignment === "delay") {
      setLayersOnMap({
        ...layersOnMap,
        heatmapDelay: true,
        heatmapActual: false,
      })
    } else if (newAlignment === "actual") {
      setLayersOnMap({
        ...layersOnMap,
        heatmapDelay: false,
        heatmapActual: true,
      })
    } else {
      setLayersOnMap({
        ...layersOnMap,
        heatmapDelay: false,
        heatmapActual: false,
      })
    }
    setSelectedHeatmap(newAlignment)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <ToggleButtonGroup
          value={SelectedHeatmap}
          exclusive
          onChange={handleHeatmapSelection}
          color="secondary"
        >
          <ToggleButton value="delay" size="small">
            {t("railroadOverview.reportedDelays")}
          </ToggleButton>
          <ToggleButton value="actual" size="small">
            {t("railroadOverview.actualDelays")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              checked={layersOnMap.stations}
              onChange={toggleStations}
              color="secondary"
            />
          }
          label={t("generalTrain.stations")}
          labelPlacement="start"
        />

        <FormControlLabel
          control={
            <Switch
              checked={layersOnMap.rfidReaders}
              onChange={toggleRfidReaders}
              color="secondary"
            />
          }
          label={t("railroadOverview.rfidReaders")}
          labelPlacement="start"
        />

        <FormControlLabel
          control={
            <Switch
              checked={layersOnMap.trainMessages}
              onChange={toggleTrainMessages}
              color="secondary"
            />
          }
          label={t("railroadOverview.reportedEvents")}
          labelPlacement="start"
        />

        <FormControlLabel
          control={
            <Switch
              checked={layersOnMap.railwayMap}
              onChange={toggleRailwayMap}
              color="secondary"
            />
          }
          label={t("generalTrain.rail")}
          labelPlacement="start"
        />

        <FormControlLabel
          control={
            <Switch
              checked={layersOnMap.trains}
              onChange={toggleTrains}
              color="secondary"
            />
          }
          label={t("generalTrain.trains")}
          labelPlacement="start"
        />
      </Grid>
    </Grid>
  )
}
