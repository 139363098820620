import React, { useState } from "react"
import { Tabs, Tab, Box } from "@mui/material"

type Props = {
  setShowAllStationsAffected: (affected: boolean) => void
}

export default function FilterSelector(props: Props) {
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event: any, tabNumber: any) => {
    props.setShowAllStationsAffected(tabNumber === 1)
    setTabValue(tabNumber)
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab label="Aktiv station" />
        <Tab label="Alla stationer" />
      </Tabs>
    </Box>
  )
}
