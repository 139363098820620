import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, Stack } from "@mui/material";
import Task from "./track_layout_space";
import { Droppable } from "react-beautiful-dnd";

const GridCenter = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default function EventColumn(props) {
  return (
    <>
      <GridCenter item xs={1}>
        <Stack>
          <Typography variant={"h4"} align="center">
            {props.column.title}
          </Typography>
          <Typography variant={"body"} align="center">
            <b>{props.column.taskIds.length * 17}m</b> av {props.column.capacity}m
          </Typography>
        </Stack>
      </GridCenter>

      <Grid item xs={11}>
        <Droppable droppableId={props.column.id} direction="horizontal">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={
                snapshot.isDraggingOver
                  ? {
                      padding: "8px",
                      height: "11rem",
                      backgroundColor: "#24799e",
                      display: "flex",
                      margin: "1px",
                    }
                  : {
                      border: "2px",
                      borderStyle: "solid hidden solid hidden",
                      padding: "8px",
                      height: "11rem",
                      display: "flex",
                      margin: "1px",
                    }
              }
            >
              {props.tasks.map((task, index) => {
                return <Task key={task.id} task={task} index={index} />;
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Grid>
    </>
  );
}
