import React from "react"
import Box from "@mui/material/Box"
import { styled } from "@mui/material"

const Container = styled("div")`
  overflow-y: scroll;
`

export default function TabPanel({ children, value, index, ...other }) {
  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Container>
  )
}
