import { IconButton } from "@mui/material"
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded"
import { useSetRecoilState } from "recoil"
import { formDialogState, rcmfSubmissionState } from "../recoil/atoms"

export default function SubmitMessageButton({ train, track, station, state }) {
  const setForm = useSetRecoilState(rcmfSubmissionState)
  const setFormDialog = useSetRecoilState(formDialogState)

  return (
    <IconButton
      sx={{ padding: 0 }}
      size="small"
      key={train.trainId}
      onClick={e => {
        e.stopPropagation()
        setForm(prev => ({
          ...prev,
          activeStationObj: station,
          activeOperator: train.operator,
          activeTrainNumber:
            train.technicalTrainNumber || train.advertisedTrainNumber,
          activeTrainWagonID: null,
          activeTrainTrack:
            track || (train.arrivalEvent || train.departureEvent)?.track,
          activeTimeType:
            (train.arrivalEvent || train.departureEvent)?.latestTimeType ||
            "Actual",
          activeState: state,
        }))

        setFormDialog(prev => ({
          ...prev,
          isOpen: true,
        }))
      }}
    >
      <MoreTimeRoundedIcon />
    </IconButton>
  )
}
