import InfoIcon from "@mui/icons-material/Info"
import { styled, Tooltip } from "@mui/material"

const Icon = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  margin: 0.1em;
  color: ${({ theme }) => theme.palette.greyDark.main};
`

export default function InfoTooltip({ text }) {
  return (
    <Tooltip disableFocusListener title={text} placement="top" arrow>
      <Icon />
    </Tooltip>
  )
}
