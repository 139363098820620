/*
    Event report button 
    ----------------------------
     
*/

import React from "react"
import { Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useRecoilState } from "recoil"
import { formDialogState } from "../../recoil/atoms"

export default function ReportButton() {
  const [formDialog, setFormDialog] = useRecoilState(formDialogState)

  return (
    <>
      <Fab
        sx={{
          backgroundColor: "secondary.main",
          color: "#fff",
          margin: "0 0.5em",
        }}
        onClick={() =>
          setFormDialog({
            ...formDialog,
            isOpen: true,
          })
        }
      >
        <AddIcon fontSize="large" />
      </Fab>
    </>
  )
}
