import { Tooltip, useTheme } from "@mui/material"
import { useRouteMatch } from "react-router-dom"
import SubmitMessageButton from "../../components/submit_msg_button"
import SwedishMaterialTable from "../../components/SweMaterialTable"
import Text from "../../components/text"
import { useUserHistory } from "../../hooks/history"
import { useStations } from "../../hooks/staticData"
import { formatEpochDateTime } from "../../utils/dataFormatting"

const Location = ({ stationId, stations }) => (
  <div>
    <Tooltip
      title={stations[stationId]?.name}
      placement="top"
      arrow
      disableFocusListener
    >
      <Text size="small">{stationId}</Text>
    </Tooltip>
  </div>
)

export default function TrainList({ trains = [], isLoading }) {
  const history = useUserHistory()
  const { page, trainId } = useRouteMatch("/:page/:trainId?")?.params || {}
  const theme = useTheme()
  const { stations } = useStations()

  const columns = [
    { render: train => <SubmitMessageButton train={train} station={null} /> },
    {
      title: "Tåg-ID",
      field: "technicalTrainNumber",
      render: ({ technicalTrainNumber, connectedTrainNumbers = [] }) => (
        <div>
          <Text size="small">{technicalTrainNumber}</Text>
          {connectedTrainNumbers?.length > 0 && (
            <Text size="tiny">
              ({connectedTrainNumbers.map(number => number.split(".")[1])})
            </Text>
          )}
        </div>
      ),
    },
    {
      title: "Annons. tågnr",
      field: "advertised_train_number",
      render: ({ advertisedTrainNumber }) => (
        <Text size="small">{advertisedTrainNumber}</Text>
      ),
    },
    {
      title: "Operatör",
      field: "operator",
      render: ({ operator }) => <Text size="small">{operator}</Text>,
    },
    {
      title: "Från",
      render: ({ events }) => (
        <Location
          stations={stations}
          stationId={events.filter(e => !!e.location)?.reverse()[0]?.location}
        />
      ),
    },
    {
      title: "Till",
      render: ({ events }) => (
        <Location
          stations={stations}
          stationId={events.filter(e => !!e.location)[0]?.location}
        />
      ),
    },
    {
      title: "Senast",
      render: ({ events }) => {
        return (
          <Location
            stations={stations}
            stationId={
              events.filter(
                e => !!e.location && e.latestTimeType === "Actual"
              )[0]?.location
            }
          />
        )
      },
    },
    {
      title: "Uppdaterad",
      field: "updated_at",
      render: ({ updatedAt }) => (
        <Text size="small">{formatEpochDateTime(updatedAt)}</Text>
      ),
    },
  ]
  return (
    <SwedishMaterialTable
      columns={columns}
      data={trains || []}
      isLoading={isLoading}
      components={{ Container: props => <div {...props} /> }}
      options={{
        search: false,
        padding: "dense",
        pageSize: 20,
        showTitle: false,
        paging: false,
        toolbar: false,
        rowStyle: ({ trainId: rowTrainId }) => {
          return {
            backgroundColor:
              rowTrainId === trainId
                ? theme.palette.tertiary.main
                : "transparent",
          }
        },
      }}
      onRowClick={(event, train) => history.push(`/${page}/${train.trainId}`)}
    />
  )
}
