/*
  Navigation bar 
  ------------------
  Global component called in App.jsx 
  - Logo
  - Tab links to main views
  - Dark/Light theme (Not implemented ) 
  - Menu button opener   
*/

import DirectionsSubwayFilledIcon from "@mui/icons-material/DirectionsSubwayFilled"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import HomeIcon from "@mui/icons-material/Home"
import MapIcon from "@mui/icons-material/Map"
import MenuIcon from "@mui/icons-material/Menu"
import SubwayIcon from "@mui/icons-material/Subway"
import TrainIcon from "@mui/icons-material/Train"
import SAPIcon from "@mui/icons-material/DensitySmall"
import RFIDReaderIcon from "@mui/icons-material/SettingsRemote"
import PlankIcon from "@mui/icons-material/GridOn"
import { getTitle } from "../../utils/domain"

import {
  AppBar,
  Avatar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material"
// Material UI
import { styled, useTheme } from "@mui/material/styles"
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { useLogout } from "../../hooks/user"
import { appDataState, userState } from "../../recoil/atoms"
import ROUTES from "../../ROUTES.json"
import { useTranslation } from "react-i18next"
import SystemStatus from "../../components/system_status"
import GridCenter from "../../components/grid_center"

const StyledTabs = styled(Tabs)`
  color: ${({ theme }) => theme.palette.grey.main};

  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.greyLight.main};
  }
`

const StyledTab = styled(Tab)`
  color: rgba(217, 217, 217, 0.84);
  padding: 0 2em;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.greyLight.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`

export default function NavigationBar() {
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up("lg"))
  const { t } = useTranslation()
  const user = useRecoilValue(userState)
  const logout = useLogout()
  //const [themeMode, setThemeMode] = useRecoilState(themeModeState)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const location = useLocation()
  const title = getTitle()

  const navigationItemsBase = [
    {
      label: t("navigation.stationOverview"),
      to: ROUTES.STATION_OVERVIEW,
      Icon: HomeIcon,
    },
    {
      label: t("navigation.trainInfo"),
      to: ROUTES.TRAIN_INFO,
      Icon: DirectionsSubwayFilledIcon,
    },
    {
      label: t("navigation.depotOverview"),
      to: ROUTES.DEPOT_VIEW,
      Icon: SubwayIcon,
    },
    {
      label: t("navigation.sap"),
      to: ROUTES.SAP_VIEW,
      Icon: SAPIcon,
    },
    {
      label: t("navigation.plank"),
      to: ROUTES.PLANK_VIEW,
      Icon: PlankIcon,
    },
    {
      label: t("navigation.rfid"),
      to: ROUTES.RFID,
      Icon: RFIDReaderIcon,
    },
    {
      label: t("navigation.railroadOverview"),
      to: ROUTES.MAP,
      Icon: MapIcon,
    },
  ]

  const navigationItems = title.includes("YardCDM")
    ? [
        navigationItemsBase.find(i => i.to === ROUTES.DEPOT_VIEW),
        ...navigationItemsBase.filter(i => i.to !== ROUTES.DEPOT_VIEW),
      ]
    : navigationItemsBase

  const tabIndexValue = navigationItems.findIndex(({ to }) =>
    location.pathname.startsWith(to)
  )

  const isAuth = !!user.username

  const openDrawer = () => {
    setIsDrawerOpen(true)
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  return (
    <AppBar position="static">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "primary.main" }}
      >
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            textDecoration: "none",
            alignItems: "center",
          }}
          component={Link}
          to={ROUTES.HOME}
        >
          <Typography
            variant="h5"
            sx={{
              margin: "0px",
              color: theme.palette.primary.contrastText,
              "&:avisited": {},
              "&:hover": {
                color: theme.palette.secondary.main,
              },
              display: "flex",
              flexDirection: "row",
              textDecoration: "none",
              alignItems: "center",
            }}
          >
            <TrainIcon
              sx={{
                margin: "0px",
                color: theme.palette.primary.contrastText,
                fontSize: "2.5rem",
                "&:avisited": {},
                "&:hover": {
                  color: theme.palette.secondary.main,
                },
              }}
            />
            {title}
          </Typography>
        </Grid>
        {isAuth ? (
          <>
            {large && (
              <Grid
                item
                md={8}
                sx={{
                  visibility: { md: "visible" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledTabs
                  value={tabIndexValue >= 0 ? tabIndexValue : false}
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {navigationItems.map(({ Icon, ...props }, index) => (
                    <StyledTab component={Link} {...props} key={index} />
                  ))}
                </StyledTabs>
              </Grid>
            )}

            <Grid
              item
              xs={10}
              lg={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <SystemStatus />
              <IconButton edge="start" color="inherit" onClick={openDrawer}>
                <MenuIcon
                  sx={{
                    fontSize: "2rem",
                    marginRight: "0.5rem",
                    color: "#fff",
                  }}
                />
              </IconButton>

              {/* Day and night mode */}
              {/* <IconButton
                edge="start"
                color="inherit"
                onClick={ToggleThemeMode}
                sx={{
                  fontSize: "2rem",
                  marginRight: "1.5rem",
                  color: "#fff",
                }}
              >
                {themeMode === "light" ? (
                  <DarkModeIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                  />
                ) : (
                  <LightModeIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                  />
                )}
              </IconButton> */}
            </Grid>
          </>
        ) : (
          <Grid item></Grid>
        )}
      </Grid>

      <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
        <List sx={{ minWidth: "300px" }}>
          {/* User data */}
          <ListItem alignItems="flex-start" key={"list-1"}>
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={user.username}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {user.userGroup}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider />

          {/* Links  */}
          {navigationItems.map(({ to, label, Icon }, index) => (
            <ListItem button component={Link} to={to} key={"list-1-" + index}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}

          {user.role === "admin" && (
            <>
              <ListItem button component={Link} to={"/analytics"}>
                <ListItemText primary="Analys" />
              </ListItem>
              <ListItem button component={Link} to={"/users"}>
                <ListItemText primary="Användare" />
              </ListItem>
            </>
          )}

          {/* Logout button */}
          <ListItem
            button
            onClick={() => {
              setIsDrawerOpen(false)
              logout()
            }}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon
                sx={{ color: theme.palette.primary.contrastText }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Logga ut"
              sx={{ color: theme.palette.primary.contrastText }}
            />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  )
}
