export const timeSequenceTranslations = {
  arrivalTo: "Ankomst",
  departureFrom: "Avgång",
  passingBy: "Passage",
  commenced: "Påbörjad",
  completed: "Avslutad",
}

export const timeTypeTranslations = {
  Estimated: "Beräknad",
  Planned: "Planerad",
  Actual: "Faktisk",
}

export const timeTypePaletteColors = {
  Planned: "info",
  Estimated: "warning",
  Actual: "actual",
}

export const serviceObjectTranslations = {
  driver_to_train: "Förare på väg mot tåg",
  driver_at_train: "Förare på tåg",
  clearance: "Klargöring",
  catering: "Catering",
  maintenance: "Underhåll",
  cleaning: "Städning",
  build_train_set: "Bygga",
}
