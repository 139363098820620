import { useEffect } from "react"
import { useBackendWithAuth } from "./backend"

type SystemStatus = {
  status: "ok" | "loading" | "stopped"
  etaSeconds: number
}

export const useSystemStatus = (): {
  isLoading: boolean
  error: any
  systemStatus?: SystemStatus
} => {
  const [{ data, isLoading, error }, fetchSystemStatus] = useBackendWithAuth()

  useEffect(() => fetchSystemStatus("/system_status"), [])

  return {
    systemStatus: data
      ? { status: data.system_status, etaSeconds: data.eta_s }
      : undefined,
    isLoading,
    error,
  }
}
