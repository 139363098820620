import CloseIcon from "@mui/icons-material/ArrowLeft"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components"
import itemRoutes from "../../routes.json"
import { COLOR_SECONDARY } from "../../utils/colors"
import Reader from "./Reader"
import RefObject from "./RefObject"
import Train from "./Train"
import ROUTES from "../../../../ROUTES.json"

const Container = styled.div`
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 30px -10px black;
  z-index: 500;
  display: flex;
`

const Button = styled.button`
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0.5em;
  z-index: 600;
  background-color: transparent;
`

const ItemInfo = () => {
  const { itemType } = useRouteMatch("/:itemType/*")?.params || {}
  const history = useHistory()

  return (
    <Container>
      <Button onClick={() => history.push(`/${itemType}`)}>
        <CloseIcon fill={COLOR_SECONDARY} height={40} width={40} />
      </Button>
      <Switch>
        <Route
          path={`${ROUTES.RFID}/${itemRoutes.TRAINS}/:id`}
          render={({ match }) => <Train id={match.params.id} />}
        />
        <Route
          path={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}/:id`}
          render={({ match }) => <RefObject id={match.params.id} />}
        />
        <Route
          path={`${ROUTES.RFID}/${itemRoutes.READERS}/:id`}
          render={({ match }) => <Reader id={match.params.id} />}
        />
      </Switch>
    </Container>
  )
}

export default ItemInfo
