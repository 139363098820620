import {
  FETCH_TRAINS_BEGIN,
  FETCH_TRAINS_ERROR,
  FETCH_TRAINS_SUCCESS,
  FETCH_TRAIN_BEGIN,
  FETCH_TRAIN_ERROR,
  FETCH_TRAIN_SUCCESS,
  MESSAGE_EVENT_RECEIVED,
} from "../actions/types"

const initialState = {
  byId: {},
  totalCount: 0,
  error: null,
  isLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRAINS_BEGIN:
    case FETCH_TRAIN_BEGIN:
      return { ...state, isLoading: true, error: null }
    case FETCH_TRAINS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        byId: action.payload.items.reduce(
          (objects, train) => ({ ...objects, [train.trainId]: train }),
          state.byId
        ),
        totalCount: action.payload.totalCount,
      }
    case FETCH_TRAIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.trainId]: action.payload,
        },
      }
    }
    case FETCH_TRAINS_ERROR:
    case FETCH_TRAIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case MESSAGE_EVENT_RECEIVED: // Update latest message for relevant train
      const trainId = action.payload.train.trainId

      // Remove old property first to put it on top
      const { [trainId]: oldTrain, ...otherTrains } = state.byId

      return {
        ...state,
        byId: {
          [trainId]: action.payload.train,
          ...otherTrains,
        },
      }
    default:
      return state
  }
}

export default reducer
