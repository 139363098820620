import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Grid } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";

const getColor = (value) => {
  let colorSubject = {
    high: "#d2191945",
    medium: "#d2911945",
    low: "#174f7245",
  };

  return colorSubject[value];
};

const TaskBox = styled(Box)(() => ({
  width: "100%",
  borderRadius: "0.5rem",
  border: "0px solid",
  marginTop: "0.5rem",
  padding: "0.5rem",
}));

export default function EventTaskA(props) {

  // Component variables
  const [priorityColor, setPriorityColor] = useState("#fff");

  useEffect(() => {
    let priority_color = getColor(props.task.eventPriority);
    setPriorityColor(priority_color);
  }, [props]);

  const removeTask = async () => {
    let taskObj = props.boardDataFull.tasks;
    delete taskObj[props.task.id];

    let columnListKeys = props.boardDataFull.columns[props.columnId].taskIds;
    let removeID = props.task.id;
    columnListKeys = columnListKeys.filter((e) => e !== removeID);

  };

  return (
    <Draggable key={props.task.id + "t"} draggableId={props.task.id} index={props.index}>
      {(provided, snapshot) => {
        return (
          <TaskBox
            key={props.task.id + "B"}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={
              snapshot.isDragging
                ? { backgroundColor: "#8b8d8d45" }
                : {
                    borderStyle: "solid",
                    backgroundColor: priorityColor,
                  }
            }
          >
            <Grid container>
              <Grid item xs={11}>
                <h4 style={{ margin: "0.4rem" }}>
                  {props.task.groupType} / {props.task.groupTypeLevel1} / {props.task.groupTypeLevel2} /{" "}
                  {props.task.groupTypeLevel3}
                </h4>

                <p>Kommentar: {props.task.multilineText}</p>

                <p>
                  Tidsfrist:
                  {props.task.selectedDate ? new Date(props.task.selectedDate).toLocaleString("sv-SV") : <></>}
                  <br />
                  Uppdatering:
                  {props.task.selectedDateUpdate ? (
                    new Date(props.task.selectedDateUpdate).toLocaleString("sv-SV")
                  ) : (
                    <></>
                  )}
                  <br />
                  Raporterad:
                  {props.task.reportedDateTime ? new Date(props.task.reportedDateTime).toLocaleString("sv-SV") : <></>}
                </p>
              </Grid>
              <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                <IconButton onClick={removeTask}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </TaskBox>
        );
      }}
    </Draggable>
  );
}
