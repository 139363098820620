import DateRangePicker from "@mui/lab/DateRangePicker"
import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import { useAllTrains } from "../../hooks/trains"
import LocationPassingInfo from "./location_passing_info"
import TabPanel from "./tabPanel"

const Container = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export default function RfidPassByList(props) {
  const [activeTab, setActiveTab] = useState(0)
  const [timeRange, setTimeRange] = useState([new Date(), new Date()])

  const fromTime = timeRange[0] || new Date()
  fromTime.setHours(0)
  fromTime.setMinutes(0)
  fromTime.setSeconds(0)

  const toTime = timeRange[1] || new Date()
  toTime.setHours(23)
  toTime.setMinutes(59)
  toTime.setSeconds(59)

  const {
    trains = [],
    isLoading,
    error,
  } = useAllTrains(props.locationID, {
    startTime: fromTime.getTime(),
    endTime: toTime.getTime(),
    limit: 100,
  })

  // Not used prepared for wagons
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: "1rem" }}>
        <DateRangePicker
          startText="Från"
          endText="Till"
          value={timeRange}
          onChange={newValue => setTimeRange(newValue)}
          renderInput={(startProps, endProps) => (
            <>
              <TextField size="small" {...startProps} />
              <Box sx={{ mx: 1 }}> </Box>
              <TextField size="small" {...endProps} />
            </>
          )}
        />

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="rfid-tabs"
          variant="fullWidth"
        >
          <Tab
            label={"Passerande tåg (" + trains?.length + ")"}
            {...a11yProps(0)}
          />
          {/* TODO: Wagons, coming in later later deployment */}
          {/*  <Tab label="Vagnar" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        {isLoading ? (
          <Stack
            sx={{}}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        ) : (
          trains
            ?.slice()
            .sort(
              (a, b) =>
                b.passingByEvent.actualTime - a.passingByEvent.actualTime
            )
            .map(train => <LocationPassingInfo train={train} />)
        )}
      </TabPanel>
    </Container>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
