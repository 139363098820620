import { format } from "date-fns"
import { Link } from "react-router-dom"
import itemRoutes from "../../routes.json"
import styled from "styled-components"
import {
  COLOR_ACCENT_SECONDARY,
  COLOR_PRIMARY,
  COLOR_TERTIARY,
  COLOR_WHITE,
} from "../../utils/colors"
import { formatRcmfId, formatTrainId } from "../../utils/dataUtils"
import ReaderIcon from "../icons/ReaderIcon"
import TrainIcon from "../icons/TrainIcon"
import Text from "../Text"
import ROUTES from "../../../../ROUTES.json"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`

const InfoContainer = styled.div`
  margin: 0.2em 0 0.2em 0.5em;
  padding: 0.5em 0.2em;
  border-radius: 5px;
  background-color: ${COLOR_WHITE};
  flex: 4;
  text-align: left;
  border: 0.1px solid ${COLOR_TERTIARY};
  cursor: pointer;

  ${({ isNewlyAdded }) =>
    isNewlyAdded &&
    `
    animation: glow 5s;
  `}

  @keyframes glow {
    from {
      box-shadow: 0 0 5px 5px ${COLOR_ACCENT_SECONDARY};
    }
    to {
      box-shadow: none;
    }
  }

  ${({ glow }) => glow && `box-shadow: 0 0 5px 5px ${COLOR_ACCENT_SECONDARY};`}

  &:hover {
    border-color: ${COLOR_PRIMARY};
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const TimeContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  flex: 1;
`

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${COLOR_PRIMARY};
  z-index: 10;
`

// direction: ser sida A 1, ser sida B 2

const Item = ({
  message,
  reader,
  glow,
  isNewlyAdded,
  users,
  onClick,
  itemType,
}) => (
  <Container>
    <TimeContainer>
      <Circle />
      <Text bold>{format(new Date(message.payload.time), "HH:mm")}</Text>
    </TimeContainer>
    <InfoContainer
      glow={glow}
      isNewlyAdded={isNewlyAdded}
      onClick={() => onClick?.(message)}
    >
      {reader && (
        <Row>
          <ReaderIcon />
          <Text>
            <Link
              onClick={e => e.stopPropagation()}
              to={`${ROUTES.RFID}/${itemRoutes.READERS}/${reader.SGLN}`}
            >
              {reader.Name}
            </Link>
          </Text>
        </Row>
      )}
      {!reader && (
        <Row>
          <TrainIcon />

          <Link
            onClick={e => e.stopPropagation()}
            to={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}/${message.payload.referenceObject}`}
          >
            <Text>{formatRcmfId(message.payload.referenceObject)}</Text>
          </Link>
        </Row>
      )}
      <Row>
        <Text small bold>
          Reported by:{" "}
        </Text>
        <Text small>{users[message.reportedBy]?.en || "Unknown"}</Text>
      </Row>
      {itemType === itemRoutes.TRAINS && (
        <Text small>
          <b>Wagon ID: </b>
          <Link
            onClick={e => e.stopPropagation()}
            to={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}/${message.payload.referenceObject}`}
          >
            {formatRcmfId(message.payload.referenceObject)}
          </Link>
        </Text>
      )}
      <Text small>
        <b>Vehicle position: </b>
        {message.vehiclePosition ? message.vehiclePosition : "Unknown"}
      </Text>
      {message.technicalTrainId && itemType !== itemRoutes.TRAINS && (
        <Text small>
          <b>Train number: </b>
          <Link
            onClick={e => e.stopPropagation()}
            to={`${ROUTES.RFID}/${itemRoutes.TRAINS}/${message.technicalTrainId}`}
          >
            {formatTrainId(message.technicalTrainId)}
          </Link>
        </Text>
      )}
      {message.payload.trainDirection && itemType !== itemRoutes.TRAINS && (
        <Row>
          <Text small bold>
            Direction:{" "}
          </Text>
          <Text small>{message.payload.trainDirection}</Text>
        </Row>
      )}
    </InfoContainer>
  </Container>
)

export default Item
