import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { digitrafficTrainPosState } from "../recoil/atoms"
import { useFetch } from "./backend"

export default function useDigitraffic() {
  const [{ data, isLoading, error }, fetchDigitraffic] = useFetch()
  const [trainPos, setTrainPos] = useRecoilState(digitrafficTrainPosState)

  useEffect(() => {
    fetchDigitraffic(
      "https://rata.digitraffic.fi/api/v1/train-locations.geojson/latest"
    )
  }, [fetchDigitraffic])

  useEffect(() => {
    setTrainPos({ data: data?.features, isLoading, error })
  }, [data, isLoading, error, setTrainPos])

  return trainPos
}
