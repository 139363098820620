import { Button, Modal, Paper, styled } from "@mui/material"
import Error from "./error"

type Props = {
  error: Error
  open: boolean
  onClose?: () => void | undefined
}

const Container = styled(Paper)`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 1em 1em 1em;
`

const StyledButton = styled(Button)`
  margin-top: 1em;
`

export default function ModalError({ error, open = false, onClose }: Props) {
  return (
    <Modal open={!!error && open} onClose={onClose}>
      <Container>
        <Error error={error} errorText="Vänligen försök igen om en stund." />
        <StyledButton
          color="secondary"
          variant="contained"
          onClick={() => onClose?.()}
        >
          Ok
        </StyledButton>
      </Container>
    </Modal>
  )
}
