import styled from "styled-components"
import qs from "query-string"
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"
import { COLOR_PRIMARY, COLOR_SECONDARY } from "../../utils/colors"
import ReaderIcon from "../icons/ReaderIcon"
import TrainIcon from "../icons/TrainIcon"
import WagonIcon from "../icons/WagonIcon"
import ItemInfo from "../ItemInfo"
import itemRoutes from "../../routes.json"
import TabLink from "./TabLink"
import TrainsDetails from "./TrainsDetails"
import RefObjectsDetails from "./RefObjectsDetails"
import ReadersDetails from "./ReadersDetails"
import ROUTES from "../../../../ROUTES.json"

const Container = styled.div`
  background-color: ${COLOR_SECONDARY};
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Tablist = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: stretch;

  & li {
    flex: 1;
  }
`

const Details = () => {
  const history = useHistory()
  const location = useLocation()

  const searchQuery = qs.parse(location.search)?.q || ""
  const setSearchQuery = query =>
    history.push({ search: query ? qs.stringify({ q: query }) : undefined })

  const subDetailsCommonProps = {
    setSearchQuery,
    searchQuery,
  }

  return (
    <Container>
      <ContentContainer>
        <Tablist>
          <li>
            <TabLink
              path={`${ROUTES.RFID}/${itemRoutes.TRAINS}`}
              Icon={TrainIcon}
            >
              Trains
            </TabLink>
          </li>
          <li>
            <TabLink
              path={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}`}
              Icon={WagonIcon}
            >
              Wagons
            </TabLink>
          </li>
          <li>
            <TabLink
              path={`${ROUTES.RFID}/${itemRoutes.READERS}`}
              Icon={ReaderIcon}
            >
              Readers
            </TabLink>
          </li>
        </Tablist>
        <Switch>
          <Route path={`${ROUTES.RFID}/${itemRoutes.TRAINS}`} exact>
            <TrainsDetails {...subDetailsCommonProps} />
          </Route>
          <Route path={`${ROUTES.RFID}/${itemRoutes.REF_OBJECTS}`} exact>
            <RefObjectsDetails {...subDetailsCommonProps} />
          </Route>
          <Route path={`${ROUTES.RFID}/${itemRoutes.READERS}`} exact>
            <ReadersDetails {...subDetailsCommonProps} />
          </Route>
          <Route
            path="/:itemType/:itemId"
            render={({ match }) => match.params.itemId && <ItemInfo />}
          />
        </Switch>
      </ContentContainer>
    </Container>
  )
}

export default Details
