import TrainIcon from "../icons/TrainIcon"
import { formatTrainId } from "../../utils/dataUtils"
import Text from "../Text"
import Timeline from "../Timeline"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import BaseContainer from "./BaseContainer"
import DataWrapper from "../DataWrapper"
import { fetchMessagesForTrain } from "../../actions/messages"
import { useHistory } from "react-router-dom"
import itemRoutes from "../../routes.json"
import { fetchTrain } from "../../actions/trains"
import { useAuthDispatch } from "../../../../hooks/rfidBackend"

const Train = ({ id }) => {
  const dispatch = useAuthDispatch()
  const history = useHistory()
  const train = useSelector(state => state.trains.byId[id])
  const isLoadingTrain = useSelector(state => state.trains.isLoading) || !train
  const trainError = useSelector(state => state.trains.error)
  const messages = useSelector(state => state.messages.byTrainId[id]) || []
  const { isLoading: isLoadingMessages, messageError } = useSelector(
    state => state.messages
  )

  useEffect(() => {
    if (!train) {
      dispatch(fetchTrain(id))
    }
  }, [id, dispatch, train])

  useEffect(() => {
    dispatch(fetchMessagesForTrain(id))
  }, [id, dispatch])

  return (
    <BaseContainer
      TitleIcon={TrainIcon}
      titleText={"Train timeline"}
      headerContent={
        <DataWrapper isLoading={isLoadingTrain} fixedHeight error={trainError}>
          <Text light>
            <b>Train number: </b>
            {formatTrainId(train?.trainId)}
          </Text>
          <Text light>
            <b>Number of readings: </b>
            {messages.length}
          </Text>
          <Text light>
            <b>Train length: </b>
            {train?.latestReferenceObjects?.length || "Unknown"}
          </Text>
        </DataWrapper>
      }
      bodyContent={
        <DataWrapper error={messageError} isLoading={isLoadingMessages}>
          <Timeline
            messages={messages}
            showReaderInfo
            onClick={message =>
              history.push(
                `/${itemRoutes.TRAINS}/${train.trainId}/${message.messageId}`
              )
            }
          />
        </DataWrapper>
      }
    />
  )
}

export default Train
