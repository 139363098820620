import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import styled from "styled-components"
import { COLOR_WHITE } from "../../utils/colors"
import Text from "../Text"

const StyledTableContainer = styled(TableContainer)`
  margin: 0;
`

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${COLOR_WHITE};
  }
  // hide last border
  &:last-child td,
  &:last-child th {
    border: none;
  }
`

const CollapsibleTable = ({ headerItems, rows }) => (
  <StyledTableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          {headerItems.map(headerItem => (
            <TableCell align="left">
              <Text small bold>
                {headerItem}
              </Text>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <StyledTableRow key={index}>
            {row.map(cell => (
              <TableCell>
                <Text small>{cell}</Text>
              </TableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </StyledTableContainer>
)

export default CollapsibleTable
