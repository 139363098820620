import React from "react"
// Components
import RfidPassByList from "./RfidPassByList"
import { Grid, Typography, Stack, Paper, styled } from "@mui/material"
import { formatEpochDateTime } from "../../utils/dataFormatting"
import TrafficImpact from "../../base-element/static-elements/traffic_impact"
import FinTrainInfo from "./fin_train_info"
import LocationInfo from "./location_info"

const Container = styled(Grid)`
  padding: 0 1em;
  height: 100%;
  overflow: auto;
`

const ItemContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1em;
  width: 100%;
`

export default function StatusSideBar({ selectedObjectOnMap }) {
  return (
    <Container
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {/* If no object is selected then show null */}
      {selectedObjectOnMap.layer &&
        /* Station object clicked */
        {
          stationStatistics: () => (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" color="text.primary">
                  {selectedObjectOnMap.object.Name}
                </Typography>
                <Typography variant="caption" color="text.primary">
                  Statistik för de senaste 24H och följande 24H
                </Typography>
                <p>
                  Genomsnittlig noggrannhet i tidshållning
                  <br />
                  <b>
                    {" "}
                    {selectedObjectOnMap.object.timekeeping_actual
                      ? selectedObjectOnMap.object.timekeeping_actual.toFixed(2)
                      : ""}{" "}
                    min
                  </b>
                  <br />
                  <br />
                  Total summerad försening rapporterad
                  <br />
                  <b>
                    {" "}
                    {selectedObjectOnMap.object.total_delay_estimation} min
                  </b>{" "}
                  <br />
                  <br />
                  Antal tåg
                  <br /> <b> {selectedObjectOnMap.object.tot_trains} st</b>
                </p>
              </Grid>
              <Grid item xs={12}></Grid>
            </>
          ),
          "train-message-layer": () => (
            <ItemContainer>
              <Grid item xs={12}>
                <Typography variant="h5" color="text.primary">
                  {selectedObjectOnMap.object.Header}
                </Typography>

                <p>
                  {selectedObjectOnMap.object.ExternalDescription} <br />
                </p>

                <hr />
                <p>
                  Antal sträckor påverkade{" "}
                  {selectedObjectOnMap.object.TrafficImpact?.length}
                </p>
                <TrafficImpact
                  routeImpact={selectedObjectOnMap.object.TrafficImpact}
                />
                <hr />
                <p>
                  Händelsens starttid:{" "}
                  {formatEpochDateTime(
                    selectedObjectOnMap.object.StartDateTime
                  )}{" "}
                  <br />
                  Updaterad:{" "}
                  {formatEpochDateTime(
                    selectedObjectOnMap.object.LastUpdateDateTime
                  )}{" "}
                  <br />
                  Ändrad:{" "}
                  {formatEpochDateTime(
                    selectedObjectOnMap.object.ModifiedTime
                  )}{" "}
                  <br />
                </p>
              </Grid>
            </ItemContainer>
          ),
          "train-locations-layer": () => (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" color="text.primary">
                  {selectedObjectOnMap.object.operator}
                </Typography>

                <p>
                  Destination: {selectedObjectOnMap.object.destination} <br />
                  Tidshållning: {selectedObjectOnMap.object.delay + " min"}{" "}
                  <br />
                  Avgångs dag: {selectedObjectOnMap.object.depdate} <br />
                  Kö: {selectedObjectOnMap.object.queue} <br />
                  Typ: {selectedObjectOnMap.object.slag} <br />
                  Fart: {selectedObjectOnMap.object.speed || "Ingen data"}{" "}
                  <br />
                  Tåg nummer: {selectedObjectOnMap.object.tagNr} <br />
                </p>
              </Grid>
              <Grid item xs={12}></Grid>
            </>
          ),
          "train-locations-fin-layer": () => (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" color="text.primary">
                  Tåg nummer:{" "}
                  {selectedObjectOnMap.object.properties.trainNumber}
                </Typography>

                <p>
                  Fart:{" "}
                  {selectedObjectOnMap.object.properties.speed || "Ingen data"}{" "}
                  km/h
                  <br />
                  Uppdaterad:{" "}
                  {formatEpochDateTime(
                    selectedObjectOnMap.object.properties.timestamp
                  )}
                  <br />
                  Avgångs dag:{" "}
                  {selectedObjectOnMap.object.properties.departureDate} <br />
                </p>
              </Grid>
              <Grid item xs={12}>
                <FinTrainInfo
                  trainNumber={
                    selectedObjectOnMap.object.properties.trainNumber
                  }
                  departureDate={
                    selectedObjectOnMap.object.properties.departureDate
                  }
                />
              </Grid>
            </>
          ),
          "rfid-readers": () => (
            <ItemContainer>
              <LocationInfo
                locationName={selectedObjectOnMap.object.Name}
                locationID={selectedObjectOnMap.object.SGLN}
              />

              <RfidPassByList
                SGLN={selectedObjectOnMap.object.SGLN}
                locationID={selectedObjectOnMap.object.SGLN}
              />
            </ItemContainer>
          ),
        }[selectedObjectOnMap.layer.id]()}
    </Container>
  )
}
