import React, { useEffect, useState } from "react"
import { TimePicker } from "@mui/lab"
import {
  InputBase,
  TextField,
  Button,
  styled,
  CircularProgress,
} from "@mui/material"
import { format, isValid } from "date-fns"
import { useRcmfSubmissionService } from "../../../hooks/rcmf"
import FlexDiv from "../../../components/flex_div"
import { Row } from "../types"
import {
  CenterContainer,
  DisableableTableRow,
  PaddedTableCell,
  RestrainedTableCell,
} from "."
import { useTranslation } from "react-i18next"
import { generateRcm } from "../utils/utils"

type Props = {
  timeSequence: "departures" | "arrivals"
  stationId: string | undefined
  disabledRows: string[]
  onSubmit: (args: { sentAt: number; trainId: string }) => void
  selectedDate: Date
}
const AddRow: React.FC<Props> = props => {
  const [newRow, setNewRow] = useState<Partial<Row>>({})
  const { t } = useTranslation()
  const [{ error: submissionError }, submitMessage] =
    useRcmfSubmissionService() as any[]

  const trainId = `${format(new Date(), "yyyyMMdd")}.${newRow.trainNumber}`
  const loading = props.disabledRows.includes(trainId)

  useEffect(() => {
    if (!loading) setNewRow({})
  }, [loading])

  const onSubmit = async () => {
    const isArrival = props.timeSequence === "arrivals"
    const rcm = generateRcm(
      { ...newRow, planned: true },
      {
        to: isArrival ? props.stationId : undefined,
        from: !isArrival ? props.stationId : undefined,
        event: undefined,
      }
    )

    submitMessage(rcm)
    props.onSubmit({
      sentAt: new Date().getTime(),
      trainId,
    })
  }

  const onChange = (field: keyof Row) => (e: any) => {
    setNewRow(prev => ({ ...prev, [field]: e.target.value }))
  }

  const selectedDate = new Date(newRow.time ?? "")
  const validDate = isValid(selectedDate)

  return (
    <DisableableTableRow forcedBackground={loading}>
      <PaddedTableCell>
        {loading && (
          <CenterContainer>
            <CircularProgress size={20} />
          </CenterContainer>
        )}
        {!loading && (
          <InputBase
            disabled={loading}
            value={newRow.trainNumber ?? ""}
            onChange={onChange("trainNumber")}
          />
        )}
      </PaddedTableCell>
      <RestrainedTableCell>
        <InputBase
          disabled={loading}
          value={newRow.operationalTrainNumber ?? ""}
          onChange={onChange("operationalTrainNumber")}
        />
      </RestrainedTableCell>
      <RestrainedTableCell>
        <InputBase
          disabled={loading}
          value={newRow.operator ?? ""}
          onChange={onChange("operator")}
        />
      </RestrainedTableCell>
      <PaddedTableCell>
        <TimePicker
          disabled={loading}
          onChange={date => {
            date?.setDate(props.selectedDate.getDate())

            onChange("time")({
              target: { value: date ? new Date(date) : null },
            })
          }}
          InputProps={{ disableUnderline: true }}
          value={newRow.time ? new Date(newRow.time) : null}
          renderInput={props => <TextField variant="standard" {...props} />}
        />
      </PaddedTableCell>
      <PaddedTableCell>
        <InputBase
          disabled={loading}
          value={newRow.track ?? ""}
          onChange={onChange("track")}
        />
      </PaddedTableCell>
      <PaddedTableCell>
        <InputBase
          disabled={loading}
          value={newRow.comment ?? ""}
          onChange={onChange("comment")}
        />
      </PaddedTableCell>
      <PaddedTableCell colSpan={4}>
        <FlexDiv>
          <AddButton
            variant="contained"
            onClick={onSubmit}
            disabled={!!loading || !validDate || !newRow.trainNumber}
          >
            {t("plank.addTrain")}
          </AddButton>
        </FlexDiv>
      </PaddedTableCell>
    </DisableableTableRow>
  )
}

const AddButton = styled(Button)`
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
`

export default AddRow
