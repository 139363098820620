import { combineReducers } from "redux"
import messagesReducer from "./messages"
import referenceObjectsReducer from "./referenceObjects"
import readersReducer from "./readers"
import trainsReducer from "./trains"
import usersReducer from "./users"

const rootReducer = combineReducers({
  messages: messagesReducer,
  referenceObjects: referenceObjectsReducer,
  readers: readersReducer,
  trains: trainsReducer,
  users: usersReducer,
})

export default rootReducer
