import { TileLayer } from "@deck.gl/geo-layers"
import {
  BitmapLayer,
  IconLayer,
  PathLayer,
  ScatterplotLayer,
} from "@deck.gl/layers"
import DeckGL from "@deck.gl/react"
import { styled } from "@mui/material"
import { useState } from "react"
import { StaticMap } from "react-map-gl"
import { useRfidReaders, useStations } from "../hooks/staticData"

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoieXlkZGVldHQiLCJhIjoiY2t0eGEyNjJhMWI0NjJxcW53dGNrMmk2eSJ9.6bkGb4cvC5pbb8sisIScSw"

const StyledMap = styled(DeckGL)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const colorForTimeTypes = {
  Planned: [33, 150, 243],
  Estimated: [238, 201, 55],
  Actual: [153, 185, 61],
}

export default function TrainRoutesMap({ trains = [] }) {
  const { stations } = useStations()
  const { readers: rfidReaders } = useRfidReaders()
  const [viewState, setViewState] = useState({
    latitude: 61,
    longitude: 16,
    zoom: 4,
    pitch: 0,
    bearing: 0,
  })

  const mapEventsToPath = events =>
    events
      .map(event => {
        const station = stations[event.location]
        const rfidReader =
          rfidReaders[event.location?.split("rcmf:location:rfid_reader:")[1]]

        if (station) {
          return [station.lon, station.lat]
        }

        if (rfidReader) {
          return [rfidReader.Longitude, rfidReader.Latitude]
        }

        return null
      })
      .filter(val => !!val)

  const paths = (trains || []).flatMap(train =>
    ["Planned", "Estimated", "Actual"].map(timeType => ({
      path: mapEventsToPath(
        train.events.filter(e => e.latestTimeType === timeType)
      ),
      timeType,
      trainNumber: train.technicalTrainNumber,
    }))
  )

  // Railway Map
  const layerBitMapOpenRailway = new TileLayer({
    data: "https://tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png",
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    visible: true,
    renderSubLayers: props => {
      const {
        bbox: { west, south, east, north },
      } = props.tile
      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      })
    },
  })

  const routePlan = paths.flatMap(({ timeType, path, trainNumber }) => ({
    path,
    name: `${trainNumber} - ${timeType}`,
    color: colorForTimeTypes[timeType],
  }))

  const stationPlan = routePlan.flatMap(({ path, name, color }, index) =>
    path.map(pos => ({ name: `${name}-${index}`, color, pos }))
  )

  const trainTracks = new PathLayer({
    id: "path-layer",
    data: routePlan,
    pickable: true,
    widthScale: 10,
    widthMinPixels: 4,
    capRounded: true,
    getPath: ({ path }) => path,
    getColor: d => d.color,
    getWidth: () => 4,
  })

  // Station location
  const layerStationPass = new ScatterplotLayer({
    id: "scatterplot-layer",
    data: stationPlan,
    pickable: true,
    opacity: 0.8,
    stroked: true,
    filled: true,
    radiusScale: 6,
    radiusMinPixels: 3,
    radiusMaxPixels: 10,
    lineWidthMinPixels: 1,
    getPosition: d => d.pos,
    getRadius: d => 100,
    getFillColor: d => d.color,
  })

  return (
    <StyledMap
      layers={[layerBitMapOpenRailway, trainTracks, layerStationPass]}
      viewState={viewState}
      onViewStateChange={e => setViewState(e.viewState)}
      controller={{ dragPan: true }}
    >
      <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />{" "}
    </StyledMap>
  )
}
