import { DatePicker } from "@mui/lab"
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { startOfDay } from "date-fns"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Prompt, useRouteMatch } from "react-router-dom"
import { useRecoilValue } from "recoil"
import DataWrapper from "../../components/data_wrapper"
import GridCenter from "../../components/grid_center"
import ModalError from "../../components/modal_error"
import StationSelector from "../../components/station_selector"
import TrainInsight from "../../components/train_insight"
import { useUserHistory } from "../../hooks/history"
import { useRcmfSubmissionService } from "../../hooks/rcmf"
import { useStations } from "../../hooks/staticData"
import {
  TrainsProps,
  useArrivingTrains,
  useDepartingTrains,
} from "../../hooks/trains"
import { userState } from "../../recoil/atoms"
import { CancelButton, PlankPage, SendButton } from "./components"
import PlankFilters, { usePlankFilters } from "./components/PlankFilters"
import { Row, State } from "./types"
import { markedAsDone } from "./utils"
import {
  createMarksForTimespan,
  formatHour,
  getAllTimestamps,
} from "./utils/utils"
import PlankColumns, { usePlankColumnsFilter } from "./components/PlankColumns"

type NavParams = {
  page?: string
  stationId?: string
  trainId?: string
}

const sendingTimeoutMs = 60 * 1000
const pollingTimeoutMs = 10 * 1000

export default function Plank() {
  const [date, setDate] = useState<Date>(new Date())
  const defaultTimeInterval = [date.getHours(), 24]
  const [timeInterval, setTimeInterval] = useState(defaultTimeInterval)
  const [arrivalsState, setArrivalsState] = useState<State>({})
  const [departuresState, setDeparturesState] = useState<State>({})
  const [pageKeys, setPageKeys] = useState([0, 1])
  const [disabledRows, setDisabledRows] = useState<string[]>([])
  const [timeoutError, setTimeoutError] = useState<Error | null>(null)
  const [hideError, setHideError] = useState(false)
  const [sentAt, setSentAt] = useState<number | null>(null)
  const [departureLeft, setDepartureLeft] = useState(false)
  const [pollingDelay, setPollingDelay] = useState<number | undefined>(
    undefined
  )
  const { t } = useTranslation()

  const filters = usePlankFilters()
  const columnFilters = usePlankColumnsFilter()

  window.onbeforeunload = () => {
    if (!unchanged) {
      return t("plank.leavePrompt")
    }
  }

  const sending = disabledRows.length > 0

  const unlockRows = () => setDisabledRows([])
  const lockRows = () =>
    setDisabledRows([
      ...Object.keys(arrivalsState),
      ...Object.keys(departuresState),
    ])

  const timeoutRef = useRef<number | undefined>()
  const fetchAfterSendingTimeout = useRef<number | undefined>()

  const [{ error: submissionError }, _, submitMessages] =
    useRcmfSubmissionService() as any[]

  const { page, stationId, trainId }: NavParams =
    useRouteMatch("/:page/:stationId?/:trainId?")?.params || {}
  const history = useUserHistory()
  const { stations } = useStations() as { stations: { [key: string]: any } }

  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("lg"))

  const primaryStations = [
    stations["Hgl"],
    stations["Äsg"],
    stations["Mgb"],
    stations["Sär"],
    stations["Uågb"],
  ].map(station => ({
    ...station,
    isPrimary: true,
  }))

  const cancelTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current)
    clearTimeout(fetchAfterSendingTimeout.current)
    timeoutRef.current = undefined
    fetchAfterSendingTimeout.current = undefined
    setSentAt(null)
    setHideError(true)
    unlockRows()
    setPollingDelay(undefined)
  }, [])

  const trainOptions: Partial<TrainsProps> = {
    startTime: startOfDay(date).getTime() + timeInterval[0] * 3600 * 1000,
    endTime: startOfDay(date).getTime() + timeInterval[1] * 3600 * 1000,
    hideMissing: false,
    includeEventMessages: true,
    sortTimeMode: "planned_time" as "planned_time",
    includeEvents: true,
    websocket: true,
    pollDelay: pollingDelay,
  }

  useEffect(() => {
    if (page && !stationId) {
      history.replace(`/${page}/${primaryStations[0].id}`)
    }
  }, [page, stationId, history, primaryStations])

  useEffect(() => {
    if (submissionError) {
      unlockRows()
      setHideError(false)
    }
  }, [submissionError])

  useEffect(() => {
    cancelTimeout()
    return () => cancelTimeout() // Cancel timeout on component unmount
  }, [page, stationId, cancelTimeout, timeInterval])

  const { username } = useRecoilValue(userState)

  const {
    trains: unfilteredArrivingTrains,
    isLoading: isLoadingArrivingTrains,
  } = useArrivingTrains(stationId, { ...trainOptions })
  const {
    trains: unfilteredDepartingTrains,
    isLoading: isLoadingDepartingTrains,
  } = useDepartingTrains(stationId, { ...trainOptions })

  const arrivingTrains = unfilteredArrivingTrains.filter(
    train =>
      (filters.showCancelled || !train.cancelled) &&
      (filters.showDone || !markedAsDone(train, "arrivals"))
  )

  const departingTrains = unfilteredDepartingTrains.filter(
    train =>
      (filters.showCancelled || !train.cancelled) &&
      (filters.showDone || !markedAsDone(train, "departures"))
  )

  useEffect(() => {
    const allEvents = [
      ...arrivingTrains.map(t => t.arrivalEvent),
      ...arrivingTrains.flatMap(t =>
        t.events?.filter(e => e.state === "train_arrival_to_depot_confirmed")
      ),
      ...departingTrains.map(t => t.departureEvent),
      ...departingTrains.flatMap(t =>
        t.events?.filter(
          e => e.state === "train_ready_for_departure_from_depot_confirmed"
        )
      ),
    ]

    // Not waterproof but probably enough
    // Should ultimately compare messsageIds but we don't know it after submission
    const receivedSentMessages =
      sentAt &&
      disabledRows.every(trainId =>
        allEvents.some(
          e =>
            e?.trainId === trainId &&
            e.updatedAt >= sentAt &&
            e.updatedBy === username
        )
      )

    if (receivedSentMessages) {
      cancelTimeout()
    }
  }, [
    arrivingTrains,
    departingTrains,
    disabledRows,
    sentAt,
    username,
    cancelTimeout,
  ])

  const selectTrain = useCallback(
    (trainId: string) => history.push(`/${page}/${stationId}/${trainId}`),
    [history, page, stationId]
  )

  const updateState = (trainId: string, rowData: Row) => (prev: any) => {
    const isEmpty = Object.values(rowData).length === 0
    if (!isEmpty) {
      return { ...prev, [trainId]: rowData }
    } else {
      // Remove the whole field
      const { [trainId]: selectedTrainId, ...rest } = prev
      return rest
    }
  }

  const onAddSubmit = (args: { sentAt: number; trainId: string }) => {
    timeoutRef.current = window.setTimeout(timeoutCallback, sendingTimeoutMs)
    setPollingDelay(pollingTimeoutMs)
    setSentAt(args.sentAt)
    setDisabledRows(prev => [...prev, args.trainId])
  }

  const onArrivalsChange = useCallback((trainId, rowData) => {
    setArrivalsState(updateState(trainId, rowData))
  }, [])

  const onDeparturesChange = useCallback(
    (trainId, rowData) => setDeparturesState(updateState(trainId, rowData)),
    []
  )

  const unchanged =
    Object.keys(arrivalsState).length === 0 &&
    Object.keys(departuresState).length === 0

  const timeoutCallback = () => {
    setTimeoutError(
      new Error(`Timeout - no message received after ${sendingTimeoutMs} ms`)
    )
    setHideError(false)
    unlockRows()
  }

  const submitAllMessages = () => {
    lockRows()
    timeoutRef.current = window.setTimeout(timeoutCallback, sendingTimeoutMs)
    setPollingDelay(pollingTimeoutMs)

    const rcms = [
      ...Object.keys(arrivalsState).flatMap(trainId => {
        const train = arrivingTrains.find(train => train.trainId === trainId)

        return getAllTimestamps(
          arrivalsState,
          trainId,
          stationId,
          train,
          true,
          train?.arrivalEvent
        )
      }),
      ...Object.keys(departuresState).flatMap(trainId => {
        const train = departingTrains.find(train => train.trainId === trainId)
        return getAllTimestamps(
          departuresState,
          trainId,
          stationId,
          train,
          false,
          train?.departureEvent
        )
      }),
    ]

    submitMessages(rcms).then(() => {
      setSentAt(new Date().getTime())
    })
  }

  const hasError = !!submissionError || !!timeoutError

  const commonPlankPageProps = {
    onSelectTrain: selectTrain,
    station: stationId && stations[stationId],
    date,
    disabledRows,
    selectedTrainId: trainId,
    hasError,
    onAddSubmit,
    hiddenPlannedTrackReporters: !filters.showTrvTracks ? /trv.*/ : undefined,
    columnFilters,
  }

  const arrivalComponent = (
    <DataWrapper isLoading={isLoadingArrivingTrains}>
      <PlankPage
        arrivals={arrivingTrains}
        onStateChange={onArrivalsChange}
        key={pageKeys[0]}
        loading={isLoadingArrivingTrains}
        {...commonPlankPageProps}
      />
    </DataWrapper>
  )

  const departureComponent = (
    <DataWrapper isLoading={isLoadingDepartingTrains}>
      <PlankPage
        departures={departingTrains}
        onStateChange={onDeparturesChange}
        key={pageKeys[1]}
        loading={isLoadingDepartingTrains}
        {...commonPlankPageProps}
      />
    </DataWrapper>
  )

  const leftComponent = departureLeft ? departureComponent : arrivalComponent
  const rightComponent = !!trainId ? (
    <Paper>
      <TrainInsight
        trainId={trainId}
        stationId={stationId}
        onDeselectTrain={() => history.push(`/${page}/${stationId}`)}
      />
    </Paper>
  ) : departureLeft ? (
    arrivalComponent
  ) : (
    departureComponent
  )

  return (
    <Container container spacing={2}>
      <Prompt when={!unchanged} message={t("plank.leavePrompt")} />
      <ModalError
        open={hasError && !hideError}
        onClose={() => {
          setHideError(true)
          setTimeoutError(null)
          timeoutRef.current = undefined
        }}
        error={submissionError || timeoutError}
      />
      <Grid item xs={12}>
        <HeaderContainer container columnSpacing={2}>
          <Grid item xs={12} md={3} xl={2}>
            <DatePicker
              mask={undefined}
              renderInput={props => (
                <TextField
                  {...props}
                  fullWidth
                  size="small"
                  label={t("time.selectDate")}
                />
              )}
              onChange={newDate => newDate && setDate(newDate)}
              value={date}
            />
            <Grid item>
              <FormControlLabel
                label={t("plank.departureLeft")}
                control={
                  <Checkbox
                    value={departureLeft}
                    onChange={e => setDepartureLeft(e.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
          <GridCenter item xs={12} md={6} xl={8}>
            <StationSelector
              onSelectStationId={(id: string) => history.push(`/${page}/${id}`)}
              selectedStationId={stationId}
              stations={primaryStations}
            />
          </GridCenter>
          <Grid item>
            <PlankFilters filters={filters} />
          </Grid>
          <Grid item>
            <PlankColumns columnFilters={columnFilters} />
          </Grid>
          <TimeSliderContainer item xs={12}>
            <Slider
              defaultValue={defaultTimeInterval}
              onChangeCommitted={(e, value) => {
                if (typeof value !== "number") {
                  setTimeInterval(value)
                }
              }}
              valueLabelDisplay="auto"
              disableSwap
              min={0}
              max={32}
              marks={createMarksForTimespan(small)}
              valueLabelFormat={formatHour}
            />
          </TimeSliderContainer>
        </HeaderContainer>
      </Grid>
      <Grid item lg={6} xs={12}>
        {leftComponent}
      </Grid>
      <Grid item lg={6} xs={12}>
        {rightComponent}
      </Grid>
      <SendButton
        onClick={submitAllMessages}
        disabled={unchanged || sending}
        loading={sending}
      />
      <CancelButton
        disabled={unchanged}
        onClick={() =>
          setPageKeys(([first, second]) => [first + 1, second + 1])
        }
      />
    </Container>
  )
}

const Container = styled(Grid)`
  padding: 1em;
`

const HeaderContainer = styled(Grid)`
  align-items: center;
`

const TimeSliderContainer = styled(Grid)`
  padding: 0 1em;
`
