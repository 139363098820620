import { Grid, Paper, styled, useTheme } from "@mui/material"
import { addHours, format, startOfDay } from "date-fns"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
} from "recharts"
import DataWrapper from "../../components/data_wrapper"
import Text from "../../components/text"
import { useStations } from "../../hooks/staticData"
import { useArrivingTrains, useDepartingTrains } from "../../hooks/trains"
import { timeTypePaletteColors } from "../../utils/rcmf"
import { sv, enGB } from "date-fns/locale"
import TrainTooltip from "./train_tooltip"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const Container = styled(Paper)`
  padding: 1em;
`

const HeaderGrid = styled(Grid)`
  display: grid;
  grid-template-columns: auto auto;
`

const CustomDot = ({ payload, cx, cy, theme, setSelectedTrainPayload }) => {
  const timeTypeColor =
    theme.palette[timeTypePaletteColors[payload.timeType]]?.main

  const diameter = 16

  return (
    <svg
      x={cx - diameter}
      y={cy - diameter}
      fill="white"
      onMouseEnter={() => setSelectedTrainPayload(payload)}
      onMouseLeave={() => setSelectedTrainPayload(null)}
      style={{ cursor: "pointer" }}
    >
      <g transform={`translate(${diameter} ${diameter})`}>
        <circle r={8} fill="transparent" />
        <circle r="4" fill={timeTypeColor} />
        <circle
          r="2"
          fill={payload.timeType === "Actual" ? timeTypeColor : "white"}
        />
      </g>
    </svg>
  )
}

const CustomLabel = props => {
  const { index, value, x, y, data } = props
  const dx = index === 0 ? -10 : 10

  return (
    <g>
      <text x={x} y={y} dx={dx} dy={-8} fontSize={12} textAnchor="middle">
        {data[index].trainNumber}
      </text>
      <text x={x} y={y} dx={dx} dy={14} fontSize={12} textAnchor="middle">
        {value}
      </text>
    </g>
  )
}

export default function SAP({
  stationId = "Cst",
  startTime = addHours(startOfDay(new Date()), 8).getTime(),
  endTime = addHours(startOfDay(new Date()), 12).getTime(),
}) {
  const trainOptions = {
    startTime,
    endTime,
    limit: 1000,
    includeEvents: true,
  }
  const { trains: arrivals, isLoading: isLoadingArrivals } = useArrivingTrains(
    stationId,
    trainOptions
  )
  const { trains: departures, isLoading: isLoadingDepartures } =
    useDepartingTrains(stationId, trainOptions)

  const trains = [...arrivals, ...departures]

  const isLoading = isLoadingArrivals || isLoadingDepartures

  const [selectedTrainPayload, setSelectedTrainPayload] = useState(null)

  const { stations } = useStations()

  const { t, i18n } = useTranslation()

  const trackOf = train => (train.arrivalEvent || train.departureEvent)?.track

  const tracks = [...new Set(trains.map(trackOf))]
    .filter(Boolean)
    .sort((a, b) =>
      b.localeCompare(a, undefined, { numeric: true, sensitivity: "base" })
    )

  const series = [...trains]
    .map(train => {
      // Returns -1 if not found
      const trainBecomesEventIndex = train.events?.findIndex(
        e => e.state === "train_becomes_completed"
      )

      const arrivalEventIndex =
        train.arrivalEvent &&
        train.events?.findIndex(
          e =>
            e.state === train.arrivalEvent.state &&
            e.location === train.arrivalEvent.location
        )

      // Make sure there are no intermediate location states
      const hasIntermediateLocationEvents =
        trainBecomesEventIndex >= 0 &&
        arrivalEventIndex >= 0 &&
        train.events?.some(
          (event, index) =>
            ((index > trainBecomesEventIndex && index < arrivalEventIndex) ||
              (index < trainBecomesEventIndex && index > arrivalEventIndex)) &&
            [
              "train_arrival_to_station",
              "train_departure_from_station",
            ].includes(event.state)
        )

      const trainBecomesEvent =
        !hasIntermediateLocationEvents &&
        trainBecomesEventIndex >= 0 &&
        arrivalEventIndex >= 0 &&
        train.events?.[trainBecomesEventIndex]
      const nextTrainId = trainBecomesEvent?.linkedTrainId
      const nextTrain =
        nextTrainId && trains?.find(t => t.trainId === nextTrainId)

      const departureWithinTimeLimit = nextTrain?.departureEvent.time <= endTime

      const arrivalEvent = train.arrivalEvent
      const departureEvent =
        train.departureEvent ||
        (departureWithinTimeLimit && nextTrain?.departureEvent)

      return {
        name: train.trainId,
        data: [
          {
            track: arrivalEvent?.track,
            trainNumber: arrivalEvent?.technicalTrainNumber,
            time: arrivalEvent?.latestTime,
            timeType: arrivalEvent?.latestTimeType,
            planned: arrivalEvent?.plannedTime,
            estimated: arrivalEvent?.estimatedTime,
            actual: arrivalEvent?.actualTime,
            trainId: arrivalEvent?.trainId,
          },
          {
            track: departureEvent?.track,
            time: departureEvent?.latestTime,
            timeType: departureEvent?.latestTimeType,
            planned: departureEvent?.plannedTime,
            estimated: departureEvent?.estimatedTime,
            actual: departureEvent?.actualTime,
            trainNumber: departureEvent?.technicalTrainNumber,
            trainId: departureEvent?.trainId,
          },
        ].filter(d => !!d.track),
      }
    })
    .filter(
      ({ data }) => data[1]?.time >= startTime && data[0]?.time <= endTime
    )
    .sort((a, b) => (a.track < b.track ? 1 : -1))

  const getTicks = (startTime, timeEndG) => {
    const tickArr = []
    const stepInterval = 1800 * 1000
    for (let i = startTime; i < timeEndG; i += stepInterval) {
      tickArr.push(i)
    }

    return tickArr
  }

  const theme = useTheme()

  const day = format(new Date(startTime), "EEEE", {
    locale: { sv, en: enGB }[i18n.language],
  })

  return (
    <DataWrapper isLoading={isLoading}>
      <Container>
        <Grid container>
          <HeaderGrid item xs={12}>
            <Text bold size="large">
              {day.charAt(0).toUpperCase() + day.substring(1)}
            </Text>
            <Text bold size="large">
              {t("sap.title")} {stations[stationId]?.name}
            </Text>
          </HeaderGrid>
          <ResponsiveContainer width="100%" height={800}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="time"
                scale="time"
                tickFormatter={time => format(new Date(time), "HH:mm")}
                domain={[startTime, endTime]}
                ticks={getTicks(startTime, endTime)}
              />
              <YAxis
                dataKey="track"
                interval={0}
                type="category"
                allowDuplicatedCategory
                padding={{ top: 20, bottom: 20 }}
                domain={tracks}
                // ticks={tracks}
              />
              <ReferenceLine x={new Date().getTime()} stroke="green" label="" />
              {series.map(s => (
                <Line
                  dataKey="track"
                  data={s.data}
                  name={s.name}
                  key={s.name}
                  isAnimationActive={false}
                  strokeWidth={2}
                  stroke="black"
                  dot={
                    <CustomDot
                      theme={theme}
                      setSelectedTrainPayload={setSelectedTrainPayload}
                    />
                  }
                  label={<CustomLabel data={s.data} />}
                  points={[
                    { x: 50, y: 50, value: s.data[0].track, payload: {} },
                  ]}
                />
              ))}
              {
                <Tooltip
                  isAnimationActive={false}
                  cursor={false}
                  content={
                    <TrainTooltip selectedTrainPayload={selectedTrainPayload} />
                  }
                />
              }
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Container>
    </DataWrapper>
  )
}
