import WarningIcon from "@mui/icons-material/PriorityHigh"
import { Collapse } from "@mui/material"
import { styled } from "@mui/system"
import { Link } from "react-router-dom"
import FlexDiv from "../../components/flex_div"
import Text from "../../components/text"
import Time from "../../components/time"
import { ReactComponent as IconTrain } from "../../resources/icon_train.svg"
import { ReactComponent as IconWagon } from "../../resources/icon_wagon.svg"
import ROUTES from "../../ROUTES.json"
import { formatUrn } from "../../utils/dataFormatting"
import WagonTimeline from "./wagon_timeline"

const Container = styled("div")`
  // TODO: Possibly make width based on length

  background-color: ${({ theme }) => theme.palette.greyLight.main};
  //border-radius: 3px;
  position: relative;
  border: solid white;
  border-width: 5px 0 5px 5px;
  flex-grow: 1;
  overflow: hidden;
  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
    border-width: 5px 5px 5px 5px;
  }
`

const WarningIndicator = styled(WarningIcon)`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  z-index: 10;
  fill: ${({ theme }) => theme.palette.warning.main};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`

const UnitLabelContainer = styled(FlexDiv)`
  padding: 0 0.8em;
  background-color: ${({ theme }) => theme.palette.background.default};
  flex-grow: 1;
`

const UnitInfoContainer = styled(FlexDiv)`
  padding: 0 0.2em;
  border-top: 5px solid white;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
`
const UnitInfoRowContainer = styled("div", { shouldForwardProp: false })`
  display: flex;
  width: 100%;
  justify-content: ${({ $hasLabel }) =>
    $hasLabel ? "space-between" : "flex-end"};
  margin: ${({ $horizontalSpacing }) => ($horizontalSpacing ? "1em" : "0")} 0;
`

const TrainInfoContainer = styled(FlexDiv)`
  background-color: #fff;
  padding: 0 0.2em;
`

const TrainNumberText = styled(Text)`
  padding-right: 0.5em;
`

const LabelText = styled(Text)`
  padding-right: 0.5em;
`

const UnitInfoRow = ({ label, horizontalSpacing, children }) => (
  <UnitInfoRowContainer
    $hasLabel={!!label}
    $horizontalSpacing={horizontalSpacing}
  >
    {label && (
      <LabelText size="small" bold>
        {label}
      </LabelText>
    )}
    {children}
  </UnitInfoRowContainer>
)

const CollapsibleInfo = ({ unit, train, showLabels, timeSequence }) => {
  const { linkedTrainNumber, linkedTrainId } =
    train.events.find(
      e =>
        e.state === "train_becomes_completed" &&
        e.linkedTrainNumber &&
        (!train.connectedTrainNumbers ||
          !train.connectedTrainNumbers.includes(e.linkedTrainNumber))
    ) || {}

  const ArrivalTime = () => (
    <UnitInfoRow label={showLabels && "Ank. tid"}>
      <Time
        right
        bold
        planned={train.arrivalEvent?.plannedTime}
        estimated={train.arrivalEvent?.estimatedTime}
        actual={train.arrivalEvent?.actualTime}
        noWrap
      />
    </UnitInfoRow>
  )

  const rowsForTimeSequence = {
    arrivals: (
      <>
        <UnitInfoRow label={showLabels && "Avg. TNR"}>
          <Text right size="small">
            {linkedTrainNumber && (
              <Link
                style={{ zIndex: 1000 }}
                onClick={e => e.stopPropagation()}
                to={`${ROUTES.TRAIN_INFO}/${linkedTrainId}`}
              >
                {linkedTrainNumber}
              </Link>
            )}
            {!linkedTrainNumber &&
              (train?.departureEvent?.technicalTrainNumber || "-")}
          </Text>
        </UnitInfoRow>
        <ArrivalTime />
        <UnitInfoRow label={showLabels && "Avg. spår"}>
          <Text size="small">{train?.departureEvent?.track || "-"}</Text>
        </UnitInfoRow>
      </>
    ),
    departures: (
      <>
        <UnitInfoRow label={showLabels && "Ank. TNR"}>
          <Text right size="small">
            {linkedTrainNumber && (
              <Link
                style={{ zIndex: 1000 }}
                onClick={e => e.stopPropagation()}
                to={`${ROUTES.TRAIN_INFO}/${linkedTrainId}`}
              >
                {linkedTrainNumber}
              </Link>
            )}
            {!linkedTrainNumber &&
              (train?.arrivalEvent?.technicalTrainNumber || "-")}
          </Text>
        </UnitInfoRow>
        <ArrivalTime />
        <UnitInfoRow label={showLabels && "Ank. spår"}>
          <Text size="small">{train?.arrivalEvent?.track || "-"}</Text>
        </UnitInfoRow>
      </>
    ),
  }[timeSequence]

  return (
    <UnitInfoContainer>
      {rowsForTimeSequence}
      <UnitInfoRow label={showLabels && "Fordonstyp"}>
        <Text size="small">{unit.type}</Text>
      </UnitInfoRow>
      <UnitInfoRow label={showLabels && "Åtgärder"} horizontalSpacing>
        <WagonTimeline />
      </UnitInfoRow>
      <UnitInfoRow label={showLabels && "Avg. tid"}>
        <Time
          right
          bold
          planned={train.departureEvent?.plannedTime}
          estimated={train.departureEvent?.estimatedTime}
          actual={train.departureEvent?.actualTime}
          noWrap
        />
      </UnitInfoRow>
    </UnitInfoContainer>
  )
}

const TrainInfo = ({ event }) => (
  <TrainInfoContainer>
    <TrainNumberText noWrap size="small" bold>
      <Link to={`./${event?.trainId}`}>{event?.technicalTrainNumber}</Link>
    </TrainNumberText>
    <Time
      noWrap
      planned={event?.plannedTime}
      estimated={event?.estimatedTime}
      actual={event?.actualTime}
    />
  </TrainInfoContainer>
)

export default function Unit({
  unit = {},
  showLabels,
  train,
  showInfo,
  event,
  timeSequence,
}) {
  const iconProps = {
    width: "16",
    height: "16",
    style: { marginRight: "0.3em" },
  }
  return (
    <Container>
      <FlexDiv>
        {train && showLabels && <TrainInfo event={event} />}
        <UnitLabelContainer size="small">
          {unit.traction ? (
            <IconTrain {...iconProps} />
          ) : (
            <IconWagon {...iconProps} />
          )}
          <Text center bold size="small" noWrap>
            {formatUrn(unit.id || unit.type)}
          </Text>
        </UnitLabelContainer>
      </FlexDiv>
      <Collapse in={showInfo}>
        <CollapsibleInfo
          unit={unit}
          train={train}
          timeSequence={timeSequence}
          showLabels={showLabels}
        />
      </Collapse>
    </Container>
  )
}
