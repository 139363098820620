import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  styled,
  TextField,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useState } from "react"
import TrainCompositionList from "../../../../components/train_composition_list"

const AddUnitContainer = styled(Grid)`
  align-items: flex-end;
  flex-direction: row;
`

export default function FormRcmfTrain({ formik }) {
  const [unitId, setUnitId] = useState("")
  const [unitType, setUnitType] = useState("")
  const [unitTraction, setUnitTraction] = useState(false)

  const addUnit = () => {
    formik.setValues(prev => ({
      ...prev,
      units: [
        ...formik.values.units,
        { id: unitId, type: unitType, traction: unitTraction },
      ],
    }))
    setUnitId("")
    setUnitType("")
    setUnitTraction(false)
  }

  const deleteUnit = unit =>
    formik.setValues(prev => ({
      ...prev,
      units: formik.values.units.filter(
        u =>
          u.id !== unit.id &&
          u.type !== unit.type &&
          u.traction !== unit.traction
      ),
    }))

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Fordonsdata
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              type="number"
              id="maxSpeed"
              name="maxSpeed"
              label="Maxfart"
              variant="standard"
              fullWidth
              value={formik.values.maxSpeed}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">km/h</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              id="totalLength"
              name="totalLength"
              label="Längd"
              variant="standard"
              fullWidth
              value={formik.values.totalLength}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              id="totalWeight"
              name="totalWeight"
              label="Vikt"
              variant="standard"
              fullWidth
              value={formik.values.totalWeight}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ton</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left">Fordonsindivider</Divider>
          </Grid>
          <Grid item xs={12}>
            <TrainCompositionList
              units={formik.values.units}
              onDelete={deleteUnit}
            />
          </Grid>
          <Grid item xs={12}>
            <AddUnitContainer container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="ID / Namn"
                  variant="standard"
                  size="small"
                  fullWidth
                  value={unitId}
                  onChange={e => setUnitId(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Typ"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={unitType}
                  onChange={e => setUnitType(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={unitTraction}
                      onChange={e => setUnitTraction(e.target.checked)}
                    />
                  }
                  labelPlacement="start"
                  label="Lok"
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  disabled={false}
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={addUnit}
                >
                  Lägg till
                </Button>
              </Grid>
            </AddUnitContainer>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
