import { useCallback, useEffect, useState } from "react"
import { useRecoilValue, useResetRecoilState } from "recoil"
import { userState } from "../recoil/atoms"
import ROUTES from "../ROUTES.json"
import { useHistory } from "react-router-dom"

interface Result {
  data: any
  isLoading: boolean
  error: any
  status?: number | null
}

type FetchFnType = (url: string, config?: any) => void

export const useFetch = (): [Result, FetchFnType] => {
  const [data, setData] = useState(null)
  const [status, setStatus] = useState<number | null>(null)
  const [params, setParams] = useState<any>("")

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const { url, config } = params
    if (!url) return

    const controller = new AbortController()

    const fetchData = async (url: string, config: any) => {
      setError(null)
      setIsLoading(true)

      try {
        const result = await fetch(url, config)

        if (result.ok && !config.skipParse) {
          const parsedData = await result.json()
          setData(parsedData)
        }

        setStatus(result.status)

        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    if (Array.isArray(config)) {
      config.forEach(c => fetchData(url, c))
    } else {
      fetchData(url, config)
    }

    return () => controller?.abort()
  }, [params])

  return [
    { data, isLoading, error, status },
    useCallback((url, config) => {
      setParams({ url, config })
    }, []),
  ]
}

export const useBackend = (): [Result, FetchFnType] => {
  const [result, doFetch] = useFetch()

  const fetchWithBackend = useCallback(
    (url, config) => doFetch(`${ROUTES.BACKEND_STATION_CDM}${url}`, config),
    [doFetch]
  )

  return [result, fetchWithBackend]
}

export const useBackendWithAuth = (): [Result, FetchFnType, FetchFnType] => {
  const user = useRecoilValue(userState)
  const clearUser = useResetRecoilState(userState)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const [result, doFetch] = useBackend()

  useEffect(() => {
    setIsLoading(result.isLoading)
  }, [result.isLoading])

  useEffect(() => {
    if (!error) {
      // Local error
      setError(result.error)
    }
  }, [result.error, error])

  useEffect(() => {
    if (result.status === 404 && !result.error) {
      setError(new Error("404 - Not found"))
    }
  }, [result.status, result.error])

  useEffect(() => {
    if (result.status === 401) {
      clearUser()
      history.replace("/")
    }
  }, [result.status, history, clearUser])

  const fetchWithAuth = useCallback(
    async (url, config = {}) => {
      if (user.username) {
        return doFetch(url, {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
      } else {
        setIsLoading(false)
        setError(new Error("Missing access token"))
      }
    },
    [user, doFetch]
  )

  const fetchSeveralToUrlWithAuth = useCallback(
    async (url, configs) => {
      if (user.username) {
        return doFetch(
          url,
          configs?.map((config: any) => ({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${user.accessToken}`,
            },
          }))
        )
      } else {
        setIsLoading(false)
        setError(new Error("Missing access token"))
      }
    },
    [user, doFetch]
  )

  return [
    { ...result, isLoading, error },
    fetchWithAuth,
    fetchSeveralToUrlWithAuth,
  ]
}
