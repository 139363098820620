import * as qs from "query-string"
import { useCallback, useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  allTrainsState,
  arrivingTrainsState,
  departingTrainsState,
  userState,
} from "../recoil/atoms"
import { useBackendWithAuth } from "./backend"
import useWebSocket from "react-use-websocket"
import { getWsBaseUrl } from "../utils/domain"
import { formatEpochTimeShort } from "../utils/dataFormatting"
import getTrackFromLocation from "../utils/getTrackFromLocation"
import { Train, TrainMessage } from "../types/trainModel"
import {
  AdministrativeState,
  LocationState,
  ServiceState,
  RcmfTrain,
} from "../types/rcmf"

export type TrainsProps = {
  recoilState: typeof arrivingTrainsState
  direction?: "arrivals" | "departures"
  stationId?: string
  startTime?: number
  endTime?: number
  sortTimeMode?: "latest_time" | "planned_time"
  limit?: number
  skip?: number
  hideCancelled?: boolean
  hideDeviating?: boolean
  hidePlanned?: boolean
  hideMissing?: boolean
  hideEven?: boolean
  hideOdd?: boolean
  hideCompositionless?: boolean
  trainNumber?: string
  websocket?: boolean
  includeEventMessages?: boolean
  includeEvents?: boolean
  pollDelay?: number | null
  disable?: boolean
}

const useTrains = ({
  recoilState,
  direction,
  stationId,
  startTime,
  endTime,
  sortTimeMode = "latest_time",
  limit = 100,
  skip = 0,
  hideCancelled,
  hideDeviating,
  hidePlanned,
  hideMissing,
  hideEven,
  hideOdd,
  hideCompositionless,
  trainNumber,
  websocket = false,
  includeEventMessages = false,
  includeEvents = false,
  pollDelay = null,
  disable,
}: TrainsProps) => {
  const [{ data, isLoading, error }, fetchTrains] = useBackendWithAuth() as any
  const [trains, setTrains] = useRecoilState(recoilState)
  const { accessToken } = useRecoilValue(userState)
  const silent = useRef(false)
  const timer = useRef<NodeJS.Timer | null>(null)

  const { lastMessage } = useWebSocket(
    `${getWsBaseUrl()}/${stationId}/trains/${direction}`,
    {
      queryParams: { token: accessToken },
      shouldReconnect: () => false,
    },
    websocket
  )

  const doFetchTrains = useCallback(() => {
    if (disable) return
    const directionPart = direction ? `/${direction}` : ""
    return fetchTrains(
      `/${stationId}${directionPart}/trains?${qs.stringify({
        limit,
        skip,
        start_time: startTime,
        end_time: endTime,
        hide_cancelled: hideCancelled,
        hide_deviating: hideDeviating,
        hide_planned: hidePlanned,
        hide_missing: hideMissing,
        hide_even: hideEven,
        hide_odd: hideOdd,
        hide_compositionless: hideCompositionless,
        train_number: trainNumber,
        include_event_messages: includeEventMessages,
        include_events: includeEvents,
        sort_time_mode: sortTimeMode,
      })}`
    )
  }, [
    direction,
    stationId,
    startTime,
    endTime,
    limit,
    skip,
    hideCancelled,
    hideDeviating,
    hidePlanned,
    hideMissing,
    hideEven,
    hideOdd,
    hideCompositionless,
    fetchTrains,
    trainNumber,
    includeEventMessages,
    includeEvents,
    sortTimeMode,
    disable,
    lastMessage,
  ])

  useEffect(() => {
    if (pollDelay != null) {
      if (timer.current != null) clearTimeout(timer.current)

      if (!isLoading) {
        silent.current = true
        setTimeout(() => {
          if (!isLoading) {
            doFetchTrains()
          }
        }, pollDelay)
      }
    } else {
      if (timer.current != null) clearTimeout(timer.current)
    }
  }, [pollDelay, timer, doFetchTrains, isLoading])

  useEffect(() => {
    setTrains(prev => ({
      ...prev,
      trains: [...prev.trains.slice(0, skip), ...(data || [])],
      isLoading,
      error,
    }))
  }, [data, skip, isLoading, error, setTrains])

  useEffect(() => {
    silent.current = true
  }, [lastMessage])

  useEffect(() => {
    silent.current = false
  }, [
    stationId,
    startTime,
    endTime,
    limit,
    skip,
    hideCancelled,
    hideDeviating,
    hidePlanned,
    hideMissing,
    hideEven,
    hideOdd,
    hideCompositionless,
    trainNumber,
  ])

  useEffect(() => {
    if (stationId) {
      doFetchTrains()
    }
  }, [doFetchTrains, stationId])

  return {
    ...trains,
    isLoading: !silent.current && trains.isLoading,
    error: !silent.current && error,
  }
}

export const useArrivingTrains = (
  stationId: string | undefined,
  options: Omit<TrainsProps, "recoilState">
) =>
  useTrains({
    ...options,
    recoilState: arrivingTrainsState,
    direction: "arrivals",
    stationId,
  })

export const useDepartingTrains = (
  stationId: string | undefined,
  options: Omit<TrainsProps, "recoilState">
) =>
  useTrains({
    ...options,
    recoilState: departingTrainsState,
    direction: "departures",
    stationId,
  })

export const useAllTrains = (
  locationId: string | undefined,
  options: TrainsProps
) =>
  useTrains({
    ...options,
    recoilState: allTrainsState,
    stationId: locationId,
    websocket: false, // For now
  })

export const useTrainRegister = ({
  date,
  trainNumber,
  limit,
  skip,
}: {
  date?: number
  trainNumber?: string
  limit?: number
  skip?: number
}) => {
  const [result, searchTrains] = useBackendWithAuth()

  useEffect(() => {
    searchTrains(
      `/trains?${qs.stringify({
        date,
        train_number: trainNumber,
        limit,
        skip,
      })}`
    )
  }, [searchTrains, trainNumber, date, limit, skip])

  return { trains: result.data, ...result }
}

export const useTrain = ({
  trainId,
  locationId,
  websocket = true,
}: {
  trainId: string
  locationId: string
  websocket?: boolean
}) => {
  // Temporarily disable all websockets
  websocket = false

  const [{ isLoading, error, ...result }, fetchTrain] = useBackendWithAuth()
  const { accessToken } = useRecoilValue(userState)
  const [silent, setSilent] = useState(false)

  const { lastMessage } = useWebSocket(
    `${getWsBaseUrl()}/trains/${trainId}`,
    {
      queryParams: { token: accessToken },
      shouldReconnect: () => false,
    },
    websocket
  )

  useEffect(() => {
    setSilent(true)
  }, [lastMessage])

  useEffect(() => {
    setSilent(false)
  }, [result.data])

  useEffect(() => {
    fetchTrain(`/trains/${trainId}${locationId ? `/${locationId}` : ""}`)
  }, [fetchTrain, trainId, locationId, lastMessage])

  return {
    train: result.data,
    isLoading: !silent && isLoading,
    error: !silent && error,
    ...result,
  }
}

export interface PlannedType {
  train: MessageValue<string>
  operationalTrainNumber: MessageValue<string | undefined>
  operator: MessageValue<string>
  time: MessageValue<number | null | undefined>
  track: MessageValue<string | null>
  comment: { value: string | undefined }
}

export const useLatestNoneEstimateMessages = (
  train: Train,
  timeSequence: "arrivals" | "departures"
): PlannedType => {
  const isArrival = timeSequence === "arrivals"
  const locationKey = isArrival ? "to" : "from"
  const eventMessages = isArrival
    ? train?.arrivalMessages
    : train?.departureMessages

  const sortedNonEstimates = eventMessages
    ?.filter((msg: any) => {
      return (
        msg.payload?.timeType === "Planned" ||
        msg.payload?.timeType === "Actual"
      )
    })
    .sort((a: any, b: any) => {
      if (a.payload?.timeType === b.payload?.timeType)
        return b.reportedAt - a.reportedAt
      if (a.payload?.timeType === "Actual") return -1
      if (b.payload?.timeType === "Actual") return 1

      return b.reportedAt - a.reportedAt
    })

  const event = isArrival ? train?.arrivalEvent : train?.departureEvent

  const technicalTrainNumberMessage = sortedNonEstimates?.find(
    message => !!message.train?.technicalTrainNumber
  )

  const operationalTrainNumberMessage = sortedNonEstimates?.find(
    message => message.train?.operationalTrainNumber
  )

  const operatorMessage = sortedNonEstimates?.find(p => !!p.train?.operator)
  const timeMessage = sortedNonEstimates?.find(p => !!p?.payload?.time)
  const sortedByLocationLength = sortedNonEstimates?.sort((a: any, b: any) => {
    const aHasTrack = !!getTrackFromLocation(a.payload?.[locationKey])
    const bHasTrack = !!getTrackFromLocation(b.payload?.[locationKey])

    if (aHasTrack && bHasTrack) return 0
    if (aHasTrack) return -1

    return 1
  })

  const locationFieldMessage = sortedByLocationLength?.find(
    (p: any) => !!p?.payload?.[locationKey]
  )

  const technicalTrainNumber = {
    value:
      technicalTrainNumberMessage?.train?.technicalTrainNumber ??
      train?.technicalTrainNumber,
    reportedBy: technicalTrainNumberMessage?.reportedBy,
    reportedAt: technicalTrainNumberMessage?.reportedAt,
  }
  const operationalTrainNumber = {
    value: operationalTrainNumberMessage?.train?.operationalTrainNumber,
    reportedBy: operationalTrainNumberMessage?.reportedBy,
    reportedAt: operationalTrainNumberMessage?.reportedAt,
  }
  const operator = {
    value: operatorMessage?.train?.operator ?? train?.operator,
    reportedAt: operatorMessage?.reportedAt,
    reportedBy: operatorMessage?.reportedBy,
  }
  const time = {
    value: timeMessage?.payload?.time ?? event?.plannedTime,
    reportedAt: timeMessage?.reportedAt,
    reportedBy: timeMessage?.reportedBy,
  }

  const track = {
    value: getTrackFromLocation(
      (locationFieldMessage?.payload as any)?.[locationKey]
    ),
    reportedAt: locationFieldMessage?.reportedAt,
    reportedBy: locationFieldMessage?.reportedBy,
    actualWithTrack:
      sortedNonEstimates?.[0] &&
      sortedNonEstimates[0].reportedAt === locationFieldMessage?.reportedAt &&
      sortedNonEstimates[0].reportedBy === locationFieldMessage?.reportedBy &&
      (locationFieldMessage?.payload as any).timeType === "Actual",
  }
  const units = event?.plannedVehicle?.units?.map(u => u.type)?.join(", ")

  return {
    train: technicalTrainNumber,
    operationalTrainNumber,
    operator,
    time,
    track,
    comment: { value: units },
  }
}

export type PlankEstimatesFieldName = keyof EstimatesType

const msgFilter = (
  estimates: TrainMessage[] | undefined,
  planned: PlannedType,
  getField: (
    payload: ServiceState | AdministrativeState | LocationState,
    index?: number,
    train?: RcmfTrain,
    planned?: PlannedType
  ) => string | number | undefined | null,
  transformField?: (field: any) => string | undefined
) => {
  return estimates
    ?.filter((msg, i) => getField(msg.payload, i, msg.train, planned) != null)
    .map(msg => {
      return {
        reportedAt: msg.reportedAt,
        reportedBy: msg.reportedBy,
        value: transformField
          ? transformField(getField(msg.payload))
          : getField(msg.payload, undefined, msg.train),
      }
    })
}

export interface MessageValue<P> {
  value?: P | null
  reportedBy?: string
  reportedAt?: number
  actualWithTrack?: boolean
}

export interface EstimatesType {
  track?: MessageValue<string>[]
  time?: MessageValue<number>[]
  operator?: MessageValue<string>[]
  comment?: MessageValue<string>[]
}

export const useEstimatedMessages = (
  train: Train,
  timeSequence: "arrivals" | "departures",
  planned: PlannedType
): EstimatesType => {
  const timeSequenceEventMessages =
    timeSequence === "arrivals"
      ? train?.arrivalMessages
      : train?.departureMessages

  const sortedEstimates = timeSequenceEventMessages
    ?.filter((msg: any) => msg.payload?.timeType === "Estimated")
    .sort((a, b) => a.reportedAt - b.reportedAt)
  const estimates: EstimatesType = {
    track: msgFilter(sortedEstimates, planned, (payload: any, i) => {
      const isArrival = timeSequence === "arrivals"
      const splitted = isArrival
        ? payload?.to?.split(":")
        : payload?.from?.split(":")
      const track: string =
        splitted.length === 5 ? splitted.splice(-1)[0] : null
      if (i !== 0 && i != null) {
        const lastSplitted = isArrival
          ? (sortedEstimates?.[i - 1]?.payload as any)?.to?.split(":")
          : (sortedEstimates?.[i - 1]?.payload as any)?.from?.split(":")
        const lastTrack =
          lastSplitted?.length === 5 ? lastSplitted.slice(-1)[0] : null
        if (track === lastTrack) return null
      } else if (planned?.track?.value === track) return null

      if (track) return track

      return null
    }) as any,
    time: msgFilter(
      sortedEstimates,
      planned,
      (payload, i) => {
        if (
          i !== 0 &&
          i != null &&
          sortedEstimates?.[i - 1]?.payload?.time === payload?.time
        )
          return null
        else if (planned.time?.value === payload?.time) return null
        return payload?.time
      },
      formatEpochTimeShort
    ) as any,
    operator: msgFilter(sortedEstimates, planned, (_, i, train) => {
      if (
        i !== 0 &&
        i != null &&
        sortedEstimates?.[i - 1]?.train?.operator === train?.operator
      )
        return null
      else if (planned?.operator?.value === train?.operator) return null
      return train?.operator ?? null
    }) as any,
    comment: msgFilter(sortedEstimates, planned, (payload, i) => {
      if (
        i !== 0 &&
        i != null &&
        sortedEstimates?.[i - 1]?.payload?.comment === payload?.comment
      )
        return null
      else if (planned?.comment?.value === payload?.comment) return null
      return payload?.comment ?? null
    }) as any,
  }

  return { ...estimates }
}
