import WagonIcon from "../icons/WagonIcon"
import { formatRcmfId, getRcmfRefObjectType } from "../../utils/dataUtils"
import Text from "../Text"
import Timeline from "../Timeline"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchMessagesForRefObject } from "../../actions/messages"
import BaseContainer from "./BaseContainer"
import DataWrapper from "../DataWrapper"
import { useHistory } from "react-router-dom"
import itemRoutes from "../../routes.json"
import { fetchReferenceObject } from "../../actions/referenceObjects"
import { useAuthDispatch } from "../../../../hooks/rfidBackend"

const capitalize = str =>
  `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`

const RefObject = ({ id }) => {
  const dispatch = useAuthDispatch()
  const history = useHistory()
  const refObject = useSelector(state => state.referenceObjects.byId[id])
  const messages = useSelector(state => state.messages.byRefObjectId[id]) || []
  const isLoadingRefObject =
    useSelector(state => state.referenceObjects.isLoading) || !refObject
  const refObjectError = useSelector(state => state.referenceObjects.error)
  const { isLoading: isLoadingMessages, error: messageError } = useSelector(
    state => state.messages
  )

  useEffect(() => {
    if (!refObject) {
      dispatch(fetchReferenceObject(id))
    }
  }, [dispatch, id, refObject])

  useEffect(() => {
    dispatch(fetchMessagesForRefObject(id))
  }, [dispatch, id])

  return (
    <BaseContainer
      TitleIcon={WagonIcon}
      titleText={
        isLoadingRefObject
          ? "Loading..."
          : ` ${capitalize(
              getRcmfRefObjectType(refObject?.refObjectId)
            )} timeline`
      }
      headerContent={
        <DataWrapper
          isLoading={isLoadingRefObject}
          fixedHeight
          error={refObjectError}
        >
          <Text light>
            <b>Number of readings: </b>
            {messages.length}
          </Text>
          <Text light>
            <b>ID: </b>
            {formatRcmfId(id)}
          </Text>
        </DataWrapper>
      }
      bodyContent={
        <DataWrapper error={messageError} isLoading={isLoadingMessages}>
          <Timeline
            messages={messages}
            showReaderInfo
            onClick={message =>
              history.push(
                `/${itemRoutes.REF_OBJECTS}/${refObject.refObjectId}/${message.messageId}`
              )
            }
          />
        </DataWrapper>
      }
    />
  )
}

export default RefObject
