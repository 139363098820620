import { TileLayer } from "@deck.gl/geo-layers"
import {
  BitmapLayer,
  IconLayer,
  PathLayer,
  ScatterplotLayer,
} from "@deck.gl/layers"
import DeckGL from "@deck.gl/react"
import mapboxgl from "mapbox-gl"
import React, { useEffect, useState } from "react"
import { StaticMap } from "react-map-gl"
import { useTrainLocations } from "../hooks/overview"
import { useRfidReaders, useStations } from "../hooks/staticData"
import PicRailwayTrainPosition from "../resources/RailwayTrainPositionWhite.png"

/* eslint-disable */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
/* eslint-enable */

export default function MapTrainTimeline({ messagesData, events, trainID }) {
  const trainLocations = useTrainLocations()
  const { stations } = useStations()
  const { readers: rfidReaders } = useRfidReaders()
  const [routePlan, setRoutePlan] = useState([])
  const [trainPosNow, setTrainPosNow] = useState([])
  const [stationPass, setStationPass] = useState([])
  const [viewState, setViewState] = useState({
    latitude: 61,
    longitude: 16,
    zoom: 5,
    pitch: 0,
    bearing: 0,
  })

  const getPathFilter = (event_type, events) => {
    var filteredEvents = events.filter(function (event) {
      return event.latestTimeType === event_type
    })

    const pathList = filteredEvents
      .map(event => {
        const station = stations[event.location]
        const rfidReader =
          rfidReaders[event.location?.split("rcmf:location:rfid_reader:")[1]]

        if (station) {
          return [station.lon, station.lat]
        }

        if (rfidReader) {
          return [rfidReader.Longitude, rfidReader.Latitude]
        }

        return null
      })
      .filter(val => !!val)

    return pathList
  }

  const getPathFull = events => {
    let pathList = []
    pathList = events
      .map(event => {
        const station = stations[event?.location]
        const rfidReader =
          rfidReaders[event?.location?.split("rcmf:location:rfid_reader:")[1]]

        if (station) {
          return [station.lon, station.lat]
        }

        if (rfidReader) {
          return [rfidReader.Longitude, rfidReader.Latitude]
        }

        return null
      })
      .filter(val => !!val)

    return pathList
  }

  const getLocations = events => {
    const stationList = events
      .map(event => {
        const station = stations[event.location]
        const rfidReader =
          rfidReaders[event.location?.split("rcmf:location:rfid_reader:")[1]]

        if (station) {
          return {
            ...event,
            pos: [station.lon, station.lat],
          }
        }

        if (rfidReader) {
          return {
            ...event,
            pos: [rfidReader.Longitude, rfidReader.Latitude],
          }
        }

        return null
      })
      .filter(val => !!val)

    return stationList
  }

  useEffect(() => {
    const TrainPos = trainLocations.filter(train => train.tagNr == trainID)
    setTrainPosNow(TrainPos)

    // FULL
    const pathFull = getPathFull(events)

    const planFullPath = {
      path: pathFull,
      name: "Full",
      color: [0, 0, 0],
    }

    // PLANED
    const pathPlanned = getPathFilter("Planned", events)
    const planRoute = {
      path: pathPlanned,
      name: "Planed",
      color: [33, 148, 243],
    }

    // ESTIMATED
    const pathEstimated = getPathFilter("Estimated", events)
    const planEstimated = {
      path: pathEstimated,
      name: "Estimated",
      color: [208, 226, 8],
    }

    // ACTUAL
    const pathActual = getPathFilter("Actual", events)
    const planActual = {
      path: pathActual,
      name: "Actual",
      color: [154, 185, 61],
    }

    setRoutePlan([planFullPath, planRoute, planEstimated, planActual])

    // CALC map center
    let sumLong = 0
    for (let i = 0; i < pathFull.length; i++) {
      sumLong = sumLong + pathFull[i][0]
    }
    let sumLat = 0
    for (let i = 0; i < pathFull.length; i++) {
      sumLat = sumLat + pathFull[i][1]
    }
    const avgLat = sumLat / pathFull.length
    const avgLong = sumLong / pathFull.length

    setViewState({
      ...viewState,
      latitude: avgLat,
      longitude: avgLong,
      zoom: 5,
    })

    const stationPos = getLocations(events)
    setStationPass(stationPos)
  }, [])

  // Railway Map
  const layerBitMapOpenRailway = new TileLayer({
    data: "https://tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png",
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    visible: true,
    renderSubLayers: props => {
      const {
        bbox: { west, south, east, north },
      } = props.tile
      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      })
    },
  })

  // Route Path layer Map
  const trainTrack = new PathLayer({
    id: "path-layer",
    data: routePlan,
    pickable: true,
    widthScale: 20,
    widthMinPixels: 5,
    capRounded: true,
    getPath: ({ path }) => path,
    getColor: d => d.color,
    getWidth: () => 20,
  })

  // Train location
  const layerTrainPos = new IconLayer({
    id: "train-locations-layer",
    data: trainPosNow,
    pickable: true,
    getIcon: d => {
      return {
        url: PicRailwayTrainPosition,
        width: 466,
        height: 643,
        anchorY: 643,
      }
    },
    sizeUnits: "common",
    sizeMinPixels: 40,
    sizeMaxPixels: 40,
    getPosition: d => {
      return [d.lng, d.lat]
    },
  })

  // Station location
  const layerStationPass = new ScatterplotLayer({
    id: "scatterplot-layer",
    data: stationPass,
    pickable: true,
    opacity: 0.8,
    stroked: true,
    filled: true,
    radiusScale: 6,
    radiusMinPixels: 4,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 1,
    getPosition: d => d.pos,
    getRadius: d => 100,
    getFillColor: d => {
      if (d.latestTimeType === "Planned") {
        return [33, 148, 243]
      } else if (d.latestTimeType === "Actual") {
        return [154, 185, 61]
      } else {
        return [208, 226, 8]
      }
    },
  })

  return (
    <div style={{ position: "relative", height: "20rem" }}>
      <DeckGL
        style={{ position: "absolute" }}
        layers={[
          layerBitMapOpenRailway,
          trainTrack,
          layerTrainPos,
          layerStationPass,
        ]}
        viewState={viewState}
        onViewStateChange={e => setViewState(e.viewState)}
        controller={{ dragPan: true }}
        // getTooltip={getTooltip}
        // onClick={getMapObj}
      >
        <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
      </DeckGL>
    </div>
  )
}

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoieXlkZGVldHQiLCJhIjoiY2t0eGEyNjJhMWI0NjJxcW53dGNrMmk2eSJ9.6bkGb4cvC5pbb8sisIScSw"
