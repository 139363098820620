import { tryFetch } from "../utils/apiService"
import {
  FETCH_MESSAGES_BEGIN,
  FETCH_MESSAGES_ERROR,
  FETCH_MESSAGES_SUCCESS,
  MESSAGE_EVENT_RECEIVED,
} from "./types"

export const fetchMessagesForRefObject =
  (refObjectId, limit = 100) =>
  accessToken =>
  dispatch => {
    dispatch({ type: FETCH_MESSAGES_BEGIN })
    return tryFetch(
      `/referenceObjects/${refObjectId}/messages?limit=${limit}`,
      accessToken
    )
      .then(messages =>
        dispatch({
          type: FETCH_MESSAGES_SUCCESS,
          refObjectId,
          payload: messages,
        })
      )
      .catch(error => dispatch({ type: FETCH_MESSAGES_ERROR, payload: error }))
  }

export const fetchMessagesForTrain =
  (trainId, limit = 100) =>
  accessToken =>
  dispatch => {
    dispatch({ type: FETCH_MESSAGES_BEGIN })
    return tryFetch(`/trains/${trainId}/messages/`, accessToken)
      .then(messages =>
        dispatch({
          type: FETCH_MESSAGES_SUCCESS,
          trainId,
          payload: messages,
        })
      )
      .catch(error => dispatch({ type: FETCH_MESSAGES_ERROR, payload: error }))
  }

export const fetchMessagesForReader =
  (readerId, limit = 0) =>
  accessToken =>
  dispatch => {
    dispatch({ type: FETCH_MESSAGES_BEGIN })
    return tryFetch(`/readers/${readerId}/messages?limit=${limit}`, accessToken)
      .then(messages =>
        dispatch({
          type: FETCH_MESSAGES_SUCCESS,
          readerId,
          payload: messages,
        })
      )
      .catch(error => dispatch({ type: FETCH_MESSAGES_ERROR, payload: error }))
  }

export const onMessageReceived = message => dispatch =>
  dispatch({
    type: MESSAGE_EVENT_RECEIVED,
    payload: { receivedAt: new Date().getTime(), ...message },
  })
