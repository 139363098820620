import { Checkbox, FormControlLabel } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import PopoverButton from "../../../components/PopoverButton"

type Filters = {
  showCancelled: boolean
  showDone: boolean
  showTrvTracks: boolean
  setShowCancelled: (show: boolean) => void
  setShowDone: (show: boolean) => void
  setShowTrvTracks: (show: boolean) => void
}

export const usePlankFilters = (): Filters => {
  const createOnChanged =
    (
      setFn: React.Dispatch<React.SetStateAction<boolean>>,
      storageItem: string
    ) =>
    (show: boolean) => {
      setFn(show)
      localStorage.setItem(storageItem, show ? "y" : "n")
    }

  const [showDone, setShowDone] = useState(
    localStorage.getItem("showDone") !== "n"
  )
  const [showCancelled, setShowCancelled] = useState(
    localStorage.getItem("showCancelled") !== "n"
  )

  // Default OFF, hide trv tracks
  const [showTrvTracks, setShowTrvTracks] = useState(
    localStorage.getItem("showTrvTracks") === "y"
  )

  return {
    showDone,
    setShowDone: createOnChanged(setShowDone, "showDone"),
    showCancelled,
    setShowCancelled: createOnChanged(setShowCancelled, "showCancelled"),
    showTrvTracks,
    setShowTrvTracks: createOnChanged(setShowTrvTracks, "showTrvTracks"),
  }
}

type Props = {
  filters: Filters
}

const PlankFilters: React.FC<Props> = props => {
  const { t } = useTranslation()
  return (
    <PopoverButton title={t("plank.filter")}>
      <>
        <FormControlLabel
          label={t("plank.showCancelled")}
          control={
            <Checkbox
              checked={props.filters.showCancelled}
              onChange={(_, checked) => props.filters.setShowCancelled(checked)}
            />
          }
        />
        <FormControlLabel
          label={t("plank.showDone")}
          control={
            <Checkbox
              checked={props.filters.showDone}
              onChange={(_, checked) => props.filters.setShowDone(checked)}
            />
          }
        />
        <FormControlLabel
          label={t("plank.showTrvTracks")}
          control={
            <Checkbox
              checked={props.filters.showTrvTracks}
              onChange={(_, checked) => props.filters.setShowTrvTracks(checked)}
            />
          }
        />
      </>
    </PopoverButton>
  )
}

export default PlankFilters
