import { useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  rfidReadersState,
  stateCatalogueState,
  stationsState,
  userState,
} from "../recoil/atoms"
import { useBackendWithAuth } from "./backend"

export const useStations = shouldFetch => {
  const [{ data, isLoading, error }, fetchStations] = useBackendWithAuth()
  const [state, setStations] = useRecoilState(stationsState)
  const { stations, isLoading: isLoadingGlobal } = state
  const { username } = useRecoilValue(userState)

  useEffect(() => {
    if (Object.keys(stations).length === 0 && !isLoadingGlobal && shouldFetch) {
      setStations(prev => ({ ...prev, isLoading: true, error: null }))
      fetchStations("/stations")
    }

    return () => {
      if (shouldFetch && isLoadingGlobal) {
        setStations(prev => ({ ...prev, isLoading: false }))
      }
    }
  }, [
    fetchStations,
    stations,
    isLoadingGlobal,
    setStations,
    shouldFetch,
    username,
  ])

  useEffect(() => {
    if (data) {
      setStations({
        stations: data
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .reduce(
            (result, station) => ({
              ...result,
              [station.id]: station,
            }),
            {}
          ),
        isLoading: false,
        error: null,
      })
    } else {
      setStations(prev => ({ ...prev, error }))
    }
  }, [data, isLoading, error, setStations])

  return state
}

export const useRfidReaders = shouldFetch => {
  const [{ data, isLoading, error }, fetchRfidReaders] = useBackendWithAuth()
  const [state, setRfidReaders] = useRecoilState(rfidReadersState)
  const { readers, isLoading: isLoadingGlobal } = state
  const { username } = useRecoilValue(userState)

  useEffect(() => {
    if (Object.keys(readers).length === 0 && !isLoadingGlobal && shouldFetch) {
      fetchRfidReaders("/rfid_readers")
    }

    return () => {
      if (shouldFetch && isLoadingGlobal) {
        setRfidReaders(prev => ({ ...prev, isLoading: false }))
      }
    }
  }, [fetchRfidReaders, readers, isLoadingGlobal, shouldFetch, setRfidReaders])

  useEffect(() => {
    if (data) {
      setRfidReaders({
        readers: data.reduce(
          (result, reader) => ({ ...result, [reader.SGLN]: reader }),
          {}
        ),
        isLoading: false,
        error: null,
      })
    } else {
      setRfidReaders(prev => ({ ...prev, isLoading, error }))
    }
  }, [data, isLoading, error, setRfidReaders, username])

  return state
}

export const useStateCatalogue = shouldFetch => {
  const [{ data, isLoading, error }, fetchStateCatalogue] = useBackendWithAuth()
  const [state, setStateCatalogue] = useRecoilState(stateCatalogueState)
  const { states, isLoading: isLoadingGlobal } = state
  const { username } = useRecoilValue(userState)

  useEffect(() => {
    if (states.length === 0 && !isLoadingGlobal && shouldFetch) {
      fetchStateCatalogue("/state_catalogue")
    }

    return () => {
      if (shouldFetch && isLoadingGlobal) {
        setStateCatalogue(prev => ({ ...prev, isLoading: false }))
      }
    }
  }, [
    fetchStateCatalogue,
    states,
    isLoadingGlobal,
    shouldFetch,
    username,
    setStateCatalogue,
  ])

  useEffect(() => {
    if (data) {
      setStateCatalogue({ states: data, isLoading: false, error: null })
    } else {
      setStateCatalogue(prev => ({ ...prev, isLoading, error }))
    }
  }, [data, isLoading, error, setStateCatalogue])

  return state
}
