import { NavLink, useLocation } from "react-router-dom"
import Text from "../Text"
import styled from "styled-components"
import { COLOR_PRIMARY, COLOR_WHITE } from "../../utils/colors"

const StyledLink = styled(NavLink)`
  margin: 0;
  padding: 0.4em 0.4em;
  list-style-type: none;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  background-color: ${COLOR_PRIMARY};
  color: ${COLOR_WHITE};
  text-decoration: none;

  &.selected {
    background-color: transparent;
    color: ${COLOR_PRIMARY};
  }
`

const TabLink = ({ path, Icon, children }) => {
  const { pathname: currentPathname } = useLocation()
  const isSelected = currentPathname === path
  return (
    <StyledLink activeClassName="selected" to={path}>
      <Icon fill={isSelected ? COLOR_PRIMARY : COLOR_WHITE} />
      <Text light={!isSelected} bold={isSelected}>
        {children}
      </Text>
    </StyledLink>
  )
}

export default TabLink
