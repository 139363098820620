/*
    Footer 
    ----------------------- 
    - Copyright note 
    - Feedback button, opening Mailto link 
 */

import React from "react"
import { useTheme } from "@mui/material/styles"
import { Grid, Button } from "@mui/material"
// Icons & Pic
import SendIcon from "@mui/icons-material/Send"
import { getTitle } from "../../utils/domain"

export default function Footer() {
  const theme = useTheme()

  const sendFeedback = () => {
    window.open(
      "mailto:railwayCDM.support@ri.se?subject=StationCDM Feedback&body=Hej! Vi vill gärna höra era synpunkter. %0D%0A%0D%0AIfall ni har upptäckt ett problem, lägg gärna in skärmdumpar och tidpunkten samt beskriv problment. %0D%0A%0D%0ANi är även mer än välkomna att komma med förslag på hur ni skulle vilja förbättra/interagera med plattformen. "
    )
  }

  return (
    <Grid
      container
      sx={{ width: "100%", background: theme.palette.primary.main }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          variant={"contained"}
          color="secondary"
          sx={{ margin: "0.5rem" }}
          onClick={sendFeedback}
        >
          Feedback <SendIcon sx={{ marginLeft: "0.5rem" }} />
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <h6
          variant="body2"
          style={{ margin: "0px", color: theme.palette.primary.contrastText }}
        >
          {"Copyright © RISE "} {new Date().getFullYear()} {getTitle()} DEMO
          v1.9.2
        </h6>
      </Grid>
    </Grid>
  )
}
