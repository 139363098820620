import {
  extractStationFromLocation,
  formatEpochDateTime,
} from "../../utils/dataFormatting"
import KeyValueDisplay from "../key_value_display"

export default function TrainData({ train }) {
  const {
    arrivalEvent,
    departureEvent,
    latestPlannedVehicle: lpv,
    latestEstimatedVehicle: lev,
    latestActualVehicle: lav,
  } = train || {}

  const ata = formatEpochDateTime(arrivalEvent?.actualTime)
  const eta = formatEpochDateTime(arrivalEvent?.estimatedTime)
  const pta = formatEpochDateTime(arrivalEvent?.plannedTime)
  const atd = formatEpochDateTime(departureEvent?.actualTime)
  const etd = formatEpochDateTime(departureEvent?.estimatedTime)
  const ptd = formatEpochDateTime(departureEvent?.plannedTime)

  return (
    <KeyValueDisplay
      hideMissing
      items={{
        ...train,
        pta,
        ptd,
        ata,
        eta,
        atd,
        etd,
        connectedTrainNumbers: train.connected_train_numbers?.map(
          (number, index) => number.split(".")[1] + " "
        ),
        latestPosition: extractStationFromLocation(
          train.latestTrainLocation?.locationId
        ),
        track: (arrivalEvent || departureEvent)?.track,
        operationalTrainNumber: (arrivalEvent || departureEvent)
          ?.operationalTrainNumber,
        wagons: (lpv || lev || lav)?.units?.length,
        plannedLoco: lpv?.units?.find(unit => unit.traction)?.type,
        estimatedLoco: lev?.units?.find(unit => unit.traction)?.type,
        actualLoco: lav?.units?.find(unit => unit.traction)?.type,
        plannedVehicleLength: lpv?.totalLength,
        estimatedVehicleLength: lev?.totalLength,
        actualVehicleLength: lav?.totalLength,
        plannedVehicleWeight: lpv?.totalWeight,
        estimatedVehicleWeight: lev?.totalWeight,
        actualVehicleWeight: lav?.totalWeight,
        plannedMaxSpeed: lpv?.maxSpeed,
        estimatedMaxSpeed: lev?.maxSpeed,
        actualMaxSpeed: lav?.maxSpeed,
      }}
      labels={{
        technicalTrainNumber: "Tåg-ID",
        operationalTrainNumber: "OTN",
        advertisedTrainNumber: "Annonserat tågnr",
        pta: "Planerad ankomst",
        ptd: "Planerad avgång",
        eta: "Beräknad ankomst",
        etd: "Beräknad avgång",
        ata: "Faktisk ankomst",
        atd: "Faktisk avgång",
        latestPosition: "Senaste position",
        operator: "Operatör",
        track: "Spår",
        trainLength: "Tåglängd",
        locomotive: "Lok",
        wagons: "Antal reg. fordonsindivider",
        connectedTrainNumbers: "Kopplade tåg-IDn",
        plannedLoco: "Planerad loktyp",
        estimatedLoco: "Beräknad loktyp",
        actualLoco: "Faktisk loktyp",
        plannedVehicleLength: "Planerad fordonslängd (m)",
        estimatedVehicleLength: "Beräknad fordonslängd (m)",
        actualVehicleLength: "Faktisk fordonslängd (m)",
        plannedVehicleWeight: "Planerad fordonsvikt (ton)",
        estimatedVehicleWeight: "Beräknad fordonsvikt (ton)",
        actualVehicleWeight: "Faktisk fordonsvikt (ton)",
        plannedMaxSpeed: "Planerad maxfart (km/h)",
        estimatedMaxSpeed: "Beräknad maxfart (km/h)",
        actualMaxSpeed: "Faktisk maxfart (km/h)",
      }}
    />
  )
}
