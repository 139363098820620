import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from "@mui/icons-material/Search"
import Input from "@mui/material/Input"
import styled from "styled-components"

const Container = styled.div``

const SearchBar = ({ value, placeholder = "Search", onChange, className }) => (
  <Container className={className}>
    <Input
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      label="Search"
      type="search"
      variant="standard"
      placeholder={placeholder}
      fullWidth
      onChange={onChange}
      value={value}
    />
  </Container>
)

export default SearchBar
