import { DatePicker } from "@mui/lab"
import { Autocomplete, Grid, Slider, styled, TextField } from "@mui/material"
import { startOfDay } from "date-fns"
import { useEffect, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useStations } from "../../hooks/staticData"
import { getTitle } from "../../utils/domain"
import SAP from "./sap"
import { useTranslation } from "react-i18next"

const Container = styled(Grid)`
  padding: 1em;
`

const SliderContainer = styled(Grid)`
  padding-right: 2em;
`

export default function SapPage({}) {
  const { page, stationId } = useRouteMatch("/:page/:stationId?")?.params || {}
  const history = useHistory()
  const { stations } = useStations()

  const [date, setDate] = useState(startOfDay(new Date()))

  const { t } = useTranslation()

  const defaultTimeInterval = [8, 16]
  const [timeInterval, setTimeInterval] = useState(defaultTimeInterval)

  const formatHour = hour => `${hour}:00`
  const marks = [...new Array(25).fill()].map((_, index) => ({
    value: index,
    label: index % 2 === 0 ? formatHour(index) : undefined,
  }))

  useEffect(() => {
    if (page && !stationId) {
      history.replace(`/${page}/${getTitle() === "YardCDM" ? "Mgb" : "Cst"}/`)
    }
  }, [page, stationId, history])

  return (
    <Container container columnSpacing={4}>
      <Grid item xs={4}>
        <Autocomplete
          disablePortal
          disableClearable
          fullWidth
          value={stations[stationId] || null}
          onChange={(event, station) => history.push(`/${page}/${station.id}`)}
          options={Object.values(stations)}
          getOptionLabel={option => `${option.name} (${option.id})`}
          renderInput={params => (
            <TextField
              {...params}
              label={t("generalTrain.stations")}
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <DatePicker
          renderInput={props => (
            <TextField
              {...props}
              fullWidth
              size="small"
              label={t("time.selectDate")}
            />
          )}
          onChange={setDate}
          value={date}
          mask={undefined}
        />
      </Grid>
      <SliderContainer item xs={6}>
        <Slider
          getAriaLabel={() => t("time.selectTimeInterval")}
          getAriaValueText={formatHour}
          defaultValue={defaultTimeInterval}
          onChangeCommitted={(e, value) => {
            setTimeInterval(value)
          }}
          valueLabelDisplay="auto"
          disableSwap
          min={0}
          max={25}
          marks={marks}
          valueLabelFormat={formatHour}
        />
      </SliderContainer>
      <Grid item xs={12}>
        <SAP
          stationId={stationId}
          startTime={date.getTime() + timeInterval[0] * 3600 * 1000}
          endTime={date.getTime() + timeInterval[1] * 3600 * 1000}
        />
      </Grid>
    </Container>
  )
}
