import { useEffect } from "react"
import axios from "axios"
import { useRecoilState } from "recoil"
import { trvTrainMessageState } from "../recoil/atoms"

export const useTrv = () => {
  const [trainMsg, setTrainMsg] = useRecoilState(trvTrainMessageState)

  useEffect(() => {
    setTrainMsg({
      ...trainMsg,
      isLoading: true,
      errors: false,
    })

    const config = {
      headers: { "Content-Type": "text/xml" },
    }

    const xmlMessages = `
            <REQUEST>
              <LOGIN authenticationkey="4c19837571f04ce191140305657bcf4d" />
              <QUERY sseurl="true" objecttype="TrainMessage" schemaversion="1.7" orderby="StartDateTime" > 
              </QUERY>
            </REQUEST>`

    axios
      .post(
        "https://api.trafikinfo.trafikverket.se/v2/data.json",
        xmlMessages,
        config
      )
      .then(function (response) {
        setTrainMsg({
          ...trainMsg,
          events: response.data.RESPONSE.RESULT[0].TrainMessage,
          isLoading: false,
          errors: false,
        })
      })
      .catch(function (error) {
        setTrainMsg({
          ...trainMsg,
          isLoading: false,
          errors: true,
          errorMsg: error.message,
        })
      })
  }, [])
}

export const useTrvArr = () => {
  const [trainMsg, setTrainMsg] = useRecoilState(trvTrainMessageState)

  useEffect(() => {
    setTrainMsg({
      ...trainMsg,
      isLoading: true,
      errors: false,
    })

    let config = {
      headers: { "Content-Type": "text/xml" },
    }
    let xmlMessages = `
            <REQUEST>
              <LOGIN authenticationkey="4c19837571f04ce191140305657bcf4d" />
              <QUERY sseurl="true" objecttype="TrainAnnouncement" schemaversion="1.6"  > 
              </QUERY>
            </REQUEST>`

    axios
      .post(
        "https://api.trafikinfo.trafikverket.se/v2/data.json",
        xmlMessages,
        config
      )
      .then(function (response) {
        // let SSEurl = response.data.RESPONSE.RESULT[0].INFO.SSEURL
        // let eventSource = new EventSource(SSEurl)
        // eventSource.onmessage = e => console.log("ON MESSAGE",JSON.parse(e.data).RESPONSE.RESULT )
      })
      .catch(function (error) {
        console.error(error)
      })
  }, [])
}
