import Warning from "@mui/icons-material/Warning"
import { styled, Tooltip } from "@mui/material"

const WarningIcon = styled(Warning, { shouldForwardProp: false })`
  color: ${({ theme, severity }) =>
    severity === 0 ? theme.palette.warning.main : theme.palette.secondary.main};
`

export default function TrainWarning({ indicator }) {
  return (
    <Tooltip disableFocusListener title={indicator.title} placement="top">
      <WarningIcon severity={indicator.severity} />
    </Tooltip>
  )
}
