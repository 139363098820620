import * as L from "leaflet"
import ReactDOMServer from "react-dom/server"
import { LayerGroup } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-markercluster"
import IconWrapper from "./IconWrapper"

const ItemsLayer = ({
  Icon,
  maxClusterRadius = 40,
  renderClusterIcon,
  children,
  selectedMessageId,
}) => (
  <LayerGroup>
    <MarkerClusterGroup
      key={selectedMessageId || Icon}
      maxClusterRadius={maxClusterRadius}
      showCoverageOnHover={false}
      iconCreateFunction={cluster => {
        const isAnyNewlyUpdated = cluster
          .getAllChildMarkers()
          .some(marker => marker.options?.data?.isNewlyUpdated)
        return L.divIcon({
          className: "dummy",
          html: ReactDOMServer.renderToString(
            Icon ? (
              <IconWrapper
                isNewlyUpdated={isAnyNewlyUpdated}
                Icon={Icon}
                count={cluster.getChildCount()}
              />
            ) : (
              renderClusterIcon(cluster)
            )
          ),
        })
      }}
    >
      {children}
    </MarkerClusterGroup>
  </LayerGroup>
)

export default ItemsLayer
