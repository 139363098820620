import { format } from "date-fns"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import styled from "styled-components"
import { COLOR_ACCENT_SECONDARY, COLOR_SECONDARY } from "../../utils/colors"
import { hasDifferentDays } from "../../utils/dateUtils"
import Error from "../../../../components/error"
import Text from "../Text"
import Item from "./Item"
import VerticalLine from "./VerticalLine"

const Container = styled.div`
  background-color: ${COLOR_SECONDARY};
  padding: 0 0.5em;
  overflow-y: scroll;
  height: 100%;
`

const DateItemContainer = styled.div`
  text-align: left;
  position: relative;
  padding: 0.5em 0 0.5em 3px; // circle radius
  display: flex;
  align-items: center;
`

const HorizontalLine = styled.div`
  height: 2px;
  width: 10px;
  margin-right: 0.2em;
  background-color: ${COLOR_ACCENT_SECONDARY};
`

const DateItem = ({ date }) => (
  <DateItemContainer>
    <HorizontalLine />
    <Text noMargin>{format(date, "yyyy-MM-dd")}</Text>
  </DateItemContainer>
)

const Timeline = ({ messages = [], showReaderInfo, onClick }) => {
  const { isLoading, error } = useSelector(state => state.messages)
  const users = useSelector(state => state.users.byId)
  const readers = useSelector(state => state.readers.byId)
  const { messageId: selectedMessageId, itemType } = useRouteMatch(
    "/:itemType/:itemId/:messageId?"
  ).params
  const scrollRefs = useRef({})

  useEffect(() => {
    scrollRefs.current?.[selectedMessageId]?.scrollIntoView?.({
      behavior: "smooth",
    })
  }, [selectedMessageId])

  return (
    <Container>
      {isLoading && <p>Loading...</p>}
      {(!messages || messages.length === 0) && (
        <Text>No readings found...</Text>
      )}
      <Error error={error}>
        <VerticalLine style={{ marginLeft: "3px" }} />
        {messages.map((message, index) => {
          const showDate = hasDifferentDays(message, messages[index - 1])
          return (
            <div
              key={message.messageId}
              ref={elem => (scrollRefs.current[message.messageId] = elem)}
            >
              {showDate && <DateItem date={new Date(message.payload.time)} />}
              <Item
                message={message}
                glow={selectedMessageId === message.messageId}
                showDate={showDate}
                users={users}
                reader={showReaderInfo && readers[message.payload.at]}
                itemType={itemType}
                onClick={onClick}
              />
            </div>
          )
        })}
      </Error>
    </Container>
  )
}

export default Timeline
